/**
 * SideBar.tsx
 * ---------------
 * Component used as a side bar menu when using the DC platform.
 * This controls what content is being rendered:
 * - Dashboard
 * - Teachers
 * - Students
 * - Lessons
 * - Classes
 * - Quizzes
 */
import { useState } from "react";
import {
  MdDashboard,
  MdFeaturedPlayList,
  MdLogout,
  MdOutlineAssignmentInd,
  MdOutlineDashboard,
  MdOutlineExpandMore,
  MdOutlineFeaturedPlayList,
  MdOutlinePeopleAlt,
  MdOutlineSupervisedUserCircle,
  MdPeopleAlt,
  MdSupervisedUserCircle,
} from "react-icons/md";
import { SchoolListType } from "utils/interfaces";
import { BACKEND_URL } from "config/getUrl";
import PopUpMenu from "./PopUpMenu";
import LogoutPopUp from "../pop-up-panels/logout-pop-up/LogoutPopUp";
import SideBarOption from "./SideBarOption";
import PopUpPanel from "components/main-view/dashboard/pop-up-panels/PopUpPanel";
import PopUpSuccessful from "./PopUpSuccessful";

interface SideBarProps {
  userRole: string;
  userFirstName: string;
  userLastName: string;
  userSchool: SchoolListType | null;
  selected: string;
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  customersRef: React.MutableRefObject<any>;
  showSuccesfulSchoolEdit: boolean;
  setShowSuccessfulSchoolEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideBar: React.FC<SideBarProps> = ({
  userRole,
  userFirstName,
  userLastName,
  userSchool,
  selected,
  setActiveItem,
  customersRef,
  showSuccesfulSchoolEdit,
  setShowSuccessfulSchoolEdit
}) => {
  // States definition
  const [showPopUpMenu, setShowPopUpMenu] = useState(false);
  const [showLogoutPopUp, setShowLogoutPopUp] = useState(false);
  const [showProfilePopUp, setShowProfilePopUp] = useState(false);

  /**
   * Action to perform on click school logo
   */
  const handleClickSchoolProfile = () => {
    // Close the pop up menu
    setShowPopUpMenu(false);

    // First of all, check if the userSchool is defined
    if (!(userSchool && userSchool._id)) return;

    // Change the tab to customers
    setActiveItem("school-options");

    // Wait until customersRef is set
    const waitForCustomersRef = new Promise((resolve) => {
      const checkRef = () => {
        if (customersRef.current) {
          resolve(true);
        } else {
          requestAnimationFrame(checkRef);
        }
      };
      checkRef();
    });

    waitForCustomersRef.then(() => {
      customersRef.current.showSchool(userSchool._id, selected);
    });
  };

  /**
   * Function used when clicking logout in the school logo menu
   */
  const handleClickLogout = () => {
    // Close the current menu
    setShowPopUpMenu(false);
    // And show the pop up modal
    setShowLogoutPopUp(true);
  };

  /**
   * Handle profile pressing
   */
  const handlePressProfile = () => {
    // Close the current menu
    setShowPopUpMenu(false);
    // And show the pop up modal
    setShowProfilePopUp(true);
  };

  const customerAdminMenuOptions = [
    {
      icon: <MdOutlineAssignmentInd className="w-full h-full" />,
      text: "School Profile",
      onClickFunction: handleClickSchoolProfile,
    },
    {
      icon: <MdLogout className="w-full h-full" />,
      text: "Logout",
      onClickFunction: handleClickLogout,
    },
  ];

  const studentMenuOptions = [
    {
      icon: <MdOutlineAssignmentInd className="w-full h-full" />,
      text: "My Profile",
      onClickFunction: handlePressProfile,
    },
    {
      icon: <MdLogout className="w-full h-full" />,
      text: "Logout",
      onClickFunction: handleClickLogout,
    },
  ];

  return (
    <div className="w-[103px] self-stretch inline-flex flex-col justify-between items-center gap-4">
      <div className="px-3 py-4 bg-neutral-50 rounded-3xl flex flex-col justify-start items-start gap-8">
        <SideBarOption
          text="Dashboard"
          icon={
            selected === "dashboard" ? (
              <MdDashboard className="w-full h-full fill-neutral-700" />
            ) : (
              <MdOutlineDashboard className="w-full h-full fill-neutral-700" />
            )
          }
          active={selected === "dashboard"}
          setActiveItem={setActiveItem}
        />
        {/* {["master admin"].includes(userRole) && (
          <SideBarOption
            text="Customers"
            icon={
              selected === "customers" ? (
                <MdSupervisedUserCircle className="w-full h-full fill-neutral-700" />
              ) : (
                <MdOutlineSupervisedUserCircle className="w-full h-full fill-neutral-700" />
              )
            }
            active={selected === "customers"}
            setActiveItem={setActiveItem}
          />
        )} */}
        {["customer admin", "class admin"].includes(userRole) && (
          <SideBarOption
            text="Classes"
            icon={
              selected === "classes" ? (
                <MdSupervisedUserCircle className="w-full h-full fill-neutral-700" />
              ) : (
                <MdOutlineSupervisedUserCircle className="w-full h-full fill-neutral-700" />
              )
            }
            active={selected === "classes"}
            setActiveItem={setActiveItem}
          />
        )}
        {["master admin"].includes(userRole) && (
          <SideBarOption
            text="Users"
            icon={
              selected === "users" ? (
                <MdPeopleAlt className="w-full h-full fill-neutral-700" />
              ) : (
                <MdOutlinePeopleAlt className="w-full h-full fill-neutral-700" />
              )
            }
            active={selected === "users"}
            setActiveItem={setActiveItem}
          />
        )}
        <SideBarOption
          text="Lessons"
          icon={
            selected === "lessons" ? (
              <MdFeaturedPlayList className="w-full h-full fill-neutral-700" />
            ) : (
              <MdOutlineFeaturedPlayList className="w-full h-full fill-neutral-700" />
            )
          }
          active={selected === "lessons"}
          setActiveItem={setActiveItem}
        />
        {/* {["master admin"].includes(userRole) && (
          <SideBarOption
            text="Quizzes"
            icon={
              selected === "quizzes" ? (
                <MdQuiz className="w-full h-full fill-neutral-700" />
              ) : (
                <MdOutlineQuiz className="w-full h-full fill-neutral-700" />
              )
            }
            active={selected === "quizzes"}
            setActiveItem={setActiveItem}
          />
        )} */}
      </div>
      {["customer admin", "class admin"].includes(userRole) && (
        <div className="relative">
          <button
            className="overflow-hidden flex-col justify-end items-center gap-1 flex cursor-pointer"
            // onClick={handleClickSchoolProfile}
            onClick={() => setShowPopUpMenu(true)}
          >
            <div className="p-1 bg-neutral-50 rounded-full justify-start items-center gap-2 inline-flex">
              <div className="w-16 h-16 rounded-full justify-start items-end gap-2 flex overflow-hidden">
                <img
                  className="w-full h-full"
                  alt=""
                  src={`${BACKEND_URL}/imgs/organisations/logo/${userSchool?._id}`}
                  onError={({currentTarget}) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "/logo.svg";
                  }}
                />
              </div>
            </div>
            <div className="text-center text-neutral-700 text-xs font-normal font-sans leading-[14px]">
              {userSchool ? userSchool.name : "-"}
            </div>
          </button>
          {showPopUpMenu && (
            <PopUpMenu
              setShowPopUpMenu={setShowPopUpMenu}
              options={customerAdminMenuOptions}
            />
          )}
        </div>
      )}
      {["master admin", "student"].includes(userRole) && (
        <>
          <div className="w-full inline-flex items-center justify-center relative">
            <button
              className="h-fit flex-col justify-center items-center gap-2 inline-flex cursor-pointer"
              onClick={() => setShowPopUpMenu(true)}
            >
              <div className="justify-center items-center gap-0.5 inline-flex">
                <div className="px-[9.50px] py-2.5 bg-neutral-50 rounded-full justify-center items-center gap-2 flex hover:bg-neutral-100 ease-in-out duration-300">
                  <div className="text-dc-secondary-600 text-[23px] font-semibold font-sans leading-7 select-none">
                    {(userFirstName[0] || "-").toUpperCase()}
                    {(userLastName[0] || "-").toUpperCase()}
                  </div>
                </div>
                <div className="w-4 h-4 relative">
                  <MdOutlineExpandMore className="w-4 h-4 text-dc-secondary-600" />
                </div>
              </div>
              <div className="justify-center items-center gap-0.5 inline-flex">
                <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide select-none">
                  My Profile
                </div>
                <div className="w-4 h-4 px-[4.28px] opacity-0 justify-center items-center flex" />
              </div>
            </button>
            {showPopUpMenu && (
              <PopUpMenu
                setShowPopUpMenu={setShowPopUpMenu}
                options={studentMenuOptions}
              />
            )}
          </div>
          <PopUpPanel
            type="profile"
            firstName={userFirstName}
            lastName={userLastName}
            showPopUp={showProfilePopUp}
            setShowPopUp={setShowProfilePopUp}
          />
        </>
      )}
      <LogoutPopUp
        showPopUp={showLogoutPopUp}
        setShowPopUp={setShowLogoutPopUp}
      />
      <PopUpSuccessful
        showPopUp={showSuccesfulSchoolEdit}
        setShowPopUp={setShowSuccessfulSchoolEdit}
        onCreateAnotherSchool={handleClickSchoolProfile}
      />
    </div>
  );
};

export default SideBar;
