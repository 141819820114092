import { Transition } from "@headlessui/react";
import { Fragment, useCallback } from "react";
import {
  MdClose,
  MdMoreHoriz,
  MdDeleteOutline,
  MdOutlineDriveFileRenameOutline,
  MdOutlineDownload,
} from "react-icons/md";

interface OptionsMenuProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRenameSignal: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteOption?: () => void;
  handleDownloadOption?: () => void;
}

const OptionsMenu: React.FC<OptionsMenuProps> = ({
  open,
  setOpen,
  setRenameSignal,
  handleDeleteOption,
  handleDownloadOption
}) => {
  /**
   * Function to toggle the behaviour of the menu
   */
  const handleOpenClick = useCallback(() => {
    // We use the negative here because the value is modified once
    // clicked, so before open it's closed and viceversa.
    setOpen(!open);
  }, [open, setOpen]);

  return (
    <div className="relative flex">
      <button
        className="flex justify-center rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 cursor-pointer z-20"
        title="Options"
        onClick={handleOpenClick}
      >
        <div className="flex justify-center items-center p-2 w-11 h-11 bg-neutral-450 hover:bg-neutral-600 ease-in-out duration-150 rounded-full backdrop-blur-[32px]">
          <div className="absolute flex w-8 h-8 justify-center items-center">
            <Transition
              show={open}
              enter="transition-opacity ease-out duration-1000"
              enterFrom="transform opacity-0 scale-90"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-300"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MdClose className="w-6 h-6 text-dc-secondary-50" />
            </Transition>
          </div>
          <div className="absolute flex w-8 h-8 justify-center items-center">
            <Transition
              show={!open}
              enter="transition-opacity ease-out duration-1000"
              enterFrom="transform opacity-0 scale-90"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-300"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MdMoreHoriz className="w-6 h-6 text-dc-secondary-50" />
            </Transition>
          </div>
        </div>
        <Transition
          as={Fragment}
          show={open}
          enter="transition ease-out duration-300"
          enterFrom="transform opacity-0 scale-90 translate-y-1/2"
          enterTo="transform opacity-100 scale-100 translate-y-0"
          leave="transition ease-in duration-300"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className="absolute inline-flex flex-col gap-2 z-30 right-0 mt-2 top-10 w-fit h-fit rounded-lg justify-center items-center">
            <div
              className="bg-white text-neutral-700 hover:bg-neutral-100 ease-in-out duration-150 w-full h-[45px] py-2 px-5 rounded-lg justify-left items-center gap-2 inline-flex"
              onClick={handleDeleteOption}
            >
              <div className="w-6 h-6 relative">
                <MdDeleteOutline className="w-6 h-6" />
              </div>
              <div className="text-base font-normal font-sans leading-7">
                Delete
              </div>
            </div>
            <div
              className="bg-white text-neutral-700 hover:bg-neutral-100 w-full h-[45px] py-2 px-5 rounded-lg justify-left items-center gap-2 inline-flex"
              onClick={() => {
                setRenameSignal((current) => !current);
              }}
            >
              <div className="w-6 h-6 flex justify-center items-center">
                <MdOutlineDriveFileRenameOutline className="w-6 h-6" />
              </div>
              <div className="text-base font-normal font-sans leading-7">
                Rename
              </div>
            </div>
            <div
              className="bg-white text-neutral-700 hover:bg-neutral-100 w-full h-[45px] py-2 px-5 rounded-lg justify-left items-center gap-2 inline-flex"
              onClick={handleDownloadOption}
            >
              <div className="w-6 h-6 flex justify-center items-center">
                <MdOutlineDownload className="w-6 h-6" />
              </div>
              <div className="text-base font-normal font-sans leading-7">
                Download
              </div>
            </div>
          </div>
        </Transition>
      </button>
    </div>
  );
};

export default OptionsMenu;
