import React, { useState, useEffect, useRef, MutableRefObject } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import { AnimatePresence, motion } from "framer-motion";
import { MdCallReceived, MdOutlineDownload } from "react-icons/md";
import Video from "components/Editor/utils/Video";
import Player from "@vimeo/player";
import AssistantPopup from "../assistant/AssistantPopup";
import { AssistantAllButtonTypes, CodeFile } from "utils/interfaces";
import PopupContainer from "./popups/PopupContainer";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ScratchFilePopup from "./popups/ScratchFilePopup";
import ScratchEditPopup from "./popups/ScratchEditPopup";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FancyTabSwap from "./utils/FancyTabSwap";
import PDFViewer from "components/Editor/utils/PDFViewer";
import { fetchPdf, fetchVideoData } from "services/filesRequests";
import { platform } from "config/platform";

interface ScratchEditorHeaderProps {
  name: string;
  video: string;
  pdf: string;
  AIAssistant: "littleC" | "jakita" | "cody";
  activeItem: "editor" | "assistant";
  setActiveItem: React.Dispatch<React.SetStateAction<"assistant" | "editor">>;
  videoExpanded: boolean;
  setVideoExpanded: (bool: boolean) => void;
  hideVideo: boolean;
  showAssistant: boolean;
  assistantPopupState:
    | "closed"
    | "popup"
    | "popupNoCode"
    | "expanded"
    | "fullscreen";
  setAssistantPopupState: React.Dispatch<
    React.SetStateAction<
      "closed" | "popup" | "popupNoCode" | "expanded" | "fullscreen"
    >
  >;
  assistantPopupClickedButton: AssistantAllButtonTypes;
  setAssistantPopupClickedButton: React.Dispatch<
    React.SetStateAction<AssistantAllButtonTypes>
  >;
  onFileSelect: (file: File) => void;
  requestDownloadFile: () => void;
  requestSaveFile: () => void;
  requestRestoreSprite: () => void;
  requestToggleTurboMode: () => void;
  requestChangeTheme: () => void;
  scratchIframe: MutableRefObject<HTMLIFrameElement | null>;
  files: CodeFile[];
  theme: "default" | "dark";
}

const ScratchEditorHeader: React.FC<ScratchEditorHeaderProps> = ({
  name,
  video,
  pdf,
  AIAssistant,
  activeItem,
  setActiveItem,
  videoExpanded,
  setVideoExpanded,
  hideVideo,
  showAssistant,
  assistantPopupState,
  setAssistantPopupState,
  assistantPopupClickedButton,
  setAssistantPopupClickedButton,
  onFileSelect,
  requestDownloadFile,
  requestSaveFile,
  requestRestoreSprite,
  requestToggleTurboMode,
  requestChangeTheme,
  scratchIframe,
  files,
  theme,
}) => {
  // States definition
  const [showInstructions, setShowInstructions] = useState(false);
  const [videoThumbnail, setVideoThumbnail] = useState<any>(undefined);
  const [showVideo, setShowVideo] = useState(false);
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });

  // Define an image reference
  const imgRef = useRef<HTMLImageElement>(null);
  // Define a reference for the video iframe
  const videoIframeRef = useRef<HTMLIFrameElement>(null);
  // Define a reference for the tab
  const tabRef = useRef<any>(null);

  // Define a navigator state
  const navigate = useNavigate();

  // Get the parameters from the url
  const { moduleOrProject, id } = useParams();
  // Get a state if we are receiving a state variable from another url
  const { state } = useLocation();

  useEffect(() => {
    if (videoExpanded) {
      const timer = setTimeout(() => {
        setShowVideo(true);
      }, 300);

      return () => clearTimeout(timer);
    } else {
      setShowVideo(false);
    }
  }, [videoExpanded]);

  // --------------- For fetching video title and thumbnail ----------------//
  useEffect(() => {
    fetchVideoData(video).then((imageObjectURL: string) => {
      setVideoThumbnail(imageObjectURL);
    });
  }, [video]);

  // Return to home url
  const goToDashboard = () => {
    if (state) {
      navigate("/home", {
        state: {
          // State for SuperAdmin.tsx
          activeItem: "lessons",
          // State for Lessons.tsx
          activeLesson: moduleOrProject,
          navRegister: state ? state.navRegister : [],
        },
      });
    } else {
      navigate("/home");
    }
  };

  /*
   * Catch ctrl + s to run the code
   */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === "s") {
        event.preventDefault();
        requestSaveFile();
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  /**
   * Function used to pause video
   */
  const pauseVideo = () => {
    if (videoIframeRef.current) {
      const player = new Player(videoIframeRef.current);
      player.pause();
    }
  };

  /**
   * Function that defines the contextual behaviour of the back button
   */
  const handleClickBack = () => {
    if (activeItem === "assistant") setActiveItem("editor");
    else if (activeItem === "editor") goToDashboard();
  };

  const openAssistant = () => {
    // Start pausing the video
    pauseVideo();

    // And moving into the AI assistant view
    setVideoExpanded(false);
    setActiveItem("assistant");
  };

  const handleThumbnailClick = () => {
    // Start pausing the video
    pauseVideo();

    // Force closing the pdf
    setShowInstructions(false);
    // Move the tab index to the beginning
    if (tabRef.current) tabRef.current.updatePillPosition(0);

    console.log("expanding video...", !videoExpanded);
    setVideoExpanded(!videoExpanded);
  };

  const updateDimensions = () => {
    if (imgRef.current) {
      const dims = {
        width: imgRef.current.clientWidth || imgDimensions.width,
        height: imgRef.current.clientHeight || imgDimensions.height,
      };
      setImgDimensions(dims);
    }
  };

  // For uploading file
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onFileSelect(file);
    }
  };

  // For downloading file
  const handleDownloadClick = () => {
    requestDownloadFile();
  };

  /**
   * Function used to request save file
   */
  const handleSaveClick = () => {
    requestSaveFile();
  }

  /**
   * Function used to request change theme
   */
  const handleChangeTheme = () => {
    requestChangeTheme();
  }
  
  /**
   * Function to execute when clicking the PDF download button
   * @returns
   */
  const handleDownloadPDFFile = () => {
    // If there is no pdf, then prevent to execute this function
    if (!pdf) return;

    fetchPdf(moduleOrProject || "", id || "").then((url: string) => {
      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = url;

      // Set the donwload name
      link.download = pdf; 

      // Create the element, click it and the remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // And finally revoke the URL
      URL.revokeObjectURL(url);
    });
  };

  const onAssistantClick = () => {
    // Force close the pdf instructions
    setShowInstructions(false);
    // Move the tab index to the beginning
    if (tabRef.current) tabRef.current.updatePillPosition(0);

    // Get the current scratch code
    const iframe = scratchIframe.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage({ type: "GET_JSON" }, "*");
    }
  };

  return (
    <>
      <div className="w-full h-[155px] px-10 relative flex items-center">
        {/* Logo and back button */}
        <div className="absolute left-10 flex items-center gap-8">
          <img
            className={`${
              platform === "codeflix" ? "w-[124px] h-10" : "w-[97.80px] h-16"
            } cursor-pointer`}
            src="/logo.svg"
            onClick={goToDashboard}
            alt="logo"
          />
          <div className="w-px self-stretch bg-neutral-200" />
          <div className="justify-start items-center gap-3 flex">
            <div className="flex-col justify-start items-center gap-2 inline-flex">
              <div
                className="w-12 h-12 p-3 bg-neutral-50 hover:bg-neutral-100 ease-in-out duration-150 rounded-full justify-center items-center inline-flex cursor-pointer z-10"
                onClick={handleClickBack}
              >
                <div className="w-6 h-6 relative">
                  <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
                </div>
              </div>
              <div className="justify-start items-start gap-2 inline-flex">
                <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide select-none">
                  Back
                </div>
              </div>
            </div>
            <div className="flex-grow flex flex-col justify-center items-start gap-2 ml-3 select-none">
              <div className="self-stretch text-center text-neutral-600 text-[23px] font-bold font-['Plus Jakarta Sans'] leading-7">
                {name}
              </div>
              <FancyTabSwap
                ref={tabRef}
                buttons={
                  !!pdf
                    ? [
                        {
                          name: "Scratch",
                          onClick: () => setShowInstructions(false),
                        },
                        {
                          name: "Instructions",
                          onClick: () => setShowInstructions(true),
                        },
                      ]
                    : [
                        {
                          name: "Scratch",
                          onClick: () => setShowInstructions(false),
                        },
                      ]
                }
              />
            </div>
          </div>
        </div>

        {/* Assistant and video buttons */}
        {activeItem === "editor" && (
          <div className="absolute right-10 flex items-center mr-3 z-10">
            {/* Conditional rendering for Assistant */}

            <div className="flex space-x-2 mb-4 h-[80px]">
              <input
                type="file"
                id="file-input"
                accept=".sb3"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {/* file popup */}
              <PopupContainer icon={ArticleOutlinedIcon} title="File">
                <ScratchFilePopup
                  handleUpload={() =>
                    document.getElementById("file-input")?.click()
                  }
                  handleDownload={handleDownloadClick}
                  handleSave={handleSaveClick}
                />
              </PopupContainer>

              {/* edit popup */}
              <PopupContainer icon={EditOutlinedIcon} title="Edit">
                <ScratchEditPopup
                  theme={theme}
                  handleRestore={requestRestoreSprite}
                  handleTurbo={requestToggleTurboMode}
                  handleChangeTheme={handleChangeTheme}
                />
              </PopupContainer>

              {showAssistant && (
                <AssistantPopup
                  assistant={platform === "codeflix" ? "cody" : AIAssistant}
                  state={assistantPopupState}
                  setState={setAssistantPopupState}
                  clickedButton={assistantPopupClickedButton}
                  setClickedButton={setAssistantPopupClickedButton}
                  onAssistantClick={onAssistantClick}
                  codeFiles={files}
                  goToDashboard={goToDashboard}
                  language={"Scratch"}
                />
              )}
            </div>

            {/* Video placeholder */}
            {video !== "" && (
              <>
                <div className="w-6" />
                <div className="relative h-[111px] w-[180px]">
                  {assistantPopupState !== "fullscreen" &&
                    (videoExpanded ? (
                      <AnimatePresence>
                        <motion.div
                          key={"video-expanded"}
                          className={`cursor-pointer absolute overflow-hidden h-[111px] w-[180px] z-1000 rounded-lg`}
                          initial={{
                            opacity: 0,
                            backdropFilter: "blur(0px)",
                            background: "rgb(0 0 0 / 10%)",
                            right: 100,
                            top: 200,
                          }}
                          animate={{
                            opacity: 1,
                            right: 0,
                            top: 0,
                            backdropFilter: "blur(2px)",
                          }}
                          exit={{
                            opacity: 0,
                            backdropFilter: "blur(0px)",
                            right: 100,
                            top: 200,
                          }}
                          onClick={handleThumbnailClick}
                        >
                          <motion.div className="h-[22px] px-3 py-1 bg-neutral-50 rounded shadow backdrop-blur-[32px] justify-center items-center gap-3 inline-flex right-2 top-2 absolute">
                            <div className="text-dc-secondary-700 text-xs font-semibold font-['Poppins'] uppercase leading-[14px] tracking-wide select-none">
                              Show Code
                            </div>
                          </motion.div>
                          <motion.div className="h-10 w-10 p-1 bg-gradient-to-tr bg-neutral-300 bg-opacity-50 rounded-tr rounded-bl-lg shadow backdrop-blur-xl justify-center gap-2 inline-flex left-0 bottom-0 absolute items-center">
                            <MdCallReceived className="h-full w-full fill-white" />
                          </motion.div>
                        </motion.div>
                      </AnimatePresence>
                    ) : (
                      <AnimatePresence initial={false}>
                        <motion.div
                          key={"code-expanded"}
                          className={`cursor-pointer absolute overflow-hidden h-[111px] w-[180px] z-1000 rounded-lg`}
                          onClick={handleThumbnailClick}
                          initial={{
                            opacity: 0,
                            right: 100,
                            top: 200,
                          }}
                          animate={{
                            opacity: 1,
                            right: 0,
                            top: 0,
                          }}
                          exit={{
                            opacity: 0,
                            right: 100,
                            top: 200,
                          }}
                        >
                          <motion.div className="h-[22px] px-3 py-1 bg-neutral-50 rounded shadow backdrop-blur-[32px] justify-center items-center gap-3 inline-flex right-2 top-2 absolute">
                            <div className="text-dc-secondary-700 text-xs font-semibold font-['Poppins'] uppercase leading-[14px] tracking-wide select-none">
                              Play Video
                            </div>
                          </motion.div>
                          <motion.div className="h-10 w-10 p-1 bg-gradient-to-tr white rounded-tr rounded-bl-lg shadow backdrop-blur-xl justify-center gap-2 inline-flex left-0 bottom-0 absolute items-center">
                            <MdCallReceived className="h-full w-full fill-white" />
                          </motion.div>
                        </motion.div>
                      </AnimatePresence>
                    ))}
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <motion.div
        className={`absolute w-full h-screen items-center max-h-[calc(100vh-203px)] right-12 mb-12 ${
          hideVideo ? "hidden" : "block"
        }`}
        initial={{
          top: 22,
          width: 155,
        }}
        animate={{
          top: videoExpanded ? 155 : 22,
          width: videoExpanded ? "calc(100% - 96px)" : 180,
          height: videoExpanded ? undefined : 111,
          right: videoExpanded ? undefined : 52,
          transition: { ease: "linear" },
        }}
        onUpdate={updateDimensions}
      >
        <div
          className={`h-full w-fit rounded-lg mx-auto relative ${
            videoExpanded ? "z-1" : ""
          }`}
        >
          <motion.div
            ref={imgRef}
            className={`h-full w-fit ${showVideo ? "hidden" : "block"}`}
          >
            {videoThumbnail !== undefined ? (
              <img
                className={`h-full rounded-lg mx-auto`}
                src={videoThumbnail}
                alt="Video Thumbnail"
              />
            ) : (
              video && (
                <div className="h-[111px] w-[180px] rounded-lg mx-auto bg-neutral-100" />
              )
            )}
          </motion.div>
          <Video
            iframeRef={videoIframeRef}
            width={imgDimensions.width}
            className={`rounded-xl mx-auto overflow-hidden ${
              showVideo ? "block" : "hidden"
            }`}
            isOpen={true}
            videoUrl={video}
          />
        </div>
      </motion.div>

      {!!pdf && (
        <motion.div
          className="fixed w-screen h-screen max-h-[calc(100vh-155px)] bg-white bottom-0 z-50 p-8"
          initial={{
            x: "100%",
            opacity: 0,
          }}
          animate={{
            x: showInstructions ? "0%" : "100%",
            opacity: showInstructions ? 1 : 0,
          }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        >
          <div className="inline-flex flex-col gap-3 bg-neutral-50 w-full h-full rounded-lg p-4">
            <div className="h-9 justify-end items-center gap-2 inline-flex">
              <div
                className="p-2 bg-neutral-100 hover:bg-neutral-200 ease-in-out duration-150 rounded-full justify-center items-center gap-2 flex cursor-pointer"
                onClick={handleDownloadPDFFile}
              >
                <div className="w-5 h-5 relative">
                  <MdOutlineDownload className="w-full h-full fill-neutral-700" />
                </div>
              </div>
            </div>
            <PDFViewer isPaning={false} isVisible={true} />
          </div>
        </motion.div>
      )}
    </>
  );
};

export default ScratchEditorHeader;
