import { MdDeleteOutline, MdDragIndicator, MdOutlineEdit } from "react-icons/md";

interface QuestionCardProps {
  index: number;
  question: string;
  deleteQuestionAction: (index: number) => void;
  editQuestionAction: (index: number) => void;
}

const QuestionCard: React.FC<QuestionCardProps> = ({
  index,
  question,
  deleteQuestionAction,
  editQuestionAction,
}) => {
  /**
   * Function when pressing delete button
   */
  const handleDeleteCard = (event: React.MouseEvent) => {
    // Prevent to do edit as well
    event.stopPropagation();
    // Perform the delete action
    deleteQuestionAction(index);
  };

  /**
   * Function when pressing on the card
   */
  const handleEditCard = () => {
    // Perform the delete action
    editQuestionAction(index);
  };

  return (
    <div className="w-full h-[62px] p-3 bg-neutral-50 rounded-lg justify-center items-center gap-2 inline-flex">
      <div className="w-6 h-6 relative cursor-grab">
        <MdDragIndicator className="w-full h-full fill-neutral-700" />
      </div>
      <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[19px] select-none">
        {question}
      </div>
      <div
        className="p-1 bg-neutral-200 hover:bg-neutral-300 ease-in-out duration-100 rounded-full justify-start items-center gap-2 flex cursor-pointer"
        onClick={handleEditCard}
      >
        <div className="w-5 h-5 relative">
          <MdOutlineEdit className="w-full h-full fill-neutral-700" />
        </div>
      </div>
      <div
        className="p-1 bg-neutral-200 hover:bg-neutral-300 ease-in-out duration-100 rounded-full justify-start items-center gap-2 flex cursor-pointer"
        onClick={handleDeleteCard}
      >
        <div className="w-5 h-5 relative">
          <MdDeleteOutline className="w-full h-full fill-neutral-700" />
        </div>
      </div>
    </div>
  );
};

export default QuestionCard;
