import Cookies from "js-cookie";
import { JwtPayload, jwtDecode } from "jwt-decode";

// Required for TypeScript to recognize our payload contains an id
interface UserToken extends JwtPayload { 
  _id: string;
};

// Decodes the current user's login token to retrieve their user id
export const getUserIdFromToken = () => {
  // Get the cookie with name "accessToken"
  const accessToken = Cookies.get("accessToken");
  // Decode the JWT token
  if (accessToken) {
    const decodedToken = jwtDecode<UserToken>(accessToken);
    return decodedToken._id;
  }
  return null;
};
