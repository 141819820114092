import React, { useEffect } from "react";
import BrowserInteractionTime, {
  AbsoluteTimeEllapsedCallbackData,
  TimeIntervalEllapsedCallbackData,
} from "browser-interaction-time";
import { logUsage } from "services/usageRequests";
import { validateClient } from "services/authRequests";

// Defining the timer props such that all settings are configurable through props
// Mimics the private 'Settings' interface and 'BasicCallbacks' type in 'browser-interaction-time.d.ts'
type Callback = (timeInMs: number) => void;
interface ActivityTimerProps {
  timeIntervalEllapsedCallbacks?: TimeIntervalEllapsedCallbackData[];
  absoluteTimeEllapsedCallbacks?: AbsoluteTimeEllapsedCallbackData[];
  browserTabInactiveCallbacks?: Callback[];
  browserTabActiveCallbacks?: Callback[];
  idleCallbacks?: Callback[];
  activeCallbacks?: Callback[];
  idleTimeoutMs?: number;
  stopTimerOnTabchange?: boolean;
  checkCallbacksIntervalMs?: number;
  initialDelayMs?: number;
  intervalMs?: number;
  type?: string;
  itemId?: string;
}

const ActivityTimer: React.FC<ActivityTimerProps> = ({
  // Default timer settings if not overwritten through props
  timeIntervalEllapsedCallbacks = [],
  absoluteTimeEllapsedCallbacks = [],
  browserTabInactiveCallbacks = [],
  browserTabActiveCallbacks = [],
  idleCallbacks = [],
  activeCallbacks = [],
  idleTimeoutMs = 60000,
  stopTimerOnTabchange = true,
  checkCallbacksIntervalMs = 1000, // needs to be non-zero for idle timeout to work
  initialDelayMs = 0, // when 0, the timer will immediately logs a user's presence when entering a new page
  intervalMs = 30000,
  type,
  itemId,
}) => {
  // States definition
  let prevTimeMs = 0;

  useEffect(() => {
    // Uncomment this function here and in timeIntervalEllapsedCallbacks to print display the timer in console
    // Useful for debugging and development

    // const displayTimeCallback = {
    //   timeInMilliseconds: initialDelayMs, // initial delay before first callback
    //   multiplier: (time: number) => time + 1000, // interval for following callbacks (e.g. time + 60000 means every 60s)
    //   callback: () => {
    //     console.log(timer.getTimeInMilliseconds())
    //   }
    // }

    // Define the callback that will log the user's use time every minute
    const usageLogCallback = {
      timeInMilliseconds: initialDelayMs, // initial delay before first callback
      multiplier: (time: number) => time + intervalMs, // interval for following callbacks (e.g. time + 60000 means every 60s)
      callback: () => {
        // Check if user is logged in
        validateClient()
          .then((response: { connected: boolean; navigateTo: string }) => {
            if (response.connected) {
              // Calculate elapsed time since last log
              const totalElapsedTimeMs = timer.getTimeInMilliseconds();
              const secondsSinceLastLog =
                (totalElapsedTimeMs - prevTimeMs) / 1000;

              // Location definition
              const location = window.location.pathname;

              // Update the state
              prevTimeMs = totalElapsedTimeMs;

              // Log usage time in database (no await since nothing relies on the response)
              logUsage(location, secondsSinceLastLog, type, itemId);
              logUsage('', secondsSinceLastLog, 'total usage'); // increment the users running total usage too
            }
          })
          .catch((error) => {
            console.warn(error);
          });
      },
    };

    // Initialize and start the activity timer
    const timer = new BrowserInteractionTime({
      // Ensure we add the callback above that logs user usage every minute to prop callbacks
      timeIntervalEllapsedCallbacks: [
        ...timeIntervalEllapsedCallbacks,
        usageLogCallback, 
        // displayTimeCallback,
      ],
      absoluteTimeEllapsedCallbacks: absoluteTimeEllapsedCallbacks,
      browserTabInactiveCallbacks: browserTabInactiveCallbacks,
      browserTabActiveCallbacks: browserTabActiveCallbacks,
      idleCallbacks: idleCallbacks,
      activeCallbacks: activeCallbacks,
      idleTimeoutMs: idleTimeoutMs,
      stopTimerOnTabchange: stopTimerOnTabchange,
      checkCallbacksIntervalMs: checkCallbacksIntervalMs,
    });
    timer.startTimer();

    return () => timer.destroy(); // Cleanup timer on component unmount
  }, []); // Empty dependency array, so this runs only once on mount

  return null;
};

export default ActivityTimer;
