import LoginForm from "./LoginForm";
import LandingPageBackground from "components/login/LandingPageBackground";
import MainWindow from "components/login/MainWindow";
import { platform } from "config/platform";
import { Link } from "react-router-dom";

const Login: React.FC = () => {
  return (
    <LandingPageBackground>
      <MainWindow>
        <div className="min-w-[300px] max-w-[414px] w-full self-stretch flex-col justify-start items-center gap-8 inline-flex">
          <div className="self-stretch h-fit flex-col justify-start items-start gap-6 flex">
            <div className="self-stretch justify-start items-center gap-2 inline-flex">
              <div className="grow shrink basis-0 text-neutral-700 text-3xl font-semibold font-sans leading-[34px]">
                Sign In to your account
              </div>
            </div>
            <div className="self-stretch justify-start items-center gap-2 inline-flex">
              <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[21px]">
                Welcome back! Sign in to access your account
              </div>
            </div>
          </div>
          <LoginForm />
          {platform === "codeflix" && (
            <div className="w-full justify-start items-start gap-2 inline-flex">
              <div className="text-neutral-700 text-sm font-normal font-sans leading-[17px]">
                Don't have an account?
              </div>
              <Link to="/sign-up">
                <div className="text-neutral-700 hover:text-neutral-900 text-sm font-semibold font-sans underline leading-[17px]">
                  Find out how to sign up
                </div>
              </Link>
            </div>
          )}
        </div>
      </MainWindow>
    </LandingPageBackground>
  );
};

export default Login;
