import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  resetPasswordCheckResetId,
  setupPasswordRequest,
} from "services/authRequests";
import { FormProvider, useForm } from "react-hook-form";
import {
  haveDigitRegex,
  haveLowercaseRegex,
  haveUppercaseRegex,
  minimumCharactersRegex,
  minPasswordCharacters,
} from "utils/regex";
import { LoadingDots } from "utils/loadingAnimation";
import {
  MdOutlineCheckCircle,
  MdLockOpen,
  MdOutlineCancel,
} from "react-icons/md";
import LandingPageBackground from "components/login/LandingPageBackground";
import MainWindow from "components/login/MainWindow";
import VisibilityButton from "components/login/VisibilityButton";
import { FaCircleCheck } from "react-icons/fa6";

const SetupPassword: React.FC = () => {
  // States definition
  const [email, setEmail] = useState("");
  const [pwdVisibility, setPwdVisibility] = useState<boolean>(false);
  const [pwdRepeatVisibility, setPwdRepeatVisibility] =
    useState<boolean>(false);
  const [uppercaseCheck, setUppercaseCheck] = useState<boolean>(false);
  const [lowercaseCheck, setLowercaseCheck] = useState<boolean>(false);
  const [digitCheck, setDigitCheck] = useState<boolean>(false);
  const [minCharactersCheck, setMinCharactersCheck] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordRepeat, setPasswordRepeat] = useState<string>("");
  const [setupLoading, setSetupLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [requestSent, setRequestSent] = useState<boolean>(false);

  // Navigate definition
  const navigate = useNavigate();

  // Form hooks
  const methods = useForm({ mode: "onChange" });

  // Get the resetTokenId
  const { resetTokenId } = useParams();

  // Check if the resetTokenId is valid, if not send again to resetPassword
  useEffect(() => {
    resetPasswordCheckResetId(resetTokenId || "").then((response) => {
      // If the response it's not successful, send to reset-password again
      if (!response.successful) {
        // navigate("/reset-password");
      }

      // Set the email of the reset token
      setEmail(response.email);
    });
  }, []);

  // Define the password validation on every change
  useEffect(() => {
    // Set every state
    setMinCharactersCheck(minimumCharactersRegex.test(password));
    setUppercaseCheck(haveUppercaseRegex.test(password));
    setLowercaseCheck(haveLowercaseRegex.test(password));
    setDigitCheck(haveDigitRegex.test(password));
  }, [password, passwordRepeat]);

  // Function for submitting
  const handlePasswordSubmit = methods.handleSubmit(async () => {
    // Check avoid run this function again after being executed
    if (setupLoading) return;

    // Set animation state
    setSetupLoading(true);

    // Define the object to send
    const dataToSend = {
      resetTokenId: resetTokenId || "",
      password: password || "",
    };

    // Send the reset password information for checking
    const response: {
      successful: boolean;
      message: string;
      redirectToReset: boolean;
    } = await setupPasswordRequest(dataToSend);

    // Stop the animation
    setSetupLoading(false);

    console.log(response);

    // If the response is successful
    if (response.successful) {
      // Then go to the home page
      setShowError(false);
      // Show the successful view
      setRequestSent(true);
    } else {
      // Display an error message
      setShowError(true);
    }

    // Check if there is necessary to redirect
    if (response.redirectToReset) navigate("/reset-password");
  });

  return (
    <LandingPageBackground>
      <MainWindow>
        <div
          className={`self-stretch h-full flex-col ${
            requestSent ? "justify-center" : "justify-start"
          } items-center gap-8 inline-flex`}
        >
          {!requestSent && (
            <>
              <div className="self-stretch h-fit flex-col justify-start items-start gap-6 flex">
                <div className="text-neutral-700 text-[33px] font-semibold font-sans leading-[34px]">
                  Set new password
                </div>
                <div className="self-stretch h-fit flex-col justify-start items-start gap-1 flex">
                  <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[21px]">
                    Please set your password for
                  </div>
                  <div className="grow shrink basis-0 text-neutral-700 text-base font-semibold font-sans leading-[21px]">
                    {email}
                  </div>
                </div>
              </div>
              <div className="self-stretch h-[183px] flex-col justify-center items-start gap-4 flex">
                <div className="self-stretch justify-start items-start gap-2 inline-flex">
                  <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[19px]">
                    Your password must have at least:
                  </div>
                </div>
                <div className="self-stretch h-[148px] flex-col justify-start items-start gap-3 flex">
                  <div className="self-stretch justify-start items-center gap-2 inline-flex">
                    <div className="w-5 h-5 relative">
                      {minCharactersCheck ? (
                        <MdOutlineCheckCircle className="w-full h-full fill-dc-accent-500" />
                      ) : (
                        <MdOutlineCancel className="w-full h-full fill-neutral-700" />
                      )}
                    </div>
                    <div className="h-[19px] justify-start items-start gap-2 flex">
                      <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[19px]">
                        {minPasswordCharacters} Characters
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch justify-start items-center gap-2 inline-flex">
                    <div className="w-5 h-5 relative">
                      {uppercaseCheck ? (
                        <MdOutlineCheckCircle className="w-full h-full fill-dc-accent-500" />
                      ) : (
                        <MdOutlineCancel className="w-full h-full fill-neutral-700" />
                      )}
                    </div>
                    <div className="h-[19px] justify-start items-start gap-2 flex">
                      <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[19px]">
                        1 uppercase character
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch justify-start items-center gap-2 inline-flex">
                    <div className="w-5 h-5 relative">
                      {lowercaseCheck ? (
                        <MdOutlineCheckCircle className="w-full h-full fill-dc-accent-500" />
                      ) : (
                        <MdOutlineCancel className="w-full h-full fill-neutral-700" />
                      )}
                    </div>
                    <div className="h-[19px] justify-start items-start gap-2 flex">
                      <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[19px]">
                        1 lowercase character
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch justify-start items-center gap-2 inline-flex">
                    <div className="w-5 h-5 relative">
                      {digitCheck ? (
                        <MdOutlineCheckCircle className="w-full h-full fill-dc-accent-500" />
                      ) : (
                        <MdOutlineCancel className="w-full h-full fill-neutral-700" />
                      )}
                    </div>
                    <div className="h-[19px] justify-start items-start gap-2 flex">
                      <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[19px]">
                        1 digit
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch justify-start items-center gap-2 inline-flex">
                    <div className="w-5 h-5 relative">
                      {password &&
                      passwordRepeat &&
                      password === passwordRepeat ? (
                        <MdOutlineCheckCircle className="w-full h-full fill-dc-accent-500" />
                      ) : (
                        <MdOutlineCancel className="w-full h-full fill-neutral-700" />
                      )}
                    </div>
                    <div className="h-[19px] justify-start items-start gap-2 flex">
                      <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[19px]">
                        Passwords must match
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FormProvider {...methods}>
                <form
                  id="setup-password-form"
                  className="w-full"
                  onSubmit={(event) => event.preventDefault()}
                  noValidate
                  autoComplete="off"
                >
                  <div className="self-stretch h-[236px] flex-col justify-start items-start gap-6 flex">
                    <div className="self-stretch h-[71px] flex-col justify-start items-start gap-1.5 flex">
                      <div className="rounded justify-start items-start gap-2 inline-flex">
                        <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide select-none">
                          Password
                        </div>
                      </div>
                      <div className="w-full h-full rounded-lg bg-neutral-50 text-neutral-700 placeholder:text-neutral-300 text-base font-normal font-sans leading-[19px] flex items-center relative">
                        <div className="absolute left-3.5 w-5 h-5 z-20">
                          <MdLockOpen className="w-full h-full text-neutral-500" />
                        </div>
                        <input
                          id="password"
                          disabled={setupLoading}
                          type={pwdVisibility ? "text" : "password"}
                          className={`w-full h-full pl-[42px] pr-12 rounded-lg bg-transparent ${
                            setupLoading
                              ? "text-neutral-300"
                              : "text-neutral-700"
                          } placeholder:text-neutral-300 placeholder:select-none focus:outline-none text-base font-normal font-sans leading-[19px] z-10`}
                          placeholder="Enter your password"
                          autoComplete="password"
                          onChange={(event) => setPassword(event.target.value)}
                        />
                        <VisibilityButton
                          visibility={pwdVisibility}
                          setVisibility={setPwdVisibility}
                        />
                      </div>
                    </div>
                    <div className="self-stretch h-[71px] flex-col justify-start items-start gap-1.5 flex">
                      <div className="rounded justify-start items-start gap-2 inline-flex">
                        <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide select-none">
                          Repeat Password
                        </div>
                      </div>
                      <div className="w-full h-full rounded-lg bg-neutral-50 text-neutral-700 placeholder:text-neutral-300 text-base font-normal font-sans leading-[19px] flex items-center relative">
                        <div className="absolute left-3.5 w-5 h-5 z-20">
                          <MdLockOpen className="w-full h-full text-neutral-500" />
                        </div>
                        <input
                          id="repeat-password"
                          disabled={setupLoading}
                          type={pwdRepeatVisibility ? "text" : "password"}
                          className={`w-full h-full pl-[42px] pr-12 rounded-lg bg-transparent ${
                            setupLoading
                              ? "text-neutral-300"
                              : "text-neutral-700"
                          } placeholder:text-neutral-300 placeholder:select-none focus:outline-none text-base font-normal font-sans leading-[19px] z-10`}
                          placeholder="Repeat the password"
                          autoComplete="password"
                          onChange={(event) =>
                            setPasswordRepeat(event.target.value)
                          }
                        />
                        <VisibilityButton
                          visibility={pwdRepeatVisibility}
                          setVisibility={setPwdRepeatVisibility}
                        />
                      </div>
                    </div>
                    {lowercaseCheck &&
                    uppercaseCheck &&
                    digitCheck &&
                    minCharactersCheck &&
                    password === passwordRepeat ? (
                      <button
                        className="self-stretch px-5 py-3 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 rounded justify-center items-center gap-2 inline-flex"
                        onClick={handlePasswordSubmit}
                      >
                        <div className="text-white text-lg font-semibold font-sans leading-snug select-none">
                          {setupLoading ? (
                            <LoadingDots />
                          ) : (
                            "Confirm new password"
                          )}
                        </div>
                      </button>
                    ) : (
                      <button
                        className="self-stretch px-5 py-3 bg-Subtle rounded justify-center items-center gap-2 inline-flex"
                        disabled
                      >
                        <div className="text-neutral-400 text-lg font-semibold font-sans leading-snug select-none">
                          Confirm new password
                        </div>
                      </button>
                    )}
                  </div>
                </form>
              </FormProvider>
            </>
          )}
          {requestSent && (
            <>
              <div className="self-stretch h-fit flex-col justify-start items-start gap-6 flex">
                <div className="self-stretch justify-start items-center gap-2 inline-flex">
                  <div className="w-10 h-10 relative bg-white rounded-full">
                    <FaCircleCheck className="w-full h-full fill-dc-accent-600" />
                  </div>
                  <div className="grow shrink basis-0 text-neutral-700 text-[33px] font-semibold font-sans leading-[34px]">
                    You’re all set!
                  </div>
                </div>
                <div className="self-stretch h-fill flex-col justify-start items-start gap-3 flex">
                  <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[21px]">
                    Your Password has been reset successfully!
                  </div>
                  <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[21px]">
                    Sign In to start learning
                  </div>
                </div>
              </div>
              <Link
                to="/"
                className="self-stretch px-5 py-3 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 rounded justify-center items-center gap-2 inline-flex"
              >
                <div className="text-white text-lg font-semibold font-sans leading-snug">
                  Sign In to your account
                </div>
              </Link>
            </>
          )}
          <div className="w-full justify-center items-center gap-2 inline-flex">
            <Link to="mailto:codeflix@jnrengineers.com">
              <div className="h-fit rounded justify-center items-center gap-2 inline-flex">
                <div className="text-dc-accent-700 hover:text-dc-accent-800 text-sm font-semibold font-sans underline leading-[17px] select-none">
                  Email support
                </div>
              </div>
            </Link>
          </div>
        </div>
      </MainWindow>
    </LandingPageBackground>
  );
};

export default SetupPassword;
