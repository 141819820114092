import { BACKEND_URL } from "config/getUrl";
import Cookies from "js-cookie";
import { validateClient } from "./authRequests";

/**
 * Function used to get a scratch file from AWS
 * @param id _id of the lesson.
 * @param lessonType Type of lesson of interest. It could be "projects" or "modules".
 * @returns
 */
export const getScratchFileFromAWS = async (id: string, lessonType: string) => {
  try {
    // Validate the tokens
    const validateResponse = await validateClient();

    // Check if the user is still connected
    if (!validateResponse.connected) {
      return {
        message: "It was not possible to find an access or refresh token.",
        successful: false,
        logout: true,
      };
    }

    // Get the accessToken
    const accessToken = Cookies.get("accessToken");

    // To include it in the request
    const response = await fetch(
      `${BACKEND_URL}/api/code/scratch/${lessonType}/${id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/x-scratch-project",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    // Check the health of the response
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get the contentype
    const contentType = response.headers.get("Content-Type");

    // Check if the content type is correct
    if (contentType !== "application/x-scratch-project") {
      console.warn("Unexpected Content-Type:", contentType);
    }

    const arrayBuffer = await response.arrayBuffer();

    // Validate that it's a .sb3 file (check for ZIP file signature)
    const header = new Uint8Array(arrayBuffer.slice(0, 4));
    if (
      header[0] === 0x50 &&
      header[1] === 0x4b &&
      header[2] === 0x03 &&
      header[3] === 0x04
    ) {
      return arrayBuffer;
    } else {
      console.error(
        "Invalid file signature:",
        Array.from(header)
          .map((b) => b.toString(16).padStart(2, "0"))
          .join(" ")
      );
      throw new Error("Invalid file format. Expected .sb3 file (ZIP format).");
    }
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

/**
 * Function used to fetch videos for code editors
 * @param video
 * @returns
 */
export const fetchVideoData = async (video: string) => {
  try {
    // Validate the tokens
    const validateResponse = await validateClient();

    // Check if the user is still connected
    if (!validateResponse.connected) {
      return "";
    }

    // Get the accessToken
    const accessToken = Cookies.get("accessToken");

    // Get the videoId
    const videoId = video.split("/").pop();

    // Request the video
    const response = await fetch(
      `${BACKEND_URL}/api/files/vimeothumbnail/${videoId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    // Processing
    const imageBlob = await response.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    if (response && response.ok && response.body) {
      return imageObjectURL;
    }

    return "";
  } catch (error) {
    console.error("Error fetching video data", error);
    return "";
  }
};

/**
 * Function used to fetch a pdf
 * @param moduleOrProject
 * @param id
 * @returns
 */
export const fetchPdf = async (moduleOrProject: string, id: string) => {
  try {
    // Validate the tokens
    const validateResponse = await validateClient();

    // Check if the user is still connected
    if (!validateResponse.connected) {
      return "";
    }

    // Get the accessToken
    const accessToken = Cookies.get("accessToken");

    // Get the PDF
    const response = await fetch(
      `${BACKEND_URL}/api/files/${moduleOrProject}/pdf/${id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    // Processing
    const blob = await response.blob();

    // Get the url of this blob
    const url = URL.createObjectURL(blob);

    return url;
  } catch (error) {
    console.error("Error fetching PDF:", error);
    return "";
  }
};

export const upsertCodeRequest = async (
  codeData: object | string | ArrayBuffer | null,
  codeEndpoint: "python-js" | "scratch"
) => {
  // Validate the tokens
  const validateResponse = await validateClient();

  // Check if the user is still connected
  if (!validateResponse.connected) {
    return {
      message: "It was not possible to find an access or refresh token.",
      successful: false,
      logout: true,
    };
  }

  // Get the accessToken
  const accessToken = Cookies.get("accessToken");

  // Check that the access token is not undefined
  if (!accessToken) {
    return {
      message: "It was not possible to find an access token.",
      successful: false,
      logout: true,
    };
  }

  // Set up the options for the request
  const options: RequestInit = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(codeData),
  };

  // Post the record to the specified
  const response = await fetch(
    `${BACKEND_URL}/api/code/${codeEndpoint}`,
    options
  );
  

  // Check if the status of the answer is ok
  if (response.status === 200) {
    // Get the response
    return await response.json();
  }
};
