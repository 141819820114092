import React, { useState, useEffect, useCallback, useRef } from "react";
import "split-pane-react/esm/themes/default.css";
import CodeEditor from "components/Editor/python-js-editor/CodeEditor";
import ScratchEditorHeader from "components/Editor/scratch-editor/ScratchEditorHeader";
import ScratchEditor from "components/Editor/scratch-editor/ScratchEditor";
import ActivityTimer from "components/activity-timer/ActivityTimer";
import Cookies from "js-cookie";
import { getCollectionRequest, populateEditor } from "services/apiRequests";
import { useNavigate, useParams } from "react-router-dom";
import { AssistantAllButtonTypes, CodeFile, languageType } from "utils/interfaces";
import { getScratchFileFromAWS } from "services/filesRequests";
import { logoutFunction, validateClient } from "services/authRequests";
import { getUserIdFromToken } from "services/userRequest";
import { EditorContext } from "components/main-view/utils/Contexts";
import { programLanguagesList } from "config/optionValues";
import CodeEditorHeader from "components/Editor/python-js-editor/CodeEditorHeader";
import ExternalEditorHeader from "components/Editor/external-editor/ExternalEditorHeader";
import ExternalEditor from "components/Editor/external-editor/ExternalEditor";
import InactivityTimer from "components/activity-timer/InactivityTimer";
import { idleTimeout } from "config/platform";

// Parameters
const zeroThreshold = 1e-6; // Parameter used to define the threshold to start defining zero when panning (JS & Python Code Editor)
const pythonJSHeaderOffset = 123;

const Editor: React.FC = () => {
  const [activeItem, setActiveItem] = useState<"editor" | "assistant">(
    "editor"
  ); // to change between editor and assistant views
  const [videoExpanded, setVideoExpanded] = useState(false);
  const [name, setName] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [fileState, setFileState] = useState<CodeFile[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [pdf, setPdf] = useState<string>("");
  const [language, setLanguage] = useState<languageType | null>(null);
  const [videoTranscript, setVideoTranscript] = useState("");

  // For scratch editor
  const [sb3File, setSb3File] = useState<ArrayBuffer | null>(null);
  const [sb3Error, setSb3Error] = useState<string | null>(null);
  const [sb3Loading, setSb3Loading] = useState<boolean>(false);
  const scratchIframeRef = useRef<HTMLIFrameElement | null>(null);
  const [downloadSignal, setDownloadSignal] = useState(false);
  const [restoreSpriteSignal, setRestoreSpriteSignal] = useState(false);
  const [saveSignal, setSaveSignal] = useState(false);
  const [toggleTurboModeSignal, setToggleTurboModeSignal] = useState(false);
  const [AIAssistant, setAIAssistant] = useState<"littleC" | "jakita">(
    "littleC"
  );
  const [scratchTheme, setScratchTheme] = useState<"default" | "dark">("default");
  const [assistantPopupState, setAssistantPopupState] = useState<
    "closed" | "popup" | "popupNoCode" | "expanded" | "fullscreen"
  >("closed");
  const [assistantPopupClickedButton, setAssistantPopupClickedButton] =
    useState<AssistantAllButtonTypes>(undefined);
  // User information
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");

  // Navigate hook
  const navigate = useNavigate();
  
  // Get the url parameters
  const { moduleOrProject, id } = useParams();
  /**
   * Check if the user has an access token every time we enter this view
   */
  useEffect(() => {
    validateClient().then(
      (response: { connected: boolean; navigateTo: string }) => {
        // Check if the token is validated
        if (!response.connected) {
          navigate("/");
        } else {
          // Get the user _id
          const _id = getUserIdFromToken();

          // And request the information
          getCollectionRequest(
            "/api/users",
            ["firstName", "lastName", "role", "organisations", "classes"],
            { _id }
          ).then((response) => {
            // Check if the response is valid
            if (!(response && response.successful)) {
              navigate("/");
              return;
            }

            // Get the user
            const user = response.content[0];

            // And set the states
            setUserFirstName(user.firstName);
            setUserLastName(user.lastName);
          });

          // Set an editor session cookie
          Cookies.set(
            "editorSession",
            Math.floor(Math.random() * Math.pow(36, 8))
              .toString(36)
              .padStart(8, "0")
          );
        }
      }
    );
  }, []);

  // Retrieve module data from db
  const fetchModuleData = useCallback(
    async (id: string) => {
      if (typeof id !== "string") {
        console.error("Invalid moduleId:", id);
        return;
      }
      try {
        const data = await populateEditor(moduleOrProject, id);
        if (data) {
          setFileState(data.files);
          setName(data.title);
          setVideo(data.videoUrl);
          setPdf(data.planName);
          setLanguage(data.language);
          setLoaded(true);
          setVideoTranscript(data.videoTranscript);

          if (data.language === "Scratch") {
            handleFetchSb3File();
          }
        } else {
          console.error("Invalid module data received from backend");
        }
      } catch (error) {
        console.error("Error fetching module data:", error);
      }
    },
    [moduleOrProject]
  );

  /**
   * Get the data
   */
  useEffect(() => {
    if (id) {
      fetchModuleData(id);
    }
  }, [id, fetchModuleData]);

  /**
   * Define the AI assistant
   */
  useEffect(() => {
    if (id) {
      // Get a boolean indicating if the last character of the id is
      // odd or even when transforming that into an ASCII code
      const isEven = id.slice(-1).charCodeAt(0) % 2 === 0;

      // If it's even, display Jakita, otherwise display Little C.
      setAIAssistant(isEven ? "jakita" : "littleC");
    }
  }, [id]);

  useEffect(() => {
    setLoaded(
      (fileState.length > 0 || ["Minecraft", "Micro:bit"].includes(language || "")) &&
        name !== undefined &&
        video !== undefined &&
        !!moduleOrProject
    );
  }, [fileState.length, moduleOrProject, name, video, language]);

  const handleFetchSb3File = async () => {
    setSb3Loading(true);
    setSb3Error(null);

    try {
      const file = await getScratchFileFromAWS(id || "", moduleOrProject || "");
      if (file instanceof ArrayBuffer) {
        setSb3File(file);
      } else if (file === undefined) {
        setSb3File(null);
      } else {
        throw new Error("Invalid file retrieved");
      }
    } catch (error) {
      console.error("Scratch error:", error);
      setSb3Error(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    } finally {
      setSb3Loading(false);
    }
  };

  //------------- Demo for how to send data to iframe-------------
  // Get iframe reference
  /* const scratch_iframe = document.getElementById('scratch-gui') as HTMLIFrameElement;
  //Retrieve correct iframe with .contentWindow, then use .postMessage as communication protocol
  const sendMessageToScratchGUI = (message: string) => {
    if (scratch_iframe && scratch_iframe.contentWindow){
      scratch_iframe.contentWindow.postMessage(message, 'http://localhost:8602'); // specify desination address
    }
  };

  //------------ Demo for how to receive data from iframe --------------------
  // Add an event listener
  window.addEventListener('message', function(event: any) {
        if (event.origin !== 'http://localhost:8602') return; // only accept messages from specified address
        console.log("Message from GUI: ", event.data);
  });
  */

  // For uploading file to  scratch editor
  const handleFileSelect = async (file: File) => {
    const arrayBuffer = await file.arrayBuffer();
    setSb3File(arrayBuffer);
  };

  const handleDownloadRequest = () => {
    setDownloadSignal(true);
  };

  const handleRestoreSprite = () => {
    setRestoreSpriteSignal(true);
  };

  const handleToggleTurboMode = () => {
    setToggleTurboModeSignal(true);
  };

  const handleSaveScratchFile = () => {
    setSaveSignal(true);
  };

  const handleChangeScratchTheme = () => {
    // Define the newTheme
    const newTheme = scratchTheme === "default" ? "dark" : "default";

    // Change the state
    setScratchTheme(newTheme);
  }

  /**
   * Function used on idle time out
   */
  const onIdleTimeout = async () => {
    await logoutFunction(navigate);
  }

  return (
    <EditorContext.Provider
      value={{ userFirstName, userLastName, zeroThreshold, pdf, pythonJSHeaderOffset }}
    >
      <InactivityTimer timeoutMs={idleTimeout} onTimeout={onIdleTimeout}/>
      <ActivityTimer type={moduleOrProject} itemId={id} intervalMs={10000} />
      {["Python", "JavaScript"].includes(language || "") ? (
        <div className={`flex-grow relative`}>
          <div className="h-screen overflow-hidden">
            <CodeEditorHeader
              name={name}
              video={video}
              AIAssistant={AIAssistant}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              videoExpanded={videoExpanded}
              setVideoExpanded={setVideoExpanded}
              hideVideo={activeItem === "assistant"}
              showAssistant={true}
              assistantPopupState={assistantPopupState}
              setAssistantPopupState={setAssistantPopupState}
              assistantPopupClickedButton={assistantPopupClickedButton}
              setAssistantPopupClickedButton={setAssistantPopupClickedButton}
              codeFiles={fileState}
            />
            {loaded && (
              <CodeEditor
                key={`${name}`}
                files={fileState}
                pdf={pdf}
                onFileChange={(x) => setFileState(x)}
                toShrink={videoExpanded}
                hide={activeItem === "assistant"}
              />
            )}
          </div>
        </div>
      ) : language === "Scratch" ? (
        <div className={`flex-grow relative`}>
          <div className="h-screen overflow-hidden">
            <ScratchEditorHeader
              name={name}
              video={video}
              AIAssistant={AIAssistant}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              videoExpanded={videoExpanded}
              setVideoExpanded={setVideoExpanded}
              hideVideo={activeItem === "assistant"}
              showAssistant={true}
              assistantPopupState={assistantPopupState}
              setAssistantPopupState={setAssistantPopupState}
              assistantPopupClickedButton={assistantPopupClickedButton}
              setAssistantPopupClickedButton={setAssistantPopupClickedButton}
              onFileSelect={handleFileSelect}
              requestDownloadFile={handleDownloadRequest}
              requestRestoreSprite={handleRestoreSprite}
              requestToggleTurboMode={handleToggleTurboMode}
              requestSaveFile={handleSaveScratchFile}
              requestChangeTheme={handleChangeScratchTheme}
              pdf={pdf}
              scratchIframe={scratchIframeRef}
              files={fileState}
              theme={scratchTheme}
            />
            {!sb3Loading && !sb3Error && (
              <div className={videoExpanded ? "" : "relative"}>
                <ScratchEditor
                  sb3File={sb3File}
                  videoExpanded={videoExpanded}
                  setIsResizingOutput={() => {}}
                  downloadSignal={downloadSignal}
                  setDownloadSignal={setDownloadSignal}
                  saveSignal={saveSignal}
                  setSaveSignal={setSaveSignal}
                  restoreSpriteSignal={restoreSpriteSignal}
                  setRestoreSpriteSignal={setRestoreSpriteSignal}
                  toggleTurboModeSignal={toggleTurboModeSignal}
                  setToggleTurboMode={setToggleTurboModeSignal}
                  iframeRef={scratchIframeRef}
                  onFileChange={(x) => setFileState(x)}
                  theme={scratchTheme}
                />
              </div>
            )}
          </div>
        </div>
      ) : ["Minecraft", "Micro:bit"].includes(language || "") ? (
        <div className={`flex-grow relative`}>
          <div className="h-screen overflow-hidden">
            <ExternalEditorHeader
              name={name}
              language={language}
              AIAssistant={AIAssistant}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              showAssistant={true}
              assistantPopupState={assistantPopupState}
              setAssistantPopupState={setAssistantPopupState}
              assistantPopupClickedButton={assistantPopupClickedButton}
              setAssistantPopupClickedButton={setAssistantPopupClickedButton}
              codeFiles={fileState}
            />
            {loaded && (
              <div className={videoExpanded ? "" : "relative"}>
                <ExternalEditor
                  key={`${name}`}
                  video={video}
                  videoTranscript={videoTranscript}
                  pdf={pdf}
                  toShrink={videoExpanded}
                  hide={activeItem === "assistant"}
                />
              </div>
            )}
          </div>
        </div>
      ) : <></>}
    </EditorContext.Provider>
  );
};

export default Editor;
