import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import ResponseCard from "components/main-view/lessons/pop-up-panels/quiz-pop-up/ResponseCard";
import { QuestionType, ResponseOption } from "utils/interfaces";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

interface AddQuestionProps {
  questionsList: QuestionType[];
  questionIndexToEdit?: number;
  setQuestionsList: React.Dispatch<React.SetStateAction<QuestionType[]>>;
  setPopUpState: React.Dispatch<
    React.SetStateAction<
      "main" | "create-new" | "add-question" | "edit-question"
    >
  >;
}

const AddQuestion: React.FC<AddQuestionProps> = ({
  questionsList,
  questionIndexToEdit,
  setQuestionsList,
  setPopUpState,
}) => {
  // States definition
  const [question, setQuestion] = useState("");
  const [responses, setResponses] = useState<ResponseOption[]>([]);
  const [activateCreateQuestionButtons, setActivateCreateQuestionButtons] =
    useState(false);
  const [isEditingQuestion, setIsEditingQuestion] = useState(false);

  /**
   * Function to prefill if there is a questionToEdit
   */
  useEffect(() => {
    if (questionIndexToEdit !== undefined) {
      // Get the question to edit
      const questionToEdit = questionsList[questionIndexToEdit];

      // Set the information
      setQuestion(questionToEdit.title);
      setResponses(questionToEdit.responses);
      // And set the control state for editing instead of creating
      setIsEditingQuestion(true);
    }
  }, []);

  /**
   * Function to activate the Add buttons
   */
  useEffect(() => {
    // Get the list of the "correct" fields
    const rightList = responses.map(
      (response: ResponseOption) => response.correct
    );
    // And check if the question text is not void and if at least one answer is right
    setActivateCreateQuestionButtons(
      !!question && rightList.some((element) => element)
    );
  }, [question, responses]);

  /**
   * Add a new response item with default parameters
   */
  const handleAddResponse = () => {
    setResponses([...responses, { text: "", correct: false }]);
  };

  /**
   * Action for adding a question
   */
  const addQuestion = () => {
    setQuestionsList((currentList) => [
      ...currentList,
      { title: question, responses: responses },
    ]);
  };

  /**
   * Action for editing a question
   */
  const editQuestion = () => {
    if (questionIndexToEdit !== undefined) {
      setQuestionsList((currentList) => [
        ...currentList.slice(0, questionIndexToEdit),
        { title: question, responses: responses },
        ...currentList.slice(questionIndexToEdit + 1),
      ]);
    }
  };

  /**
   * Add question button handler
   */
  const handleAddQuestion = () => {
    // If the button is not activated, then do nothing
    if (!activateCreateQuestionButtons) return;

    // Add this question to the current list of questions
    if (isEditingQuestion) editQuestion();
    else addQuestion();

    // And come back to the previous view
    setPopUpState("create-new");
  };

  /**
   * Add question and create another button handler
   */
  const handleAddQuestionAndCreateAnother = () => {
    // If the button is not activated, then do nothing
    if (!activateCreateQuestionButtons) return;

    // Add this question to the current list of questions
    if (isEditingQuestion) editQuestion();
    else addQuestion();

    // And clean the states
    setQuestion("");
    setResponses([]);
  };

  /**
   * Function applied for re-ordering the questions visually 
   * on screen after dragging
   * @param result 
   * @returns 
   */
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(responses);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setResponses(items);
  };

  return (
    <div className="w-[414px] min-w-[414px] h-full max-h-[70vh] flex-col justify-start items-start gap-8 inline-flex overflow-y-auto pop-up-scrollbar">
      <div className="self-stretch h-full flex-col justify-start items-start gap-4 flex">
        <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
          <div className="rounded justify-start items-start gap-2 inline-flex">
            <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
              Question*
            </div>
          </div>
          <div className="self-stretch grow shrink basis-0 bg-neutral-50 rounded-lg flex-col justify-start items-start flex">
            <div className="w-full h-[151px] justify-start items-start gap-2 inline-flex">
              <textarea
                value={question}
                onChange={(event) => setQuestion(event.target.value)}
                className="p-3 grow basis-0 self-stretch bg-neutral-50 rounded-lg text-neutral-700 text-base font-normal font-sans leading-[19px] resize-none placeholder:text-neutral-300 focus:outline-none custom-scroll"
                placeholder="Enter question"
              />
            </div>
          </div>
        </div>
        <div className="self-stretch h-full flex-col justify-start items-start gap-4 flex">
          <div className="self-stretch text-neutral-500 text-base font-semibold font-sans leading-[19px]">
            Responses
          </div>
          {responses.length > 0 && (
            <div className="w-full">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="responses">
                  {(provided: any) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="flex flex-col justify-start items-stretch gap-4 w-full"
                    >
                      {responses.map(
                        (response: ResponseOption, index: number) => (
                          <Draggable
                            key={index}
                            draggableId={`response-${index}`}
                            index={index}
                          >
                            {(provided: any) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="full-width-draggable"
                              >
                                <ResponseCard
                                  index={index}
                                  text={response.text}
                                  correct={response.correct}
                                  responses={responses}
                                  setResponses={setResponses}
                                />
                              </div>
                            )}
                          </Draggable>
                        )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
          <div className="self-stretch h-12 flex-col justify-start items-start gap-8 flex">
            <div
              className="self-stretch px-2 py-3 rounded-lg border border-dashed border-neutral-300 hover:bg-neutral-50 ease-in-out duration-100 justify-center items-center gap-2 inline-flex cursor-pointer"
              onClick={handleAddResponse}
            >
              <div className="w-6 h-6 relative">
                <MdAdd className="w-full h-full fill-neutral-500" />
              </div>
              <div className="text-neutral-500 text-base font-normal font-sans leading-[19px] select-none">
                Add a response
              </div>
            </div>
          </div>
        </div>
        {!isEditingQuestion && (
          <div className="self-stretch h-fit flex-col justify-start items-start gap-4 inline-flex">
            <div
              className={
                activateCreateQuestionButtons
                  ? "self-stretch h-[46px] px-5 py-3 rounded border border-dc-secondary-600 hover:bg-neutral-50 ease-in-out duration-100 justify-center items-center gap-2 inline-flex cursor-pointer"
                  : "self-stretch h-[46px] px-5 py-3 rounded border border-Subtle justify-center items-center gap-2 inline-flex cursor-not-allowed"
              }
              onClick={handleAddQuestion}
            >
              <div
                className={
                  activateCreateQuestionButtons
                    ? "text-dc-secondary-600 text-lg font-semibold font-sans leading-snug select-none"
                    : "text-Subtle text-lg font-semibold font-sans leading-snug select-none"
                }
              >
                Add question
              </div>
            </div>
            <div
              className={
                activateCreateQuestionButtons
                  ? "self-stretch h-[46px] px-5 py-3 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-100 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
                  : "self-stretch h-[46px] px-5 py-3 bg-Subtle rounded justify-center items-center gap-2 inline-flex cursor-not-allowed"
              }
              onClick={handleAddQuestionAndCreateAnother}
            >
              <div
                className={
                  activateCreateQuestionButtons
                    ? "text-white text-lg font-semibold font-sans leading-snug select-none"
                    : "text-neutral-400 text-lg font-semibold font-sans leading-snug select-none"
                }
              >
                Add and create another
              </div>
            </div>
          </div>
        )}
        {isEditingQuestion && (
          <div className="self-stretch h-fit flex-col justify-start items-start gap-4 inline-flex">
            <div
              className={
                activateCreateQuestionButtons
                  ? "self-stretch h-[46px] px-5 py-3 bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-100 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
                  : "self-stretch h-[46px] px-5 py-3 bg-Subtle rounded justify-center items-center gap-2 inline-flex cursor-not-allowed"
              }
              onClick={handleAddQuestion}
            >
              <div
                className={
                  activateCreateQuestionButtons
                    ? "text-white text-lg font-semibold font-sans leading-snug select-none"
                    : "text-neutral-400 text-lg font-semibold font-sans leading-snug select-none"
                }
              >
                Edit question
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddQuestion;
