import { AnimatePresence, motion } from "framer-motion";
import { MdOutlineCloudDone, MdOutlineCloudSync } from "react-icons/md";

// Definition of the container variants
const containerVariants = {
  saved: { opacity: 1 },
  saving: {
    opacity: [0, 0.5, 1, 1, 1, 0.5, 0],
    transition: {
      duration: 1,
      repeat: Infinity,
      repeatDelay: 0.3,
    },
  },
  success: { opacity: 1 },
  empty: { opacity: 1 },
};

const iconVariants = {
  saved: { opacity: 1 },
  empty: { opacity: 0 },
  saving: { opacity: 1 },
  success: { opacity: 1 },
  enter: { opacity: 1 },
  leave: {
    opacity: 0,
    transition: { duration: 0.3 },
  },
};

const textVariants = {
  saved: {
    width: 0,
    marginLeft: 0,
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
  empty: {
    width: 0,
    marginLeft: 0,
  },
  saving: {
    width: "fit-content",
    marginLeft: 6,
    opacity: 1,
  },
  success: {
    width: "fit-content",
    marginLeft: 6,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
  enter: {
    width: 0,
    marginLeft: 0,
    opacity: 0,
  },
  leave: {
    width: 0,
    marginLeft: 0,
    opacity: 0,
    transition: { duration: 0.3 },
  },
};

interface AutoSaveIconProps {
  state: "saved" | "saving" | "success" | "empty";
}

const AutoSaveIcon: React.FC<AutoSaveIconProps> = ({ state }) => {
  return (
    <motion.div
      initial="saved"
      className="w-fit h-fit inline-flex justify-end items-center overflow-hidden"
      animate={state}
      variants={containerVariants}
    >
      <AnimatePresence mode="wait">
        {["saved", "success"].includes(state) && (
          <motion.div
            initial="enter"
            animate={state}
            variants={iconVariants} 
            className="w-5 h-5 relative"
          >
            <MdOutlineCloudDone className="w-full h-full fill-neutral-300" />
          </motion.div>
        )}
        {state === "saving" && (
          <motion.div
            key={state}
            initial="enter"
            animate="saving"
            exit="leave"
            variants={iconVariants}
            className="w-5 h-5 relative"
          >
            <MdOutlineCloudSync className="w-full h-full fill-neutral-300" />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {["saving", "success"].includes(state) && (
          <motion.div
            key={state}
            initial="enter"
            exit="leave"
            animate="saving"
            variants={textVariants}
            className="text-neutral-300 text-sm font-normal font-sans leading-none select-none whitespace-nowrap"
          >
            {state === "saving" ? "Saving..." : "Saved online"}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default AutoSaveIcon;
