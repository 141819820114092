/**
 * quizRequests.ts
 * -----------------
 * This file contains the requests needed to perform
 * quiz creation, get quizzes, among others.
 */
import { response } from "express";
import { BACKEND_URL } from "config/getUrl";
import {
  QuizListType,
  QuizQuestionOptionType,
  QuizQuestionType,
  QuizType,
} from "utils/interfaces";
import { createRecordRequest, updateRecordRequest } from "./apiRequests";

const API_URL = BACKEND_URL;

const shuffleArray = (array: QuizQuestionType[] | QuizQuestionOptionType[]) => {
  let shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export const getQuizByIdRequest = async (
  quizId: string,
  setState: React.Dispatch<React.SetStateAction<QuizType>>,
  fields: string[] = []
) => {
  // Trasform fields into a string
  const fieldsString = fields.join(" ");

  // Get the program
  let query = new URLSearchParams({ fields: fieldsString });
  const response = await fetch(`${API_URL}/api/quizzes/${quizId}?${query}`);

  if (response.status === 200) {
    // Get the response
    let data = await response.json();

    // Randomize the order of questions if required
    if (data.content.randomizeQuestionOrder === "true") {
      data.content.questions = shuffleArray(data.content.questions);
    }

    // Randomize the order of question options if required
    if (data.content.randomizeOptionsOrder === "true") {
      for (let question of data.content.questions) {
        if (question.options) {
          question.options = shuffleArray(question.options);
        }
      }
    }

    // Write the (potentially) processed data to the QuizPage state
    setState(data.content);
  }
};

/**
 * Function used to create and update quizzes in the context of
 * creating/editing a course. This creates the quizzes if they
 * are being created inside a "Create New Course", and update
 * which quiz is active inside "Editing Course".
 * @param courseId _id of the course that contains these quizzes.
 * @param quizzes List of quizzes to be created/updated.
 * @param baseSelectedQuizzes In the "Editing course" context, the quizzes that were selected originally.
 */
export const createUpdateQuizzesInCourses = async (
  courseId: string,
  quizzes: QuizListType[],
  baseSelectedQuizzes: { [key: string]: boolean }
) => {
  // Get all the new quizzes to be created and send them to be created
  const newQuizPromises = quizzes
    .filter((quiz: QuizListType) => quiz.tempId)
    .map(async (quiz: QuizListType) => {
      // Get the quiz data
      const { _id, ...quizData } = quiz;

      // Add the course _id to the quiz
      quizData.course = courseId;

      // Send the request to be created
      const quizResponse = await createRecordRequest(quizData, "/api/quizzes");

      // If the response is successful, then show the pop up message
      if (!quizResponse.successful) {
        alert(quizResponse.message);
      }
    });

  // And for the currently created ones, check if the activate has changed
  const updateQuizPromises = quizzes
    .filter((quiz: QuizListType) => quiz._id)
    .map(async (quiz: QuizListType) => {
      // Compare if the activate field has changed
      if (quiz.active !== baseSelectedQuizzes[quiz._id]) {
        // Then update the quiz activate status
        const quizResponse = await updateRecordRequest(
          { _id: quiz._id, toUpdate: { active: quiz.active } },
          "/api/quizzes"
        );

        // If the response is successful, then show the pop up message
        if (!quizResponse.successful) {
          alert(quizResponse.message);
        }
      }
    });

  // Wait for all promises to resolve
  await Promise.all([...newQuizPromises, ...updateQuizPromises]);
};
