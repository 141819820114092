import { useState, useEffect } from "react";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  BarChart,
} from "recharts";
import ChartTooltip from "./ChartTooltip";
import { getCollectionRequest } from "services/apiRequests";

interface MyProgressProps {
  userFirstName: string;
}

const MyProgress: React.FC<MyProgressProps> = ({ userFirstName }) => {
  // States definition
  const [chartData, setChartData] = useState<
    { language: string; user: number; class: number }[]
  >([]);
  const [yAxisWidth, setYAxisWidth] = useState(0);

  /**
   * Get the data every time it's rendered
   */
  useEffect(() => {
    getData();
  }, []);

  /**
   * Function to define the max width of YAxis labels
   */
  useEffect(() => {
    // Find the longest Y-axis label width
    const labels = document.querySelectorAll(
      ".recharts-yAxis .recharts-cartesian-axis-tick-value tspan"
    );

    if (labels) {
      let maxWidth = 0;

      labels.forEach((label: any) => {
        const labelWidth = label.getBBox().width;
        if (labelWidth > maxWidth) {
          maxWidth = labelWidth;
        }
      });

      // Add some padding to the calculated width
      setYAxisWidth(maxWidth + 10);
    }
  }, [chartData]);

  /**
   * Function used to request the progress values
   */
  const getData = async () => {
    // Get the knowledge points
    const response = await getCollectionRequest("/api/users/my-progress");

    // Check if the response is successful
    if (!response.successful) {
      return;
    }

    setChartData(response.content);

    return response;
  };

  /**
   * Function used to format the number on the YAxis and
   * prevent to display long decimal number
   * @param tick 
   * @returns 
   */
  const formatYAxis = (tick: number) => {
    return Number.isInteger(tick) ? tick.toString() : tick.toFixed(1);
  }

  return (
    <div className="w-1/3 h-[555px] p-6 bg-neutral-25 rounded-3xl flex flex-col justify-start items-start gap-8 overflow-hidden relative">
      <div className="self-stretch justify-between items-center inline-flex">
        <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
          <div className="self-stretch text-neutral-700 text-lg font-normal font-sans leading-snug select-none">
            My Progress
          </div>
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          barGap={2}
        >
          <XAxis
            dataKey="language"
            axisLine={false}
            tickLine={false}
            fontSize={10}
            className="select-none"
          />
          <YAxis
            width={yAxisWidth}
            axisLine={false}
            tickLine={false}
            fontSize={12}
            allowDecimals={false}
            domain={[0, "dataMax"]}
            tickFormatter={formatYAxis}
            className="select-none"
          />
          <Tooltip content={<ChartTooltip userName={userFirstName} />} />
          <Bar dataKey="user" fill="#16a7b6" radius={5} />
          <Bar dataKey="class" fill="#596876" radius={5} />
        </BarChart>
      </ResponsiveContainer>
      {/* <Example /> */}
      <div className="flex-col justify-start items-start gap-2 flex">
        <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide select-none">
          Legend
        </div>
        <div className="justify-start items-center gap-2 inline-flex">
          <div className="w-6 h-4 relative bg-dc-accent-500 rounded" />
          <div className="text-neutral-500 text-[10px] font-normal font-sans leading-3 tracking-tight select-none">
            Your Progress
          </div>
        </div>
        <div className="justify-start items-center gap-2 inline-flex">
          <div className="w-6 h-4 relative bg-neutral-500 rounded" />
          <div className="text-neutral-500 text-[10px] font-normal font-sans leading-3 tracking-tight select-none">
            Class Average
          </div>
        </div>
        <div className="text-neutral-500 text-[10px] font-normal font-sans leading-3 tracking-tight select-none">
          Number of Projects completed
        </div>
      </div>
    </div>
  );
};

export default MyProgress;
