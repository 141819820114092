import { useContext, useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import {
  Course,
  Module,
  Program,
  Project,
  QuestionType,
  QuizListType,
} from "utils/interfaces";
import AddQuestion from "./AddQuestion";
import QuestionCard from "./QuestionCard";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { updateRecordRequest } from "services/apiRequests";
import { formatUpdateQuiz } from "utils/formatUpdate";
import InputBadgeImage from "components/main-view/utils/placeholders/InputBadgeImage";
import { LessonsContext } from "components/main-view/utils/Contexts";

interface CreateNewQuizProps {
  quizzes: QuizListType[];
  selectedLessonData?: Program | Course | Project | Module | null;
  selectedQuizToEdit?: QuizListType | null;
  popUpState: "main" | "create-new" | "add-question" | "edit-question";
  setPopUpState: React.Dispatch<
    React.SetStateAction<
      "main" | "create-new" | "add-question" | "edit-question"
    >
  >;
  setQuizzes: React.Dispatch<React.SetStateAction<QuizListType[]>>;
  afterCreateEditFunction: () => void;
  handlePressBack: () => void;
}

const CreateNewQuiz: React.FC<CreateNewQuizProps> = ({
  quizzes,
  selectedLessonData,
  selectedQuizToEdit,
  popUpState,
  setPopUpState,
  setQuizzes,
  afterCreateEditFunction,
  handlePressBack,
}) => {
  // States definition
  const [quizName, setQuizName] = useState("");
  const [description, setDescription] = useState("");
  const [questionsList, setQuestionsList] = useState<QuestionType[]>([]);
  const [activateCreateQuizButton, setActivateCreateQuizButton] =
    useState(false);
  const [questionIndexToEdit, setQuestionIndexToEdit] = useState(NaN);
  const [badgeImage, setBadgeImage] = useState<string | null>(null);

  // Get the context
  const { setVersion } = useContext(LessonsContext);

  /**
   * If there is selected data, then prefill the defined fields
   */
  useEffect(() => {
    if (selectedQuizToEdit) {
      setQuizName(selectedQuizToEdit.title);
      setDescription(selectedQuizToEdit.description);
      setQuestionsList(selectedQuizToEdit.questions);
      setBadgeImage(
        selectedQuizToEdit.badgeImage
          ? selectedQuizToEdit.badgeImage
          : `/imgs/quizzes/${selectedQuizToEdit._id}/badgeImage`
      );
    }
  }, []);

  /**
   * Function to check when the required fields are filled out
   * in order to activate the quiz button
   */
  useEffect(() => {
    // Activate when the quizName is filled and we have at least one question
    setActivateCreateQuizButton(!!quizName && questionsList.length > 0);
  }, [quizName, questionsList]);

  /**
   * Function for creating/edit a quiz button
   */
  const handleSaveQuiz = async () => {
    // Check if the button is already available
    if (!activateCreateQuizButton) return;

    // Get the data
    const [data, formattedData] = provideData();

    // Request for editing a quiz
    if (selectedQuizToEdit) {
      // If it's a created quiz (in the database), then update it
      if (selectedQuizToEdit._id) {
        const response = await updateRecordRequest(
          { _id: selectedQuizToEdit._id, toUpdate: formattedData },
          "/api/quizzes"
        );

        // Check if the response is successful
        if (!response.successful) {
          alert(response.message);
          return;
        }

        // Create an updated quizzes list
        const updatedQuizzes = quizzes.map((quiz: QuizListType) => {
          // For the quiz of interest to be edited, apply the update
          if (quiz._id === selectedQuizToEdit._id) {
            return data;
          }
          // Otherwise just return the original quiz
          return quiz;
        });

        // And set the updated quizzes
        setQuizzes(updatedQuizzes as QuizListType[]);
      }
      // Otherwise, if it's not created in the database, just update it in the current list
      else {
        // Update the one with the specified tempId
        const updatedQuizzes = quizzes.map((quiz: QuizListType) => {
          if (quiz.tempId === selectedQuizToEdit.tempId) {
            return { ...data, tempId: quiz.tempId };
          }
          return quiz;
        });

        // And set the quizzes
        setQuizzes(updatedQuizzes as QuizListType[]);
      }
    } else {
      // Define a temporary id
      const tempId = quizzes.filter((quiz: QuizListType) => quiz.tempId).length;
      // And add it to the current data
      const newQuiz = { ...data, tempId: tempId.toString() };

      // Given that there is no course to be associated with,
      // let's just add this to the quizzes state
      setQuizzes([...quizzes, newQuiz as QuizListType]);
    }

    // Update the version to update the images
    setVersion((currentVersion: number) => currentVersion + 1);

    // Execute the defined actions after the creation
    afterCreateEditFunction();
  };

  /**
   * Function that returns all the form data
   * @returns data
   */
  const provideData = () => {
    // Return all the gathered data in the form
    const dataToProvide = {
      _id:
        selectedQuizToEdit && selectedQuizToEdit._id
          ? selectedQuizToEdit._id
          : "",
      title: quizName,
      description: description,
      questions: questionsList,
      badgeImage: badgeImage,
      course:
        selectedQuizToEdit && selectedQuizToEdit.course
          ? selectedQuizToEdit.course
          : selectedLessonData
          ? selectedLessonData._id
          : undefined,
      active:
        selectedQuizToEdit && selectedQuizToEdit.active
          ? selectedQuizToEdit.active
          : false,
      tempId:
        selectedQuizToEdit && selectedQuizToEdit.tempId
          ? selectedQuizToEdit.tempId
          : undefined,
    };

    return [dataToProvide, formatUpdateQuiz(selectedQuizToEdit, dataToProvide)];
  };

  /**
   * Function to delete a question when pressed delete button
   */
  const deleteQuestionAction = (index: number) => {
    // And delete that specific index
    setQuestionsList([
      ...questionsList.slice(0, index),
      ...questionsList.slice(index + 1),
    ]);
  };

  /**
   * Function to edit a question when pressed on the card
   */
  const editQuestionAction = (index: number) => {
    // Set the question to Edit
    setQuestionIndexToEdit(index);
    // And set the pop up state
    setPopUpState("edit-question");
  };

  /**
   * For re-ordering the questions visually on screen
   * @param result
   * @returns
   */
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(questionsList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setQuestionsList(items);
  };

  return (
    <>
      {popUpState === "create-new" && (
        <div className="max-w-[90vw] w-[1026px] h-full bg-white rounded-3xl flex-col justify-start items-start gap-8 inline-flex">
          <div className="max-h-[50vh] self-stretch justify-start items-start gap-8 inline-flex overflow-y-auto custom-scroll">
            <div className="flex-1 h-fit flex-col justify-start items-start gap-4 flex">
              <div className="self-stretch text-neutral-500 text-base font-semibold font-sans leading-[19px] select-none">
                Basic details
              </div>
              <InputBadgeImage
                text="Upload a badge image"
                badgeImage={badgeImage}
                setBadgeImage={setBadgeImage}
                id="quiz-image-upload"
              />
              <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
                <div className="rounded justify-start items-start gap-2 inline-flex">
                  <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide select-none">
                    Quiz Name*
                  </div>
                </div>
                <div className="self-stretch bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
                  <input
                    type="text"
                    value={quizName}
                    onChange={(event) => setQuizName(event.target.value)}
                    className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                    placeholder="Enter name of quiz"
                  />
                </div>
              </div>
              <div className="self-stretch h-[171px] flex-col justify-start items-start gap-1.5 flex">
                <div className="rounded justify-start items-start gap-2 inline-flex">
                  <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide select-none">
                    Quiz Description (Optional)
                  </div>
                </div>
                <div className="self-stretch grow shrink basis-0 bg-neutral-50 rounded-lg flex-col justify-start items-start flex">
                  <div className="w-full h-[151px] justify-start items-start gap-2 inline-flex">
                    <textarea
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                      className="p-3 grow basis-0 self-stretch bg-neutral-50 rounded-lg text-neutral-700 text-base font-normal font-sans leading-[19px] resize-none placeholder:text-neutral-300 focus:outline-none custom-scroll"
                      placeholder="Provide a brief description about this quiz"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch justify-start items-start gap-2 flex">
              <div className="w-px self-stretch bg-neutral-200" />
            </div>
            <div className="flex-1 h-fit flex-col justify-start items-start gap-8 inline-flex overflow-y-auto pop-up-scrollbar relative">
              <div className="self-stretch h-fit flex-col justify-start items-start gap-4 flex">
                <div className="self-stretch text-neutral-500 text-base font-semibold font-sans leading-[19px] select-none">
                  Questions
                </div>
                {questionsList.length > 0 && (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="questions">
                      {(provided: any) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="w-full space-y-4"
                        >
                          {questionsList.map(
                            (question: QuestionType, index: number) => (
                              <Draggable
                                key={index}
                                draggableId={`question-${index}`}
                                index={index}
                              >
                                {(provided: any) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="full-width-draggable"
                                  >
                                    <QuestionCard
                                      index={index}
                                      question={question.title}
                                      deleteQuestionAction={
                                        deleteQuestionAction
                                      }
                                      editQuestionAction={editQuestionAction}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
                <div
                  className="self-stretch px-2 py-3 rounded-lg border border-dashed border-neutral-300 hover:bg-neutral-50 ease-in-out duration-100 justify-center items-center gap-2 inline-flex cursor-pointer"
                  onClick={() => setPopUpState("add-question")}
                >
                  <div className="w-6 h-6 relative">
                    <MdAdd className="w-full h-full fill-neutral-500" />
                  </div>
                  <div className="text-neutral-500 text-base font-normal font-sans leading-[19px] select-none">
                    Add a question
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch justify-end items-start gap-4 inline-flex">
            {!selectedQuizToEdit && (
              <div className="w-[330px] h-fit px-5 py-3 rounded justify-center items-center gap-2 flex">
                <div
                  className="text-dc-secondary-600 text-lg font-semibold font-sans leading-snug cursor-pointer hover:underline"
                  onClick={handlePressBack}
                >
                  Discard Changes
                </div>
              </div>
            )}
            <div
              className={`${
                !!selectedQuizToEdit ? "w-full" : "w-[330px]"
              } h-fit px-5 py-3 rounded justify-center items-center gap-2 inline-flex ${
                activateCreateQuizButton
                  ? "bg-dc-secondary-600 hover:bg-dc-secondary-700 ease-in-out duration-150 cursor-pointer"
                  : "bg-Subtle cursor-not-allowed"
              } `}
              onClick={handleSaveQuiz}
            >
              <div
                className={
                  activateCreateQuizButton
                    ? "text-white text-lg font-semibold font-sans leading-snug select-none"
                    : "text-neutral-400 text-lg font-semibold font-sans leading-snug select-none"
                }
              >
                Save Changes
              </div>
            </div>
          </div>
        </div>
      )}
      {popUpState === "add-question" && (
        <AddQuestion
          questionsList={questionsList}
          setQuestionsList={setQuestionsList}
          setPopUpState={setPopUpState}
        />
      )}
      {popUpState === "edit-question" && (
        <AddQuestion
          questionIndexToEdit={questionIndexToEdit}
          questionsList={questionsList}
          setQuestionsList={setQuestionsList}
          setPopUpState={setPopUpState}
        />
      )}
    </>
  );
};

export default CreateNewQuiz;
