import { landingBlurb } from "config/blurb";
import { platform } from "config/platform";
import { Link } from "react-router-dom";

interface MainWindowProps {
  children?: React.ReactNode;
}

const MainWindow: React.FC<MainWindowProps> = ({ children }) => {
  return (
    <div className="w-full h-full min-h-screen inline-flex items-center justify-between p-14 z-10 overflow-auto">
      <div className="w-fit" />
      <div className="min-w-fit w-full h-full max-w-[1276px] p-[128px] bg-neutral-50 bg-opacity-70 rounded-2xl shadow backdrop-blur-[32px] justify-start items-center gap-24 inline-flex ">
        <div className="min-w-[200px] max-w-[414px] w-full self-stretch flex-col justify-start items-start gap-10 inline-flex">
          <Link
            className={`w-fit justify-start items-center inline-flex`}
            to="/"
          >
            <img className={`${platform === "codeflix" ? "h-[72px]" : "h-[101px]"} select-none`} src={"/logo.svg"} />
          </Link>
          <div className="self-stretch text-neutral-700 text-base font-normal font-sans leading-7">
            {landingBlurb}
          </div>
        </div>
        <div className="w-px self-stretch justify-start items-start flex">
          <div className="w-px self-stretch bg-white" />
        </div>
        <div className="min-w-[300px] max-w-[414px] w-full self-stretch flex-col justify-between items-center inline-flex">
          {children}
        </div>
      </div>
      <div className="w-fit" />
    </div>
  );
};

export default MainWindow;
