import {
  MdArrowBack,
  MdArrowDropDown,
  MdOutlineForum,
  MdOutlineHowToReg,
} from "react-icons/md";
import AssistantButton from "./AssistantButton";
import { motion } from "framer-motion";
import {
  AssistantAllButtonTypes,
  AssistantButtonKeys,
  AssistantButtonType,
  languageType,
} from "utils/interfaces";

const buttonOptions: AssistantButtonType = {
  "I want some feedback": "I want some feedback",
  "I'm stuck, Can you help me?": "I'm stuck, can you help me?",
  "I don't understand the logic": "I don't understand the logic",
  // "How can I run the code?": "How can I run the code?",
  "I can see an error": "I can see an error",
};

const externalEditorButtonOptions = [
  "I don't understand this lesson",
  "What coding mistakes should I look out for?",
  "How can variables, loops and functions help with this lesson?",
  "What should I do if my code doesn't work?",
];

interface AssistantPopupContentsProps {
  state: "closed" | "popup" | "popupNoCode" | "expanded" | "fullscreen";
  setState: React.Dispatch<
    React.SetStateAction<
      "closed" | "popup" | "popupNoCode" | "expanded" | "fullscreen"
    >
  >;
  setClickedButton:
    | React.Dispatch<React.SetStateAction<AssistantAllButtonTypes>>
    | ((id: AssistantAllButtonTypes) => void);
  hasCode: boolean;
  assistant: "littleC" | "jakita" | "cody";
  language: languageType | null;
}

const AssistantPopupContents: React.FC<AssistantPopupContentsProps> = ({
  state,
  setState,
  setClickedButton,
  hasCode,
  assistant,
  language,
}) => {
  const handleButtonClick = (id: AssistantAllButtonTypes) => {
    setClickedButton(id);
    setState("fullscreen");
  };

  const toggleExpandedPopup = () => {
    if (state === "popup") setState("expanded");
    else if (state === "expanded") setState("popup");
  };

  return (
    <div className={`max-w-[299px] h-fit inline-flex flex-col ${["Micro:bit", "Minecraft"].includes(language || "") ? "gap-3" : "gap-6"}`}>
      <div className="self-stretch inline-flex flex-col gap-2.5">
        <div className="flex items-center gap-3">
          <div
            className="h-9 p-2 bg-neutral-50 hover:bg-neutral-100 ease-in-out duration-150 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 inline-flex cursor-pointer"
            onClick={() => setState("closed")}
          >
            <div className="w-5 h-5 relative">
              <MdArrowBack className="w-full h-full" />
            </div>
          </div>
          <div className="text-neutral-800 text-base font-semibold font-['Poppins'] leading-[19px] select-none">
            {assistant === "cody" ? "Hi there!" : "Jingeri!"}
          </div>
        </div>
        <div className="self-stretch h-fit text-neutral-800 text-sm font-normal font-['Poppins'] leading-relaxed select-none">
          {assistant === "littleC" ? (
            <>
              I'm Little C and I'm a Yugumbeh boy.
              <br />
              Jingeri is how we say welcome on my
              <br />
              country. I'm here to help you become a<br />
              Deadly Coder!
            </>
          ) : assistant === "jakita" ? (
            <>
              I'm Big J and I'm a Yugumbeh girl.
              <br />
              Jingeri is how we say welcome on my
              <br />
              country. I'm here to help you become a<br />
              Deadly Coder!
            </>
          ) : (
            <>
              I'm Cody, your friendly AI coding buddy. Whether you're just
              starting out or you're knee-deep in code, I'm here to help you
              navigate the exciting world of programming.
            </>
          )}
        </div>
        {/* Chat with be dropdown */}
        {/* Check code for completion */}
        {hasCode && (
          <>
            <div className="w-full text-neutral-800 text-sm font-normal font-['Poppins'] leading-relaxed select-none">
              Pick an option below to get started
            </div>
            {!["Micro:bit", "Minecraft"].includes(language || "") && (
              <>
                <div
                  className={`w-[301px] h-[39px] py-2.5 rounded text-dc-secondary-600 border border-dc-secondary-600 hover:bg-neutral-50 ease-in-out duration-150 justify-center items-center gap-2 inline-flex cursor-pointer`}
                  onClick={() => {}}
                >
                  <MdOutlineHowToReg className="relative w-4 h-4" />
                  <div
                    className="whitespace-nowrap text-base font-semibold font-['Poppins'] leading-[19px]"
                    onClick={() => handleButtonClick("Please check my code")}
                  >
                    Check my code for completion
                  </div>
                </div>

                {/* Chat with be dropdown */}
                <div
                  className={`w-[301px] h-[39px] py-2.5 rounded border border-dc-secondary-600 hover:bg-neutral-50 ease-in-out duration-150 justify-center items-center gap-2 inline-flex cursor-pointer`}
                  onClick={toggleExpandedPopup}
                >
                  <MdOutlineForum className="relative text-dc-secondary-600 w-4 h-4" />
                  <div className="whitespace-nowrap text-dc-secondary-600 text-base font-semibold font-['Poppins'] leading-[19px]">
                    Chat with me
                  </div>
                  <motion.div
                    className="w-4 h-4 relative"
                    initial={{ rotate: 0 }}
                    animate={{ rotate: state === "expanded" ? -180 : 0 }}
                  >
                    <MdArrowDropDown className="text-dc-secondary-600 w-full h-full" />
                  </motion.div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      {/* Buttons visibile in expanded mode */}
      {state === "expanded" && (
        <div className="self-stretch inline-flex flex-col gap-1.5">
          {!["Micro:bit", "Minecraft"].includes(language || "") && (
            <div className="self-stretch text-neutral-800 text-xs font-normal font-['Poppins'] uppercase leading-[14px] tracking-wide">
              Tell me how I can help?
            </div>
          )}
          <div className="self-stretch inline-flex flex-col gap-2.5">
            {!["Micro:bit", "Minecraft"].includes(language || "")
              ? (Object.keys(buttonOptions) as AssistantButtonKeys[]).map(
                  (key, index) => (
                    <AssistantButton
                      key={index}
                      text={buttonOptions[key]}
                      onClick={() => handleButtonClick(key)}
                    />
                  )
                )
              : externalEditorButtonOptions.map((option, index) => (
                  <AssistantButton
                    key={index}
                    text={option}
                    onClick={() => handleButtonClick(option)}
                  />
                ))}
          </div>
          <div className="self-stretch text-neutral-800 text-sm font-normal font-['Poppins'] leading-relaxed">
            <span className="select-none">I'd rather just </span>
            <span
              className="text-dc-secondary-600 hover:text-dc-secondary-700 ease-in-out duration-150 text-sm font-semibold font-['Poppins'] underline leading-[17px] select-none cursor-pointer"
              onClick={() => handleButtonClick("chat")}
            >
              start a conversation
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssistantPopupContents;
