import CreateNewPopUpClasses from "components/main-view/classes/utils/CreateNewPopUp";
import CreateNewPopUpCustomers from "components/main-view/customers/utils/CreateNewPopUp";
import {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import {
  MdAdd,
  MdClose,
  MdCorporateFare,
  MdOutlineExpandMore,
  MdOutlinePerson,
  MdOutlineSave,
  MdSystemUpdateAlt,
} from "react-icons/md";
import { motion } from "framer-motion";
import { getReports } from "services/classesRequests";
import GenerateReportPopup from "../pop-up-panels/generate-reports-pop-up/GenerateReportPopup";

interface DashboardHeaderOptionsProps {
  ref: any;
  userRole: string;
  userFirstName: string;
  userLastName: string;
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  setShowSideBarMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<ReactElement | string>>;
  setShowBackButton: React.Dispatch<React.SetStateAction<boolean>>;
  dashboardRef: React.MutableRefObject<any>;
  customersRef: React.MutableRefObject<any>;
  subHeaderRef: React.MutableRefObject<any>;
}

const DashboardHeaderOptions: React.FC<DashboardHeaderOptionsProps> =
  forwardRef(
    (
      {
        userRole,
        userFirstName,
        userLastName,
        setActiveItem,
        setShowSideBarMenu,
        setTitle,
        setDescription,
        setShowBackButton,
        dashboardRef,
        customersRef,
        subHeaderRef,
      },
      ref
    ) => {
      // States definition
      const [showCreateNewPopUp, setShowCreateNewPopUp] = useState(false);
      const [showGenerateReportsPopUp, setShowGenerateReportsPopUp] =
        useState(false);
      const [showCreateNew, setShowCreateNew] = useState(true);
      const [showGenerateReportsButton, setShowGenerateReportsButton] = useState(false);
      const [saveButton, setSaveButton] = useState<{
        show: boolean;
        disabled: boolean;
      }>({ show: false, disabled: true });
      // State for controlling where to redirect on customer admin school logo pressing
      const [activeItemOnBack, setActiveItemOnBack] = useState("");
      // State for controlling what create pop up I should display on master admin dashboard
      const [createTypeMasterAdmin, setCreateTypeMasterAdmin] = useState<
        "customers" | "classes"
      >("customers");

      /**
       * Define the list of functions that can be used from external components
       * using a reference
       */
      useImperativeHandle(ref, () => ({
        setShowCreateNewButton(bool: boolean) {
          setShowCreateNew(bool);
        },
        setShowGenerateReportsButton(bool: boolean) {
          setShowGenerateReportsButton(bool);
        },
        setSaveButton(options: { show: boolean; disabled: boolean }) {
          setSaveButton(options);
        },
        pressCreateNewSchool() {
          onCreateEditSchool();
        },
        pressCreateNewClass() {
          onCreateClass();
        },
        pressCreateNewStudent() {
          onCreateEditStudent();
        },
        pressEditSchool(title: string = "", description: string = "") {
          onCreateEditSchool(title, description);
        },
        onDiscard() {
          onDiscard();
        },
        setActiveItemOnBack(activeItem: string) {
          setActiveItemOnBack(activeItem);
        },
        setCreateTypeMasterAdmin(type: "customers" | "classes") {
          setCreateTypeMasterAdmin(type);
        }
      }));

      /**
       * CreateNewPopUp functions definition
       */
      // Create class
      const onCreateClass = () => {
        // Close the pop up panel menu
        setShowCreateNewPopUp(false);

        // And open the create class pop up window
        dashboardRef.current.setShowCreateNewClass(true);
      };
      // Create Student
      const onCreateEditStudent = (
        title = "New Student",
        description = "Student"
      ) => {
        // Regarding the header
        setShowCreateNewPopUp(false);
        setShowBackButton(true);
        setShowSideBarMenu(false);
        setShowCreateNew(false);
        setSaveButton({ show: true, disabled: true });
        setTitle(title);
        setDescription(
          <div className="w-fit flex gap-1">
            <MdOutlinePerson />
            <div>{description}</div>
          </div>
        );

        // And set the status
        dashboardRef.current.setCreateNewStudent(true);
        // And hide the subheader
        subHeaderRef.current.setShow(false);
      };

      // Create Student
      const onCreateEditSchool = (
        title = "New School",
        description = "School"
      ) => {
        // Regarding the header
        setShowCreateNewPopUp(false);
        setShowBackButton(true);
        setShowSideBarMenu(false);
        setShowCreateNew(false);
        setSaveButton({ show: true, disabled: true });
        setTitle(title);
        setDescription(
          <div className="w-fit flex gap-1">
            <MdCorporateFare />
            <div>{description}</div>
          </div>
        );

        // And set the status
        customersRef.current.setCreateSchool(true);
      };

      /**
       * Function that defines the root view
       */
      const onDiscard = () => {
        // Activate the side bar menu
        setShowSideBarMenu(true);
        // Delete back button
        setShowBackButton(false);
        // Delete the generate report button
        setShowGenerateReportsButton(false);
        // Activating the create new button
        setShowCreateNew(true);
        // And turn off the other buttons
        setSaveButton({ show: false, disabled: true });

        if (userRole === "master admin") {
          // And set the status
          customersRef.current.setCreateSchool(false);
          // And force unselect data
          customersRef.current.setSelectedData(null);
          // And force hide the subheader
          subHeaderRef.current.setShow(false);

          // Change the tab name
          setTitle("Schools");
          setDescription("List of current schools");

          // If there is a specified tab to come back, then come back
          if (activeItemOnBack) {
            setActiveItem(activeItemOnBack);
          }
          // Set the type back to customers
          setCreateTypeMasterAdmin("customers");
        }
        if (["customer admin", "class admin"].includes(userRole)) {
          // And set the status
          dashboardRef.current.setCreateNewStudent(false);
          // And show the subheader again
          subHeaderRef.current.setShow(true);

          // Change the tab name
          setTitle("Dashboard");
          setDescription(`Welcome, ${userFirstName} ${userLastName}`);
        }
      };

      const generateReport = async (format: "csv" | "pdf") => {
        const classId = dashboardRef.current
          .customerAdminDashboard()
          .getSelectedData();
        if (classId) {
          getReports(classId, format);
          setShowGenerateReportsPopUp(false);
        }
      };

      useEffect(() => {
        setTitle("Dashboard");
        setDescription(`Welcome, ${userFirstName}`);
      }, [userFirstName]);

      /**
       * Function to control the save button
       */
      const handleSave = () => {
        // If the save button is available, then make it work
        if (!saveButton.disabled) {
          if (userRole === "master admin") {
            customersRef.current.pressSaveButton();
          } else if (["customer admin", "class admin"].includes(userRole)) {
            dashboardRef.current.pressSaveButton();
          }
        }
      };

      return (
        <>
          {["master admin", "customer admin", "class admin"].includes(userRole) &&
            showGenerateReportsButton && (
              <motion.div
                className="h-24 p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex select-none relative"
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
                onClick={() => {
                  if (!showGenerateReportsPopUp)
                    setShowGenerateReportsPopUp(true);
                }}
              >
                <div className="justify-center items-center gap-0.5 inline-flex cursor-pointer">
                  <div className="w-4 h-4" />
                  <div className="p-3 bg-Canvas rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex hover:bg-neutral-100 ease-in-out duration-300">
                    <div className="w-6 h-6 relative">
                      <MdSystemUpdateAlt className="w-full h-full text-dc-secondary-600" />
                    </div>
                  </div>
                  <div className="w-4 h-4 relative">
                    <MdOutlineExpandMore className="w-4 h-4 text-dc-secondary-600" />
                  </div>
                </div>
                <div className="justify-center items-center gap-0.5 inline-flex">
                  <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide">
                    Generate Report
                  </div>
                </div>
                {showGenerateReportsPopUp && (
                  <GenerateReportPopup
                    setShowPopup={setShowGenerateReportsPopUp}
                    onGenerateCsv={() => generateReport("csv")}
                    onGeneratePdf={() => generateReport("pdf")}
                  />
                )}
              </motion.div>
            )}
          {userRole === "master admin" && showCreateNew && (
            <motion.div
              className="p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex relative"
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
            >
              <div
                className="justify-center items-center gap-0.5 inline-flex cursor-pointer"
                onClick={() => {
                  if (!showCreateNewPopUp)
                    setShowCreateNewPopUp(!showCreateNewPopUp);
                }}
              >
                <div className="p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex hover:bg-neutral-100 ease-in-out duration-300">
                  <div className="w-6 h-6 relative">
                    <MdAdd className="w-full h-full fill-dc-secondary-600" />
                  </div>
                </div>
                <div className="w-4 h-4 relative">
                  <MdOutlineExpandMore className="w-4 h-4 text-dc-secondary-600" />
                </div>
              </div>
              <div className="justify-center items-center gap-0.5 inline-flex">
                <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap select-none">
                  Create
                </div>
                <div className="w-4 h-4 px-[4.28px] opacity-0 justify-center items-center flex" />
              </div>
              {showCreateNewPopUp && (
                <>
                  {createTypeMasterAdmin === "customers" ? (
                    <CreateNewPopUpCustomers
                      setShowCreateNewPopUp={setShowCreateNewPopUp}
                      onCreateEditSchool={() => onCreateEditSchool()}
                    />
                  ) : createTypeMasterAdmin === "classes" ? (
                    <CreateNewPopUpClasses
                      setShowCreateNewPopUp={setShowCreateNewPopUp}
                      onCreateClass={onCreateClass}
                      onCreateEditStudent={() => onCreateEditStudent()}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </motion.div>
          )}
          {["customer admin", "class admin"].includes(userRole) && showCreateNew && (
            <motion.div
              className="p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex relative"
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
            >
              <div
                className="justify-center items-center gap-0.5 inline-flex cursor-pointer"
                onClick={() => {
                  if (!showCreateNewPopUp)
                    setShowCreateNewPopUp(!showCreateNewPopUp);
                }}
              >
                <div className="p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex hover:bg-neutral-100 ease-in-out duration-300">
                  <div className="w-6 h-6 relative">
                    <MdAdd className="w-full h-full fill-dc-secondary-600" />
                  </div>
                </div>
                <div className="w-4 h-4 relative">
                  <MdOutlineExpandMore className="w-4 h-4 text-dc-secondary-600" />
                </div>
              </div>
              <div className="justify-center items-center gap-0.5 inline-flex">
                <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap select-none">
                  Create
                </div>
                <div className="w-4 h-4 px-[4.28px] opacity-0 justify-center items-center flex" />
              </div>
              {showCreateNewPopUp && (
                <CreateNewPopUpClasses
                  setShowCreateNewPopUp={setShowCreateNewPopUp}
                  onCreateClass={onCreateClass}
                  onCreateEditStudent={() => onCreateEditStudent()}
                />
              )}
            </motion.div>
          )}
          {saveButton.show && (
            <div className="justify-start items-start gap-1 flex">
              <div className="p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex">
                <div className="justify-center items-center gap-0.5 inline-flex">
                  <div
                    className="p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex cursor-pointer hover:bg-neutral-100 ease-in-out duration-300"
                    onClick={onDiscard}
                  >
                    <div className="w-6 h-6 relative">
                      <MdClose className="w-full h-full fill-dc-secondary-600" />
                    </div>
                  </div>
                </div>
                <div className="justify-center items-center gap-0.5 inline-flex">
                  <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide">
                    Discard
                  </div>
                </div>
              </div>
              <div className="p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex">
                <div
                  className={
                    saveButton.disabled
                      ? "justify-center items-center gap-0.5 inline-flex"
                      : "justify-center items-center gap-0.5 inline-flex cursor-pointer"
                  }
                  onClick={handleSave}
                >
                  <div
                    className={
                      saveButton.disabled
                        ? "p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex"
                        : "p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex hover:bg-neutral-100 ease-in-out duration-300"
                    }
                  >
                    <div className="w-6 h-6 relative">
                      <MdOutlineSave
                        className={
                          saveButton.disabled
                            ? "w-full h-full fill-neutral-300"
                            : "w-full h-full fill-dc-secondary-600"
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="justify-center items-center gap-0.5 inline-flex">
                  <div
                    className={
                      saveButton.disabled
                        ? "text-center text-neutral-300 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap"
                        : "text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap"
                    }
                  >
                    Save
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
  );

export default DashboardHeaderOptions;
