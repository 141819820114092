interface AssistantButtonProps {
  text?: string;
  onClick?: () => void;
}

const AssistantButton: React.FC<AssistantButtonProps> = ({ text, onClick }) => {
  return (
    <div
      className={`w-[301px] h-[51px] p-2.5 rounded border border-dc-secondary-600 hover:bg-neutral-50 ease-in-out duration-150 justify-center items-center gap-2 inline-flex cursor-pointer`}
      onClick={onClick}
    >
      <div className="text-center text-dc-secondary-600 text-base font-semibold font-['Poppins'] leading-[19px]">
        {text}
      </div>
    </div>
  )
}

export default AssistantButton;