import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

interface VisibilityButtonProps {
  visibility: boolean;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

const VisibilityButton: React.FC<VisibilityButtonProps> = ({
  visibility,
  setVisibility,
}) => {
  // States definition
  const [blockToggle, setBlockToggle] = useState(false);

  // Parameters
  const visibilityTransitionMs = 0.2;

  /**
   * Function executed when clicking on toggle password visibility.
   * This mechanism is necessary to avoid UI problems when clicking
   * too fast this icon.
   */
  const toggleVisibility = () => {
    // Block the toggle button
    setBlockToggle(true);
  };

  /**
   * Function executed every time the toggle visibility has changed
   */
  useEffect(() => {
    if (blockToggle) {
      // Toggle the visibility
      setVisibility(!visibility);

      // Delay the visibility update to allow the exit animation to finish
      setTimeout(() => {
        // And release toggle button
        setBlockToggle(false);
      }, visibilityTransitionMs * 1000 + 20); // delay exceeds the exit animation duration to avoid overclicking errors
    }
  }, [blockToggle]);

  return (
    <div
      className="absolute w-fit h-fit p-2 right-1.5 cursor-pointer z-20 rounded-full hover:bg-neutral-100 ease-in-out duration-150"
      onClick={toggleVisibility}
    >
      <AnimatePresence mode="wait">
        {visibility ? (
          <motion.div
            key="visible"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: visibilityTransitionMs }}
          >
            <MdOutlineVisibility className="w-5 h-5 text-neutral-500" />
          </motion.div>
        ) : (
          <motion.div
            key="hidden"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: visibilityTransitionMs }}
          >
            <MdOutlineVisibilityOff className="w-5 h-5 text-neutral-500" />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default VisibilityButton;
