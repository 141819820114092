import React, { useEffect, useRef } from "react";

interface InactivityTimerProps {
  timeoutMs?: number;
  onTimeout: () => void;
}

const InactivityTimer: React.FC<InactivityTimerProps> = ({
  timeoutMs = 300000,
  onTimeout,
}) => {
  // Define a reference for the timer
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  // Reset the timer when activity is detected
  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      if (onTimeout) onTimeout(); // Call the onTimeout callback
    }, timeoutMs);
  };

  // Attach event listeners to track user activity
  useEffect(() => {
    const handleActivity = () => resetTimer();

    // Activity events to listen
    const events = [
      "mousemove",
      "keydown",
      "mousedown",
      "touchstart",
      "scroll",
      "wheel"
    ];

    // Add the listeners for each event
    events.forEach((event) => window.addEventListener(event, handleActivity));

    // Start the timer initially
    resetTimer();

    // Cleanup on component unmount
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [timeoutMs, onTimeout]);

  return <></>;
};

export default InactivityTimer;
