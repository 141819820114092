import { useEffect, useState } from "react";
import { getCollectionRequest } from "services/apiRequests";
import {
  Course,
  Program,
  Project,
  Module,
  QuizListType,
} from "utils/interfaces";
import QuizCard from "./cards/QuizCard";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";

interface QuizzesListProps {
  userRole: string;
  searchQuery: string;
  selectedData?: Program | Course | Project | Module | null;
  navRegister?: {
    lessonListToShow: string;
    selectedData: Program | Course | Project | Module | null;
    record: Program | Course | Project | Module | null;
  }[];
  setShowEditQuiz?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedQuizToEdit?: React.Dispatch<React.SetStateAction<QuizListType | null>>;
  showEditQuiz?: boolean;
}

const QuizzesList: React.FC<QuizzesListProps> = ({
  userRole,
  searchQuery,
  selectedData,
  navRegister,
  setShowEditQuiz,
  setSelectedQuizToEdit,
  showEditQuiz,
}) => {
  const [data, setData] = useState<QuizListType[]>([]);

  // Define a navigator state
  const navigate = useNavigate();
  const { state } = useLocation();

  /**
   * Root definition
   */
  useEffect(() => {
    if (selectedData !== null && selectedData !== undefined) {
      // console.log("selected data has been provided :)", selectedData)
      let _quizzes: QuizListType[] = [];
      if ("quizzes" in selectedData) {
        // console.log('quizzes in selected data, setting quizzes to show: ', _quizzes)
        _quizzes = selectedData.quizzes;
      }
      getData(_quizzes);
    } else {
      getData();
    }
  }, [selectedData, showEditQuiz]);

  /**
   * Function to get the data depending on the class
   * @returns data
   */
  const getData = async (quizIds: any[] | undefined = undefined) => {
    let response;
    // Define the population option
    if (quizIds !== undefined) {
      response = await getCollectionRequest("/api/quizzes", [], {
        _id: { $in: quizIds },
        active: true,
      });
    } else {
      response = await getCollectionRequest("/api/quizzes", [], {
        active: true,
      });
    }

    // Check if the response is successful
    if (!response.successful) {
      // Check if it's not successful because of a token expiration
      if (response.logout) {
        // alert(response.message);
        navigate("/");
      }

      return [];
    }

    // Get the data
    const data = response.content;

    // Assign the data to the corresponding element
    setData(data);
    return data;
  };

  /**
   * Function applied when we press the edit button on cards
   */
  const handleCardClick = (record: QuizListType, selectedTab: number) => {
    if (selectedTab === 0) {
      console.log(state);
      if (navRegister !== undefined) {
        navigate(`/quiz/${record._id}`, {
          state: {
            activeItem: "lessons",
            activeLesson: selectedData?._id,
            navRegister: navRegister,
          },
        });
      } else {
        navigate(`/quiz/${record._id}`, { state });
      }
    }
  };

  /**
   * Expand click definition
   */
  const handleExpandClick = (record: QuizListType) => {
    return (event: React.MouseEvent) => {
      event.stopPropagation();
      handleCardClick(record, 0);
    };
  };

  /**
   * Edit click definition
   */
  const handleEditClick = (record: QuizListType) => {
    return (event: React.MouseEvent) => {
      event.stopPropagation();
      setShowEditQuiz && setShowEditQuiz(true);
      setSelectedQuizToEdit && setSelectedQuizToEdit(record);
    };
  };

  /**
   * Function that renders the list of cards depending of the activeClass selected
   * @returns a list of cards
   */
  const cardsToRender = () => {
    return (
      data &&
      data.length > 0 &&
      data
        .filter((item: QuizListType) =>
          item.active && item.title.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .map((quiz: any, index: number) => (
          <QuizCard
            key={index}
            userRole={userRole}
            quiz={quiz}
            handleExpand={handleExpandClick(quiz)}
            handleEdit={handleEditClick(quiz)}
          />
        ))
    );
  };

  return (
    <>
      {data && data.length > 0 && (
        <AnimatePresence>
          <motion.div className="w-full h-full inline-flex flex-col gap-3">
            {cardsToRender()}
          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
};

export default QuizzesList;
