import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchPdf } from "services/filesRequests";
import PDFViewer from "components/Editor/utils/PDFViewer";
import InstructionsBar from "./InstructionsBar";
import TabBar from "./TabBar";
import { ExternalEditorContext } from "components/main-view/utils/Contexts";

interface RightPaneType {
  pdf: string;
  videoTranscript: string;
  isResizing: boolean;
  rightPanelRef: React.RefObject<HTMLDivElement>;
  handleOpenClick: () => void;
  handleCloseClick: () => void;
}

const RightPane: React.FC<RightPaneType> = ({
  pdf,
  videoTranscript,
  isResizing,
  rightPanelRef,
  handleOpenClick,
  handleCloseClick,
}) => {
  // States definition
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTabName, setSelectedTabName] = useState("");

  // Parameters retrieval
  const { moduleOrProject, id } = useParams();

  // Get parameters from context
  const { normalisedRightWidth } = useContext(ExternalEditorContext);

  // Parameters definition
  const tabOptions: string[] = [];
  if (pdf) tabOptions.push("Instructions");
  // if (videoTranscript) tabOptions.push("Video Transcript");

  /**
   * Function to get the name of the current tab
   */
  useEffect(() => {
    setSelectedTabName(tabOptions[selectedTabIndex] || "");
  }, [tabOptions, selectedTabIndex]);

  /**
   * Function to execute when clicking the PDF download button
   * @returns
   */
  const handleDownloadClick = () => {
    // If there is no pdf, then prevent to execute this function
    if (!pdf) return;

    fetchPdf(moduleOrProject || "", id || "").then((url: string) => {
      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = url;

      // Set the donwload name
      link.download = pdf;

      // Create the element, click it and the remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // And finally revoke the URL
      URL.revokeObjectURL(url);
    });
  };

  return (
    <div
      className="flex flex-row w-full h-full mr-10 rounded-xl"
      ref={rightPanelRef}
    >
      <div
        className={`flex flex-1 p-3 bg-neutral-50 ${
          normalisedRightWidth === 0 ? "cursor-pointer " : ""
        }h-full overflow-hidden rounded-xl gap-2`}
        onClick={handleOpenClick}
      >
        <motion.div
          className={`${
            normalisedRightWidth === 0 ? "w-0 h-0" : "w-full h-full" // Force them to be zero to avoid clicking problems
          } inline-flex flex-col gap-3 overflow-hidden`}
          style={{ opacity: normalisedRightWidth }}
        >
          <InstructionsBar
            selectedTabIndex={selectedTabIndex}
            tabOptions={tabOptions}
            setSelectedTabIndex={setSelectedTabIndex}
            handleDownloadClick={handleDownloadClick}
            handleCloseClick={handleCloseClick}
          />
          <div className="flex-1 flex p-3 bg-white rounded-2xl flex-col h-full overflow-hidden">
            {selectedTabName.toLowerCase() === "instructions" && (
              <PDFViewer isPaning={isResizing} isVisible={true} />
            )}
            {selectedTabName.toLowerCase() === "video transcript" && (
              <div className="pr-4 overflow-auto custom-scroll">
                {videoTranscript}
              </div>
            )}
          </div>
        </motion.div>
      </div>
      <div className="flex-none w-10 h-full ml-auto">
        {normalisedRightWidth < 1 && (
          <div className="w-fit h-fit translate-x-[1.5rem] select-none text-neutral-800 origin-center pointer-events-none">
            <motion.div
              style={{
                rotate: (1 - normalisedRightWidth) * 90,
                translateX: Math.round(
                  (normalisedRightWidth - 1) * 92 + normalisedRightWidth * -407
                ),
                translateY: Math.round(
                  (1 - normalisedRightWidth) * 50 + normalisedRightWidth * 20
                ),
                transition: "transform 0s",
              }}
            >
              <motion.div
                style={{
                  opacity: 1 - normalisedRightWidth,
                }}
              >
                Instructions
              </motion.div>
              <motion.div
                className="absolute w-fit h-fit "
                style={{
                  opacity: normalisedRightWidth,
                  translateX: -18,
                  translateY: -32,
                }}
              >
                {tabOptions && tabOptions.length > 0 && (
                  <TabBar
                    tabOptions={tabOptions}
                    selectedTabIndex={selectedTabIndex}
                    setSelectedTabIndex={setSelectedTabIndex}
                  />
                )}
              </motion.div>
            </motion.div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RightPane;
