import watermarkImage2 from "assets/imgs/watermark2.png";
import { useEffect, useState } from "react";
import { getCollectionRequest } from "services/apiRequests";
import { BACKEND_URL } from "config/getUrl";
import { platform } from "config/platform";

interface MyBadgesProps {}

const MyBadges: React.FC<MyBadgesProps> = ({}) => {
  // States definition
  const [badges, setBadges] = useState<string[]>([]);

  // Parameter definition
  const maxAmountOfBadges = 4;

  /**
   * Get the data every time it's rendered
   */
  useEffect(() => {
    getData();
  }, []);

  /**
   * Function used to request the progress values
   */
  const getData = async () => {
    // Get the knowledge points
    const response = await getCollectionRequest("/api/users/my-badges");

    // Check if the response is successful
    if (!response.successful) {
      return;
    }

    // Setting the id of the badges
    setBadges(response.content);

    return response;
  };

  return (
    <div className="self-stretch h-[390px] min-h-[390px] bg-neutral-25 rounded-3xl flex-col justify-between items-start flex overflow-hidden relative">
      {platform === "deadly-coders" && (
        <img
          className="absolute w-[390px] h-[390px] right-[-140px] rotate-[3.62deg] opacity-20 select-none"
          src={watermarkImage2}
        />
      )}
      <div className="self-stretch p-6 items-start inline-flex select-none">
        {platform === "deadly-coders" && (
          <>
            {badges
              .slice(-maxAmountOfBadges)
              .map((badge: string, index: number) => (
                <img
                  key={index}
                  className="w-20 h-20 rounded-full shadow border border-neutral-200 bg-white relative"
                  style={{
                    right: index * 24,
                  }}
                  src={`${BACKEND_URL}${badge}`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "/logo.svg";
                  }}
                />
              ))}
            {badges.length > maxAmountOfBadges && (
              <div
                className="w-20 h-20 min-w-20 rounded-full bg-neutral-25/80 shadow relative z-20"
                style={{
                  right: maxAmountOfBadges * 24,
                }}
              >
                <div className="w-full h-full absolute rounded-full border-4 border-[#d18732]/40 justify-center items-center inline-flex">
                  <div className="text-[#955309]/80 text-[33px] font-semibold font-sans leading-10">
                    {badges.length - maxAmountOfBadges}+
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {platform === "codeflix" && (
          <>
            {badges
              .slice(-maxAmountOfBadges)
              .map((badge: string, index: number) => (
                <img
                  key={index}
                  className="w-20 h-20 relative"
                  style={{
                    right: index * 28,
                  }}
                  src={`${BACKEND_URL}${badge}`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "/logo.svg";
                  }}
                />
              ))}
            {badges.length > maxAmountOfBadges && (
              <div
                className="w-20 h-20 min-w-20 relative z-20 inline-flex items-center justify-center"
                style={{
                  right: maxAmountOfBadges * 28,
                }}
              >
                <div className="w-14 h-14 absolute bg-neutral-25/80 shadow border-4 border-[#d18732]/40 rounded-md justify-center items-center inline-flex rotate-45">
                  <div className="text-[#955309]/80 text-[24px] font-semibold font-sans leading-10 -rotate-45">
                    {badges.length - maxAmountOfBadges}+
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="self-stretch p-6 justify-between items-end inline-flex z-10">
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
          <div className="self-stretch relative">
            <div className="text-neutral-700 text-[33px] font-semibold font-sans leading-10 select-none">
              {badges.length || 0}
            </div>
          </div>
          <div className="self-stretch h-fit flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch text-neutral-700 text-lg font-normal font-sans leading-snug select-none">
              My Badges
            </div>
            <div className="self-stretch text-neutral-500 text-xs font-normal font-sans leading-[14px] select-none">
              You have earned {badges.length}{" "}
              {badges.length === 1 ? "badge" : "badges"} so far
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyBadges;
