import { useEffect, useState } from "react";
import ViewHeader from "components/main-view/utils/view-header/ViewHeader";
import QuizzesList from "./QuizzesList";

interface QuizzesProps {
  userRole: string;
  headerRef: React.MutableRefObject<any>;
}

const Quizzes: React.FC<QuizzesProps> = ({
  userRole,
  headerRef,
}) => {
  // States definition
  const [searchQuery, setSearchQuery] = useState("");
  const [showTabSearchBar, setShowTabSearchBar] = useState(true);

  /**
   * Define the tab title and description once opened
   */
  useEffect(() => {
    headerRef.current.setHeaderTitle("Quizzes");
    headerRef.current.setHeaderDescription(`List of quizzes`);
  }, []);

  return (
    <div className="w-full self-stretch flex-col justify-start items-start gap-4 inline-flex">
      {showTabSearchBar && (
        <ViewHeader
          type="quizzes"
          userRole={userRole}
          activeItem={""}
          setSearchQuery={setSearchQuery}
          tabOptions={[]}
          setActiveItem={() => {}}
          headerRef={headerRef}
        />
      )}
      <QuizzesList
        userRole={userRole}
        searchQuery={searchQuery}
      />
    </div>
  );
};

export default Quizzes;
