import StudentCard from "./cards/StudentCard";
import ClassesListCards from "./cards/ClassesListCards";
import ParentCard from "./cards/ParentCard";
import TeacherCard from "./cards/TeacherCard";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { LoadingDots } from "utils/loadingAnimation";
import {
  ClassListType,
  ContactListType,
  ParentListType,
  StudentListType,
  TeacherListType,
} from "utils/interfaces";
import EmptyView from "./utils/EmptyView";

interface AnimatedClassesCardsProps {
  activeClass: string;
  isLoadingClasses: boolean;
  isLoadingTeachers: boolean;
  isLoadingStudents: boolean;
  isLoadingParents: boolean;
  searchQuery: string;
  classes: ClassListType[] | null;
  teachers: TeacherListType[] | null;
  students: StudentListType[] | null;
  parents: ParentListType[] | null;
  setShowTeachersList: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTeachers: React.Dispatch<React.SetStateAction<ContactListType[]>>;
  handleCardClick: (
    card: ClassListType | TeacherListType | ParentListType | StudentListType
  ) => void;
  headerRef: React.MutableRefObject<any>;
}

const AnimatedClassesCards: React.FC<AnimatedClassesCardsProps> = ({
  activeClass,
  isLoadingClasses,
  isLoadingTeachers,
  isLoadingStudents,
  isLoadingParents,
  classes,
  teachers,
  students,
  parents,
  searchQuery,
  setShowTeachersList,
  setSelectedTeachers,
  handleCardClick,
  headerRef,
}) => {
  // States definition
  const [previousList, setPreviousList] = useState(activeClass);

  /**
   * Define the previous list
   */
  useEffect(() => {
    setPreviousList(activeClass);
  }, [activeClass]);

  return (
    <div className="relative w-full h-full">
      {/* Classes */}
      <motion.div
        initial={{
          x: "0%",
        }}
        animate={{
          x: activeClass === "classes" ? "0%" : "-100%",
          opacity: activeClass === "classes" ? 1 : 0,
          transition: {
            duration: 0.5,
          },
        }}
        className="w-full h-full flex-col gap-4 inline-flex absolute top-0 left-0 overflow-y-auto custom-scroll"
      >
        {classes && classes.length > 0 ? (
          <ClassesListCards
            canEdit={true}
            classes={classes}
            searchQuery={searchQuery}
            setSelectedTeachers={setSelectedTeachers}
            setShowTeachersList={setShowTeachersList}
            onCardClick={handleCardClick}
          />
        ) : !isLoadingClasses ? (
          <EmptyView activeClass={activeClass} headerRef={headerRef} />
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <LoadingDots bgColor="bg-neutral-300" />
          </div>
        )}
      </motion.div>

      {/* Teachers */}
      <motion.div
        initial={{
          x: "100%",
        }}
        animate={{
          x:
            previousList === "classes"
              ? "100%"
              : activeClass === "staff"
              ? "0%"
              : "-100%",
          opacity: activeClass === "staff" ? 1 : 0,
          transition: {
            duration: 0.5,
          },
        }}
        className="w-full h-full flex-col gap-4 inline-flex absolute top-0 left-0 overflow-y-auto custom-scroll"
      >
        {teachers && teachers.length > 0 ? (
          teachers
            .filter((teacher: TeacherListType) => {
              let name = teacher.firstName + " " + teacher.lastName;
              return name.toLowerCase().includes(searchQuery.toLowerCase());
            })
            .map((teacher: TeacherListType, index: number) => (
              <TeacherCard
                key={index}
                teacher={teacher}
                handleCardClick={handleCardClick}
              />
            ))
        ) : !isLoadingTeachers ? (
          <EmptyView activeClass={activeClass} headerRef={headerRef} />
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <LoadingDots bgColor="bg-neutral-300" />
          </div>
        )}
      </motion.div>

      {/* Students */}
      <motion.div
        initial={{
          x: "100%",
        }}
        animate={{
          x:
            previousList === "parents"
              ? "-100%"
              : activeClass === "students"
              ? "0%"
              : "100%",
          opacity: activeClass === "students" ? 1 : 0,
          transition: {
            duration: 0.5,
          },
        }}
        className="w-full h-full flex-col gap-4 inline-flex absolute top-0 left-0 overflow-y-auto custom-scroll"
      >
        {students && students.length > 0 ? (
          students
            .filter((student: StudentListType) => {
              let name = student.firstName + " " + student.lastName;
              return name.toLowerCase().includes(searchQuery.toLowerCase());
            })
            .map((student: StudentListType, index: number) => (
              <StudentCard
                key={index}
                student={student}
                handleCardClick={handleCardClick}
              />
            ))
        ) : !isLoadingStudents ? (
          <EmptyView activeClass={activeClass} headerRef={headerRef} />
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <LoadingDots bgColor="bg-neutral-300" />
          </div>
        )}
      </motion.div>

      {/* Parents */}
      <motion.div
        initial={{
          x: "100%",
        }}
        animate={{
          x: activeClass === "parents" ? "0%" : "100%",
          opacity: activeClass === "parents" ? 1 : 0,
          transition: {
            duration: 0.5,
          },
        }}
        className="w-full h-full flex-col gap-4 inline-flex absolute top-0 left-0 overflow-y-auto custom-scroll"
      >
        {parents && parents.length > 0 ? (
          parents
            .filter((parent: ParentListType) => {
              let name = parent.firstName + " " + parent.lastName;
              return name.toLowerCase().includes(searchQuery.toLowerCase());
            })
            .map((parent: ParentListType, index: number) => (
              <ParentCard
                key={index}
                parent={parent}
                handleCardClick={handleCardClick}
              />
            ))
        ) : !isLoadingParents ? (
          <EmptyView activeClass={activeClass} headerRef={headerRef} />
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <LoadingDots bgColor="bg-neutral-300" />
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default AnimatedClassesCards;
