import { Transition } from "@headlessui/react";
import { Fragment, useContext, useRef, useState } from "react";
import QuizPanel from "./quiz-pop-up/QuizPanel";
import {
  ClassListType,
  Course,
  Module,
  Program,
  Project,
  QuizListType,
} from "utils/interfaces";
import SavedSuccessfulPanel from "./saved-successful-pop-up/SavedSuccessfulPanel";
import AssignLessonPanel from "./assign-lesson-pop-up/AssignLessonPanel";
import AddRemovePanel from "./add-pop-up/AddRemovePanel";
import OptionsPanel from "components/main-view/utils/pop-up-panels/options-pop-up/OptionsPanel";
import { levelList, programLanguagesList } from "config/optionValues";
import AssignClassPanel from "components/main-view/utils/pop-up-panels/assign-class-pop-up/AssignClassPanel";
import { MainContext } from "components/main-view/utils/Contexts";

interface PopUpPanelProps {
  type:
    | "add-remove-child"
    | "level"
    | "quiz"
    | "language"
    | "saved-successful"
    | "assign-class"
    | "assign-to-parent-lesson"
    | "edit-quiz";
  showPopUp: boolean;
  selectedData?: Program | Course | Project | Module | null;
  selectedDataId?: string;
  selectedLesson?: string;
  quizzes?: QuizListType[];
  selectedQuizToEdit?: QuizListType | null;
  selectedClasses?: ClassListType[];
  fromAddModuleCardButton?: boolean;
  lessonIdToAssignToClass?: string;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setOption?: React.Dispatch<React.SetStateAction<string>>;
  setQuizzes?: React.Dispatch<React.SetStateAction<QuizListType[]>>;
  setLinkedId?: React.Dispatch<React.SetStateAction<string>>;
  setLinkedLesson?: React.Dispatch<React.SetStateAction<string>>;
  headerRef?: React.MutableRefObject<any>;
  lessonsListRef?: any;
}

const PopUpPanel: React.FC<PopUpPanelProps> = ({
  type,
  selectedData,
  selectedDataId,
  selectedLesson,
  quizzes,
  selectedQuizToEdit,
  selectedClasses,
  fromAddModuleCardButton = false,
  lessonIdToAssignToClass,
  showPopUp,
  setShowPopUp,
  setOption,
  setQuizzes,
  setLinkedId,
  setLinkedLesson,
  headerRef,
  lessonsListRef,
}) => {
  // States defininition
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  // Get context variables
  const { userSchool } = useContext(MainContext);

  // Reference for the quiz panel
  const quizPanelRef = useRef();
  const assignClassRef = useRef();

  /**
   * Handle clicking in the black screen
   */
  const handleClickBlackScreen = () => {
    // Prevent closing for these types
    if (type === "quiz") {
      // Get the current quizPanel pop up state
      let currentHandler: any;
      if (quizPanelRef.current) currentHandler = quizPanelRef.current;
      const popUpState = currentHandler.getPopUpState();

      // If the pop up state is one of these ones, then don't allow to
      // close when clicking the black screen
      if (
        ["create-new", "add-question", "edit-question"].includes(popUpState)
      ) {
        // Activate the warning message
        setShowWarningMessage(true);
        // And finish the function
        return;
      }
    } else if (type === "assign-class") {
      // Get the current quizPanel pop up state
      let currentHandler: any;
      if (assignClassRef.current) currentHandler = assignClassRef.current;
      const popUpState = currentHandler.getPopUpState();

      // If the pop up state is one of these ones, then don't allow to
      // close when clicking the black screen
      if (["create-new", "add-teacher"].includes(popUpState)) {
        // Activate the warning message
        setShowWarningMessage(true);
        // And finish the function
        return;
      }
    }

    // Close the pop up
    setShowPopUp(false);
    // Force close the warning message
    setShowWarningMessage(false);
  };

  return (
    <>
      <Transition
        show={showPopUp}
        as={Fragment}
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 w-full h-full bg-neutral-500 bg-opacity-40 backdrop-blur-[2px] z-20 flex flex-col flex-grow"
          onClick={handleClickBlackScreen}
        />
      </Transition>
      {/* Add pop-up */}
      <Transition
        show={showPopUp && type === "add-remove-child"}
        as={"div"} // Done this way because if we use fragments we won't be able to pass setStates methods.
        className="fixed w-[462px] h-fit left-0 right-0 ml-auto mr-auto bottom-4 px-6 pt-6 pb-8 bg-white rounded-3xl flex-col justify-start items-start gap-6 inline-flex z-30"
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <AddRemovePanel
          selectedData={selectedData}
          selectedLesson={selectedLesson || ""}
          setShowPopUp={setShowPopUp}
          setLinkedId={setLinkedId ? setLinkedId : () => {}}
          setLinkedLesson={setLinkedLesson ? setLinkedLesson : () => {}}
          headerRef={headerRef}
          lessonsListRef={lessonsListRef}
        />
      </Transition>
      {/* Level pop-up */}
      <Transition
        show={showPopUp && type === "level"}
        as={"div"}
        className="fixed w-[451px] h-96 px-6 pt-6 pb-8 bottom-5 bg-white rounded-3xl flex-col justify-center items-center gap-6 inline-flex z-30"
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <OptionsPanel
          options={levelList}
          setShowPopUp={setShowPopUp}
          setOptions={setOption ? setOption : () => {}}
        />
      </Transition>
      {/* Quiz pop-up */}
      <Transition
        show={showPopUp && type === "quiz"}
        as={"div"}
        className="fixed inset-0 inline-flex items-end p-5 justify-center z-30"
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="absolute w-full h-full z-30"
          onClick={handleClickBlackScreen}
        />
        {/* Needed to encapsulate this to ensure `react-beautiful-dnd` works alright inside this module. */}
        <div className="w-fit h-fit px-6 pt-6 pb-8 bg-white rounded-3xl flex-col gap-4 justify-between items-start inline-flex overflow-hidden z-40">
          <QuizPanel
            ref={quizPanelRef}
            selectedLessonData={selectedData}
            quizzes={quizzes || []}
            setShowPopUp={setShowPopUp}
            setQuizzes={setQuizzes ? setQuizzes : () => {}}
            showWarningMessage={showWarningMessage}
            setShowWarningMessage={setShowWarningMessage}
          />
        </div>
      </Transition>
      {/* Coding language pop-up */}
      <Transition
        show={showPopUp && type === "language"}
        as={"div"}
        className="fixed w-[451px] h-fit px-6 pt-6 pb-8 bottom-5 bg-white rounded-3xl flex-col justify-center items-center gap-6 inline-flex z-30"
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <OptionsPanel
          options={[...programLanguagesList]}
          setShowPopUp={setShowPopUp}
          setOptions={setOption ? setOption : () => {}}
        />
      </Transition>
      {/* Saved successful message pop-up */}
      <Transition
        show={showPopUp && type === "saved-successful"}
        as={"div"}
        className="fixed w-[440px] h-[590px] left-0 right-0 ml-auto mr-auto p-6 bottom-5 bg-white rounded-3xl flex-col justify-start items-center gap-10 inline-flex z-30"
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <SavedSuccessfulPanel
          selectedLesson={selectedLesson || ""}
          setShowPopUp={setShowPopUp}
          headerRef={headerRef}
        />
      </Transition>
      {/* Assign to class pop-up as customer admin */}
      <Transition
        show={showPopUp && type === "assign-class"}
        as={"div"}
        className="fixed w-[462px] h-fit left-0 right-0 ml-auto mr-auto px-6 pt-6 pb-8 bottom-5 bg-white rounded-3xl flex-col justify-start items-start gap-6 inline-flex z-30"
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <AssignClassPanel
          ref={assignClassRef}
          contextSchool={userSchool}
          selectedLesson={selectedLesson}
          selectedClasses={selectedClasses}
          lessonIdToAssign={lessonIdToAssignToClass}
          setShowPopUp={setShowPopUp}
          showWarningMessage={showWarningMessage}
          setShowWarningMessage={setShowWarningMessage}
          closeOnceClickOnCard={false}
        />
      </Transition>
      {/* Assign to lesson pop-up */}
      <Transition
        show={
          showPopUp && !!selectedDataId && type === "assign-to-parent-lesson"
        }
        as={"div"}
        className="fixed w-[462px] h-fit left-0 right-0 ml-auto mr-auto px-6 pt-6 pb-8 bottom-5 bg-white rounded-3xl flex-col justify-start items-start gap-6 inline-flex z-30"
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <AssignLessonPanel
          selectedDataId={selectedDataId || ""}
          selectedLesson={selectedLesson || ""}
          fromAddModuleCardButton={fromAddModuleCardButton}
          setShowPopUp={setShowPopUp}
          setLinkedId={setLinkedId ? setLinkedId : () => {}}
          setLinkedLesson={setLinkedLesson ? setLinkedLesson : () => {}}
          headerRef={headerRef}
        />
      </Transition>
      {/* Edit quiz pop-up */}
      <Transition
        show={showPopUp && type === "edit-quiz"}
        as={"div"}
        className="fixed inset-0 inline-flex items-end p-5 justify-center z-30"
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="absolute w-full h-full z-30"
          onClick={handleClickBlackScreen}
        />
        {/* Needed to encapsulate this to ensure `react-beautiful-dnd` works alright inside this module. */}
        <div className="w-fit h-fit px-6 pt-6 pb-8 bg-white rounded-3xl flex-col gap-4 justify-between items-start inline-flex overflow-hidden z-40">
          <QuizPanel
            quizzes={quizzes || []}
            selectedLessonData={selectedData}
            selectedQuizToEdit={selectedQuizToEdit}
            setShowPopUp={setShowPopUp}
            setQuizzes={setQuizzes ? setQuizzes : () => {}}
          />
        </div>
      </Transition>
    </>
  );
};

export default PopUpPanel;
