import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ViewHeader from "components/main-view/utils/view-header/ViewHeader";
import CustomersList from "./CustomersList";
import { SchoolListType } from "utils/interfaces";
import AddNewSchool from "./add-new-school-view/AddNewSchool";
import PopUpPanel from "./pop-up-panels/PopUpPanel";
import CustomerAdminDashboard from "../dashboard/customer-admin/CustomerAdminDashboard";
import { CustomersContext } from "../utils/Contexts";
import { MdCorporateFare } from "react-icons/md";

interface CustomersProps {
  ref: any;
  userRole: string;
  userFirstName: string;
  userLastName: string;
  backPressedToggle: boolean;
  headerRef: React.MutableRefObject<any>;
  subHeaderRef: React.MutableRefObject<any>;
  customerAdminDashboardRef?: React.MutableRefObject<any>;
  setShowSuccessfulSchoolEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  from?: "main" | "dashboard";
}

const Customers: React.FC<CustomersProps> = forwardRef(
  (
    {
      userRole,
      userFirstName,
      userLastName,
      backPressedToggle,
      headerRef,
      subHeaderRef,
      customerAdminDashboardRef,
      setShowSuccessfulSchoolEdit,
      from = "dashboard",
    },
    ref
  ) => {
    // States definition
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedData, setSelectedData] = useState<SchoolListType | null>(
      null
    );
    const [showSavedSuccessfully, setShowSavedSuccessfully] = useState(false);
    const [display, setDisplay] = useState<
      "main" | "create-edit" | "inside-school"
    >("main");
    // State used to manage the image cache
    const [version, setVersion] = useState(0);

    // References definition
    const customersListRef = useRef(null);
    // Add customer reference definition
    const addCustomerRef = useRef(null);

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      setCreateSchool(bool: boolean) {
        setDisplay(bool ? "create-edit" : "main");
      },
      pressSaveButton() {
        let currentHandler: any;

        // If we are working with schools
        if (["main", "create-edit"].includes(display)) {
          if (addCustomerRef.current) currentHandler = addCustomerRef.current;
          currentHandler.handleSaveSchool();
        }
        // In the case of creating a new student
        else {
          if (customerAdminDashboardRef && customerAdminDashboardRef.current)
            currentHandler = customerAdminDashboardRef.current;
          currentHandler.handleSaveNewStudent();

          // And comeback to the previous view
          getBackToSelectedClass();
        }
      },
      setSelectedData(data: SchoolListType | null) {
        setSelectedData(data);
      },
      showSchool(id: string, currentActiveItem: string) {
        let currentHandler: any;
        if (customersListRef.current) currentHandler = customersListRef.current;
        currentHandler.setSchoolToShow(id, currentActiveItem);
      },
    }));

    /**
     * Reference to get data
     */
    const getData = async () => {
      if (customersListRef.current)
        await (customersListRef.current as any).getData();
    };

    /**
     * Function used to set title and description
     * @param card
     */
    const setTitleAndDescription = (card: SchoolListType) => {
      // Set the title and description
      headerRef.current.setHeaderTitle(card.name);
      headerRef.current.setHeaderDescription(
        <div className="w-fit flex gap-1">
          <MdCorporateFare />
          <div>School Panel</div>
        </div>
      );
    };

    /**
     * Function executed when clicking back inside "Create new Student" to avoid
     * coming back to the list of Schools.
     */
    const getBackToSelectedClass = () => {
      // First of all, check if there is selected data
      if (!selectedData) return;

      // Also check that the customerAdminDashboardRef exists
      if (!(customerAdminDashboardRef && customerAdminDashboardRef.current))
        return;

      // Set the state to false
      customerAdminDashboardRef.current.setCreateNewStudent(false);

      // Activate the side bar menu
      headerRef.current.setShowSideBarMenu(true);
      // Activating the create new button
      headerRef.current.dashboard().setShowCreateNewButton(true);
      // Activate the sub header
      subHeaderRef.current.setShow(true);
      // And turn off the other buttons
      headerRef.current
        .dashboard()
        .setSaveButton({ show: false, disabled: true });

      // And set the title and description again
      setTitleAndDescription(selectedData);
    };

    /**
     * Everytime the backPressedToggle signal is activated, we come back to the root view
     */
    useEffect(() => {
      // Check if we have the customer admin view (after clicked a card)
      if (selectedData) {
        // Check if we are inside the create new student option
        if (customerAdminDashboardRef && customerAdminDashboardRef.current) {
          // Get the internal state
          const createNewStudent =
            customerAdminDashboardRef.current.getCreateNewStudent();

          // If we are inside the create new student
          if (createNewStudent) {
            // Come back to the previous view
            getBackToSelectedClass();
            return;
          }
        }
      }

      let currentHandler;
      if (from === "dashboard") {
        currentHandler = headerRef.current.dashboard();

        // Force to come back to the customers view
        currentHandler.setCreateTypeMasterAdmin("customers");
      } else {
        currentHandler = headerRef.current.customers();
      }

      // Once pressed, come back to the root view
      currentHandler.onDiscard();

      // Define the tab title and description once opened
      headerRef.current.setHeaderTitle("Schools");
      headerRef.current.setHeaderDescription(`List of current schools`);

      // And force unselect data
      setSelectedData(null);
      // And force hide the successful message
      if (setShowSuccessfulSchoolEdit) setShowSuccessfulSchoolEdit(false);
    }, [backPressedToggle]);

    /**
     * Function executed when clicking create new school on the
     * successful panel
     */
    const handleCreateAnotherSchool = () => {
      // Create a new school
      headerRef.current.dashboard().pressCreateNewSchool();

      // And hide the pop up message
      setShowSavedSuccessfully(false);
    };

    /**
     * Function used after save a school
     */
    const afterSaveFunction = async () => {
      // Everytime this function is called, update the version
      setVersion((currentVersion) => currentVersion + 1);

      // Display the successful message
      setShowSavedSuccessfully(true);

      // Display external successful message
      if (setShowSuccessfulSchoolEdit) setShowSuccessfulSchoolEdit(true);

      // And refresh the data
      await getData();
    };

    return (
      <CustomersContext.Provider value={{ version, setVersion }}>
        <div className="w-full self-stretch flex-col justify-start items-start gap-4 inline-flex">
          {display === "main" ? (
            <>
              <ViewHeader
                type="customers"
                userRole={userRole}
                activeItem={""}
                tabOptions={[]}
                setActiveItem={() => {}}
                setSearchQuery={setSearchQuery}
                headerRef={headerRef}
              />
              <CustomersList
                ref={customersListRef}
                searchQuery={searchQuery}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                setDisplay={setDisplay}
                headerRef={headerRef}
                from={from}
              />
            </>
          ) : display === "create-edit" ? (
            <AddNewSchool
              ref={addCustomerRef}
              userRole={userRole}
              afterSaveFunction={afterSaveFunction}
              selectedData={selectedData}
              headerRef={headerRef}
              from={from}
            />
          ) : (
            <CustomerAdminDashboard
              ref={customerAdminDashboardRef}
              userRole={userRole}
              userFirstName={userFirstName}
              userLastName={userLastName}
              userSchool={selectedData}
              headerRef={headerRef}
              subHeaderRef={subHeaderRef}
            />
          )}
          <PopUpPanel
            type="saved-successful"
            showPopUp={showSavedSuccessfully}
            setShowPopUp={setShowSavedSuccessfully}
            onCreateAnotherSchool={handleCreateAnotherSchool}
          />
        </div>
      </CustomersContext.Provider>
    );
  }
);

export default Customers;
