import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
  Course,
  Module,
  Program,
  Project,
  QuizListType,
} from "utils/interfaces";
import ProgramCard from "./cards/ProgramCard";
import CourseCard from "./cards/CourseCard";
import ProjectCard from "./cards/ProjectCard";
import ModuleCard from "./cards/ModuleCard";
import EmptyView from "./utils/EmptyView";
import QuizzesList from "../quizzes/QuizzesList";
import { LoadingDots } from "utils/loadingAnimation";

interface AnimatedLessonCardsParams {
  userRole: string;
  lessonListToShow: string;
  programs: Program[];
  courses: Course[];
  projects: Project[];
  modules: Module[];
  searchQuery: string;
  isLoadingPrograms: boolean;
  isLoadingCourses: boolean;
  isLoadingProjects: boolean;
  isLoadingModules: boolean;
  headerRef: any;
  selectedData: Program | Course | Project | Module | null;
  navRegister: {
    lessonListToShow: string;
    selectedData: Program | Course | Project | Module | null;
    record: Program | Course | Project | Module | null;
  }[];
  handleExpandClick: (
    record: Program | Course | Project | Module
  ) => () => void;
  handleEditClick: (
    record: Program | Course | Project | Module
  ) => (event: React.MouseEvent) => void;
  handleAddToClasses: (
    record: Program | Course | Project | Module,
    lesson: string
  ) => (event: React.MouseEvent) => void;
  setSelectedModuleId: React.Dispatch<React.SetStateAction<string>>;
  setShowAddToLesson: React.Dispatch<React.SetStateAction<boolean>>;
  setLinkedId: React.Dispatch<React.SetStateAction<string>>;
  setLinkedLesson: React.Dispatch<React.SetStateAction<string>>;
  setShowEditQuiz: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedQuizToEdit: React.Dispatch<
    React.SetStateAction<QuizListType | null>
  >;
  showEditQuiz: boolean;
}

const AnimatedLessonCards: React.FC<AnimatedLessonCardsParams> = ({
  userRole,
  lessonListToShow,
  programs,
  courses,
  projects,
  modules,
  searchQuery,
  isLoadingPrograms,
  isLoadingCourses,
  isLoadingProjects,
  isLoadingModules,
  headerRef,
  selectedData,
  navRegister,
  handleExpandClick,
  handleEditClick,
  handleAddToClasses,
  setSelectedModuleId,
  setShowAddToLesson,
  setLinkedId,
  setLinkedLesson,
  setShowEditQuiz,
  setSelectedQuizToEdit,
  showEditQuiz,
}) => {
  // States definition
  const [previousList, setPreviousList] = useState(lessonListToShow);

  /**
   * Define the previous list
   */
  useEffect(() => {
    setPreviousList(lessonListToShow);
  }, [lessonListToShow]);

  return (
    <div className="relative w-full h-full">
      {/* Programs */}
      <motion.div
        initial={{
          x: "0%",
        }}
        animate={{
          x: lessonListToShow === "programs" ? "0%" : "-100%",
          opacity: lessonListToShow === "programs" ? 1 : 0,
          transition: {
            duration: 0.5,
          },
        }}
        className="w-full h-full flex-col gap-4 inline-flex absolute top-0 left-0 overflow-y-auto custom-scroll"
      >
        {programs && programs.length > 0 ? (
          programs
            .filter((program: Program) =>
              program.title.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map((program: Program, index: number) => (
              <ProgramCard
                key={index}
                userRole={userRole}
                program={program}
                handleExpand={handleExpandClick(program)}
                handleEdit={handleEditClick(program)}
                handleAddToClasses={handleAddToClasses(program, "programs")}
              />
            ))
        ) : !isLoadingPrograms ? (
          <EmptyView
            userRole={userRole}
            selectedLesson={lessonListToShow}
            selectedData={selectedData}
            setLinkedId={setLinkedId}
            setLinkedLesson={setLinkedLesson}
            headerRef={headerRef}
          />
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <LoadingDots bgColor="bg-neutral-300" />
          </div>
        )}
      </motion.div>

      {/* Courses */}
      <motion.div
        initial={{
          x: "100%",
        }}
        animate={{
          x:
            previousList === "programs"
              ? "100%"
              : lessonListToShow === "courses"
              ? "0%"
              : "-100%",
          opacity: lessonListToShow === "courses" ? 1 : 0,
          transition: {
            duration: 0.5,
          },
        }}
        className="w-full h-full flex-col gap-4 inline-flex absolute top-0 left-0 overflow-y-auto custom-scroll"
      >
        {courses && courses.length > 0 ? (
          courses
            .filter((course) =>
              course.title.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map((course, index) => (
              <CourseCard
                key={index}
                userRole={userRole}
                course={course}
                handleExpand={handleExpandClick(course)}
                handleEdit={handleEditClick(course)}
                handleAddToClasses={handleAddToClasses(course, "courses")}
              />
            ))
        ) : !isLoadingCourses ? (
          <EmptyView
            userRole={userRole}
            selectedLesson={lessonListToShow}
            selectedData={selectedData}
            setLinkedId={setLinkedId}
            setLinkedLesson={setLinkedLesson}
            headerRef={headerRef}
          />
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <LoadingDots bgColor="bg-neutral-300" />
          </div>
        )}
      </motion.div>

      {/* Projects */}
      <motion.div
        initial={{
          x: "100%",
        }}
        animate={{
          x:
            previousList === "modules"
              ? "-100%"
              : lessonListToShow === "projects"
              ? "0%"
              : "100%",
          opacity: lessonListToShow === "projects" ? 1 : 0,
          transition: {
            duration: 0.5,
          },
        }}
        className="w-full h-full flex-col gap-4 inline-flex absolute top-0 left-0 overflow-y-auto custom-scroll"
      >
        {(projects && projects.length > 0) ||
        (selectedData &&
          "quizzes" in selectedData &&
          selectedData.quizzes.length > 0) ? (
          <>
            {projects
              .filter((project) =>
                project.title.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((project, index) => (
                <ProjectCard
                  key={index}
                  userRole={userRole}
                  project={project}
                  handleExpand={handleExpandClick(project)}
                  handleEdit={handleEditClick(project)}
                  handleAddToClasses={handleAddToClasses(project, "projects")}
                />
              ))}
            {selectedData !== undefined && selectedData !== null && (
              <QuizzesList
                userRole={userRole}
                searchQuery={""}
                selectedData={selectedData}
                navRegister={navRegister}
                setShowEditQuiz={setShowEditQuiz}
                setSelectedQuizToEdit={setSelectedQuizToEdit}
                showEditQuiz={showEditQuiz}
              />
            )}
          </>
        ) : !isLoadingProjects ? (
          <EmptyView
            userRole={userRole}
            selectedLesson={lessonListToShow}
            selectedData={selectedData}
            setLinkedId={setLinkedId}
            setLinkedLesson={setLinkedLesson}
            headerRef={headerRef}
          />
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <LoadingDots bgColor="bg-neutral-300" />
          </div>
        )}
      </motion.div>

      {/* Modules */}
      <motion.div
        initial={{
          x: "100%",
        }}
        animate={{
          x: lessonListToShow === "modules" ? "0%" : "100%",
          opacity: lessonListToShow === "modules" ? 1 : 0,
          transition: {
            duration: 0.5,
          },
        }}
        className="w-full h-full flex-col gap-4 inline-flex absolute top-0 left-0 overflow-y-auto custom-scroll"
      >
        {modules && modules.length > 0 ? (
          modules
            .filter((module) =>
              module.title.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map((module, index) => (
              <ModuleCard
                key={index}
                userRole={userRole}
                module={module}
                handleExpand={handleExpandClick(module)}
                handleEdit={handleEditClick(module)}
                handleAdd={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  setSelectedModuleId(module._id);
                  setShowAddToLesson(true);
                }}
                handleAddToClasses={handleAddToClasses(module, "modules")}
              />
            ))
        ) : !isLoadingModules ? (
          <EmptyView
            userRole={userRole}
            selectedLesson={lessonListToShow}
            selectedData={selectedData}
            setLinkedId={setLinkedId}
            setLinkedLesson={setLinkedLesson}
            headerRef={headerRef}
          />
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <LoadingDots bgColor="fill-neutral-450" />
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default AnimatedLessonCards;
