import { MdOutlineDownload, MdClose } from "react-icons/md";
import TabBar from "./TabBar";
import { useContext } from "react";
import { ExternalEditorContext } from "components/main-view/utils/Contexts";

interface InstructionsBarProps {
  selectedTabIndex: number;
  tabOptions: string[];
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>;
  handleDownloadClick: () => void;
  handleCloseClick: () => void;
}

const InstructionsBar: React.FC<InstructionsBarProps> = ({
  selectedTabIndex,
  tabOptions,
  setSelectedTabIndex,
  handleDownloadClick,
  handleCloseClick,
}) => {
  // Get parameter from the context
  const { normalisedRightWidth } = useContext(ExternalEditorContext);

  return (
    <div className="w-full h-fit relative justify-between items-start inline-flex">
      <div
        className={`w-full h-fit${
          normalisedRightWidth === 1 ? "" : " invisible"
        }`}
      >
        {tabOptions && tabOptions.length > 0 && (
          <TabBar
            tabOptions={tabOptions}
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
          />
        )}
      </div>
      <div className="self-stretch inline-flex justify-center items-center gap-2">
        {tabOptions && tabOptions.length > 0 && (
          <div
            className="p-2 bg-neutral-100 hover:bg-neutral-200 ease-in-out duration-150 rounded-full justify-center items-center inline-flex cursor-pointer"
            onClick={handleDownloadClick}
          >
            <div className="w-5 h-5 justify-center items-center flex">
              <MdOutlineDownload className="w-full h-full fill-neutral-700" />
            </div>
          </div>
        )}
        <div
          className="p-2 bg-neutral-100 hover:bg-neutral-200 ease-in-out duration-150 rounded-full justify-center items-center inline-flex cursor-pointer"
          onClick={handleCloseClick}
        >
          <div className="w-5 h-5 justify-center items-center flex">
            <MdClose className="w-full h-full fill-neutral-700" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructionsBar;
