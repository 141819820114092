import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import { StudentResultsType } from "utils/interfaces";
import { getProgressBarColorRaw } from "components/main-view/utils/progressUtils";

interface LeaderboardItemProps {
  student: StudentResultsType;
  status: string;
  showStatus: boolean;
}

const getStatusColor = (status: string): string => {
  return status === "Active" ? "text-success-500" : "text-error-500";
};

const LeaderboardItem: React.FC<LeaderboardItemProps> = ({
  student,
  status,
  showStatus,
}) => {
  // States definition
  const [progress, setProgress] = useState(0);
  const [aptitude, setAptitude] = useState(0);

  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      opacity: 1,
      transition: { duration: 1 },
    });
  }, [controls]);

  /**
   * Get data when starting
   */
  useEffect(() => {
    setProgress(student.courseCompleted);
    setAptitude(student.avgQuizScore);
  }, []);

  return (
    <motion.div
      className="w-full px-8 py-2 bg-Canvas rounded-lg flex items-center justify-between"
      initial={{ opacity: 0 }}
      animate={controls}
    >
      <div className="w-[128px] text-left text-neutral-600 text-sm select-none">{`${student.firstName} ${student.lastName}`}</div>
      <div className="w-fit flex items-center justify-between relative">
        <div className="absolute size-[35px] rounded-full border-[5px] border-neutral-100" />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="w-full relative flex items-center justify-center"
        >
          <CircularProgress
            variant="determinate"
            value={progress}
            size={35}
            thickness={5}
            style={{
              color: getProgressBarColorRaw(progress),
              strokeLinecap: "round",
            }}
          />
          <div className="absolute flex items-center justify-center w-[35px] h-[35px]">
            <div className="text-center text-neutral-600 text-sm select-none">
              {progress}
            </div>
          </div>
        </motion.div>
      </div>
      <div
        className={`w-fit flex items-center ${
          showStatus ? "justify-center" : "justify-end"
        } relative`}
      >
        <div className="absolute size-[35px] rounded-full border-[5px] border-neutral-100" />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="relative flex items-center justify-center"
        >
          <CircularProgress
            variant="determinate"
            value={aptitude}
            size={35}
            thickness={5}
            style={{
              color: getProgressBarColorRaw(aptitude),
              strokeLinecap: "round",
            }}
          />
          <div className="absolute flex items-center justify-center w-[35px] h-[35px]">
            <div className="text-center text-neutral-600 text-sm select-none">
              {aptitude}
            </div>
          </div>
        </motion.div>
      </div>
      {showStatus && (
        <motion.div
          className={`flex-1 text-center ${getStatusColor(
            status
          )} text-xs font-semibold`}
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 1 }}
        >
          {status}
        </motion.div>
      )}
    </motion.div>
  );
};

export default LeaderboardItem;
