import React, { useContext, useEffect, useState } from "react";
import { MdArrowForward, MdCheck, MdOutlineEdit } from "react-icons/md";
import { QuizType } from "utils/interfaces";
import { checkResultComplete } from "services/resultsRequests";
import { BACKEND_URL } from "config/getUrl";
import { LessonsContext } from "components/main-view/utils/Contexts";

interface QuizCardProps {
  userRole: string;
  quiz: QuizType;
  handleExpand: (event: React.MouseEvent) => void;
  handleEdit: (event: React.MouseEvent) => void;
}

const QuizCard: React.FC<QuizCardProps> = ({
  userRole,
  quiz,
  handleExpand,
  handleEdit,
}) => {
  // States definition
  const [isComplete, setIsComplete] = useState();

  // Get the context to prevent caching
  const { version } = useContext(LessonsContext);

  /**
   * Function used to determine if the quiz is completed
   */
  const updateComplete = async () => {
    if (quiz._id && ["student", "parent"].includes(userRole)) {
      setIsComplete(await checkResultComplete("quizzes", quiz._id));
    }
  };

  /**
   * Function that executes update complete
   */
  useEffect(() => {
    updateComplete();
  }, [quiz._id]);

  return (
    <div
      className={`w-full h-[110px] px-6 py-5 bg-neutral-25 rounded-xl justify-between items-center inline-flex hover:shadow-card ease-in-out duration-150 relative cursor-pointer ${
        isComplete ? "border-2 border-success-500" : "border border-neutral-100"
      }`}
      onClick={handleExpand}
    >
      {isComplete && (
        <div className="absolute left-0 top-0 w-6 h-6">
          <div className="relative w-full h-full p-1 rounded-tl-lg rounded-br-xl bg-success-500">
            <MdCheck className="w-full h-full text-white" />
          </div>
        </div>
      )}
      <div className="h-[68px] justify-start items-center gap-3 flex">
        <img
          src={`${BACKEND_URL}/imgs/quizzes/${quiz._id}/badgeImage?v=${version}`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/logo.svg";
          }}
          alt={quiz.title}
          className="w-[68px] h-[68px] rounded-full"
        />
        <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
          <div className="self-stretch text-neutral-700 text-lg font-semibold font-['Poppins'] leading-snug">
            {quiz.title}
          </div>
          <div className="self-stretch text-neutral-600 text-xs font-normal font-['Poppins'] leading-[18px]">
            {quiz.questions ? quiz.questions.length : 0} question
            {(quiz.questions ? quiz.questions.length : 0) > 1 && "s"}
          </div>
        </div>
      </div>
      <div className="flex justify-end items-center gap-5">
        <div className="w-12 self-stretch justify-center items-center gap-3 flex">
          <button
            className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer z-20 hover:bg-neutral-200 ease-in-out duration-300"
            onClick={handleEdit}
          >
            <div className="w-6 h-6">
              <MdOutlineEdit className="w-full h-full fill-neutral-700" />
            </div>
          </button>
        </div>
        <div className="w-fit self-stretch justify-center items-center gap-3 flex">
          <div
            className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer hover:bg-neutral-200 ease-in-out duration-150"
            onClick={handleExpand}
          >
            <div className="w-6 h-6">
              <MdArrowForward className="w-full h-full fill-neutral-700" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizCard;
