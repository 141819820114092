import { Transition } from "@headlessui/react";
import SavedSuccessfulPanel from "components/main-view/utils/pop-up-panels/saved-successful-pop-up/SavedSuccessfulPanel";
import { Fragment } from "react";

interface PopUpSuccessfulProps {
  showPopUp: boolean;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  onCreateAnotherSchool: () => void;
}

const PopUpSuccessful: React.FC<PopUpSuccessfulProps> = ({
  showPopUp,
  setShowPopUp,
  onCreateAnotherSchool,
}) => {
  return (
    <>
      <Transition
        appear={true}
        show={showPopUp}
        as={Fragment}
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 w-full h-full bg-neutral-500 bg-opacity-40 backdrop-blur-[2px] z-10 flex flex-col flex-grow"
          onClick={() => setShowPopUp(false)}
        />
      </Transition>
      <Transition
        appear={true}
        show={showPopUp}
        as={"div"}
        className="fixed w-[440px] h-fit max-h-[90vh] left-0 right-0 ml-auto mr-auto p-6 bottom-5 bg-white rounded-3xl flex-col justify-start items-center gap-10 inline-flex z-30"
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <SavedSuccessfulPanel
          title="School Added Successfully"
          paragraphs={[
            "The school has been successfully updated in the system.",
            "Feel free to dive in to start customizing the school's profile, setting up classes, and inviting staff, students, and parents to join the platform.",
          ]}
          createText="Edit again"
          onCreateAnother={onCreateAnotherSchool}
          onClose={() => setShowPopUp(false)}
        />
      </Transition>
    </>
  );
};

export default PopUpSuccessful;
