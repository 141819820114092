import { motion } from "framer-motion";
import { Editor } from "@monaco-editor/react";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Transition } from "@headlessui/react";
import ButtonBar from "../utils/ButtonBar";
import PopUpDeleteFile from "./PopUpDeleteFile";
import RunCodeButton from "../utils/RunCodeButton";
import { EditorLeftPaneContext } from "components/main-view/utils/Contexts";
import { EditorContext } from "components/main-view/utils/Contexts";
import { MdOutlineCloudDone } from "react-icons/md";

export interface CodeFile {
  name: string;
  content: string;
}

interface CodeEditorPaneType {
  editorPanelRef: React.RefObject<HTMLDivElement>;
  normalisedLeftWidth: number;
  normalisedEditorHeight: number;
  filesState: CodeFile[];
  activeTab: string;
  language: string;
  code: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setFilesState: React.Dispatch<React.SetStateAction<CodeFile[]>>;
  handleAddFile: () => void;
  handleRunCode: () => void;
  handleEditorChange: (value: string | undefined) => void;
  leftRightSizes: number[];
  consoleEditorSizes: number[];
  isResizing: boolean;
}

const CodeEditorPane: React.FC<CodeEditorPaneType> = ({
  editorPanelRef,
  normalisedLeftWidth,
  normalisedEditorHeight,
  filesState,
  activeTab,
  setActiveTab,
  setFilesState,
  handleAddFile,
  handleRunCode,
  language,
  code,
  handleEditorChange,
  leftRightSizes,
  consoleEditorSizes,
  isResizing,
}) => {
  // This value defines the limit of left-right paning where the opacity should be 0
  const opacityLimit = 0.3;

  // States definition
  const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false);
  const [isDeletingFile, setIsDeletingFile] = useState<boolean>(false);
  const [baseButtonPos, setBaseButtonPos] = useState<number[]>([0, 0]);

  // Get the context parameters
  const { codeEditorHeight } = useContext(EditorLeftPaneContext);
  const { pythonJSHeaderOffset } = useContext(EditorContext);

  // Transform console editor sizes into pixels
  const consoleEditorSizesPx = consoleEditorSizes.map(
    (value: number) => (value / 100) * codeEditorHeight
  );

  // Define the runCode elements of pane
  const outputRunCodeButtonInfo = document
    .getElementById("output-run-code")
    ?.getBoundingClientRect();

  let x = outputRunCodeButtonInfo?.x ?? 0;
  let y = outputRunCodeButtonInfo?.y ?? 0;

  /**
   * Get and define the original run code button position
   */
  useEffect(() => {
    // Logic to prevent overwrite this when it's not in the original
    // position. It's necessary to do this because on the first render
    // it's not achieved
    if (normalisedLeftWidth === 1) {
      const mainRunCodeButtonInfo = document
        .getElementById("code-editor-run-code")
        ?.getBoundingClientRect();

      let xOriginal = mainRunCodeButtonInfo?.x ?? 0;
      let yOriginal = mainRunCodeButtonInfo?.y ?? 0;

      setBaseButtonPos([xOriginal, yOriginal]);
    }
  }, [leftRightSizes, consoleEditorSizes]);

  /*
   * handle file renames
   */
  const handleFileRename = useCallback((oldName: string, newName: string) => {
    setFilesState((currentFiles) =>
      currentFiles.map((file) =>
        file.name === oldName ? { ...file, name: newName } : file
      )
    );
    setActiveTab(newName);
  }, []);

  /*
   * handle delete file
   */
  const handleDeleteFile = useCallback(() => {
    // Make a shallow copy of the output
    const outputTabs = [...filesState];
    // Get the index to be deleted
    const indexToBeDeleted = filesState.findIndex(
      (element) => element.name === activeTab
    );

    // Deleting the active tab
    outputTabs.splice(indexToBeDeleted, 1);
    // Define the new active tab at the end of the delete process.
    // If this is the last element, then return just an array with an empty name.
    const newActiveTab = outputTabs[
      indexToBeDeleted - 1 >= 0 ? indexToBeDeleted - 1 : 0
    ] ?? { name: "" };

    // Setting the values
    setFilesState(outputTabs);
    setActiveTab(newActiveTab.name);
    // Close the pop up window
    setIsDeletingFile(false);
    setIsOptionSelected(false);
  }, [activeTab, filesState]);

  /*
   * Function executed when pressing delete button on "..." menu
   */
  const handleDeleteOptionWindow = useCallback(() => {
    setIsDeletingFile(true);
  }, []);

  /*
   * Function executed when pressing download button on "..." menu
   */
  const handleDownloadOptionWindow = useCallback(() => {
    // Create a blob with the data
    const blob = new Blob([code], { type: "text/plain" });

    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);

    // Set the filename
    a.download = activeTab;

    // Create the element, click it and the remove it
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // And finally revoke the URL
    URL.revokeObjectURL(a.href);
  }, [activeTab, code]);

  /*
   * handle pop out the delete message
   */
  const handlePopOutDeleteOption = useCallback(() => {
    setIsOptionSelected(false);
    setIsDeletingFile(false);
  }, []);

  return (
    <div
      className={`flex flex-row w-full h-full rounded-xl ${
        isResizing ? "pointer-events-none" : ""
      }`}
      ref={editorPanelRef}
    >
      <div className="flex-none w-12 h-full mr-auto z-20 text-neutral-700">
        <div className="w-fit h-fit translate-x-[-4.2rem] select-none">
          <motion.div
            className="whitespace-nowrap"
            style={{
              originX: 1,
              originY: 0,
              opacity:
                -0.5 * Math.min(normalisedLeftWidth, normalisedEditorHeight) +
                1,
              rotate: Math.round(
                // The rotation effect of paning down, compensated
                // with the left width pane movement. i.e. if the normalissedLeftWidth
                // is 0 (or closed), we don't want to see the effect of the top-down
                // movement)
                -90 * normalisedEditorHeight * normalisedLeftWidth +
                  // The rotation effect of shrinking the pane when moving left
                  -90 * (1 - normalisedLeftWidth)
              ),
              translateX: Math.round(
                // The effect of shrinking the pane when moving left
                (1 - normalisedLeftWidth) * 31 +
                  // The corrected (because is faded by the left-rigth effect)
                  // effect of shrinking the pane when moving down
                  (1 - normalisedEditorHeight) * 130 * normalisedLeftWidth
              ),
              translateY: Math.round(
                // The effect of shrinking the pane when moving left
                (1 - normalisedLeftWidth) * 15 +
                  // The corrected (because is faded by the left-rigth effect)
                  // effect of shrinking the pane when moving down
                  (1 - normalisedEditorHeight) *
                    (consoleEditorSizesPx[0] / 2 - 14) *
                    normalisedLeftWidth
              ),
              transition: "transform 0.05s ease-in-out",
            }}
          >
            Code editor
          </motion.div>
        </div>
      </div>
      <div className="flex-1 pb-4 pt-0 h-full overflow-hidden rounded-xl bg-Canvas ml-15">
        <motion.div
          className={`flex-1 flex flex-col h-full overflow-hidden ${
            isResizing ? "pointer-events-none" : ""
          }`}
          style={{
            opacity: Math.max(
              Math.min(
                normalisedEditorHeight,
                1 + (1 / (1 - opacityLimit)) * (normalisedLeftWidth - 1)
              ),
              0
            ),
          }}
        >
          <ButtonBar
            id="code-editor"
            text={filesState.map((tab) => tab.name)}
            isOptionSelected={isOptionSelected}
            onSelect={setActiveTab}
            activeTab={activeTab}
            textEditable={true}
            handleRename={handleFileRename}
            setIsOptionSelected={setIsOptionSelected}
            handleDeleteOption={handleDeleteOptionWindow}
            handleDownloadOption={handleDownloadOptionWindow}
            onAdd={handleAddFile}
            handleRunCode={handleRunCode}
            showRunCode={
              normalisedLeftWidth === 1 && normalisedEditorHeight === 1
            }
            addBlurToOptionButtons={true}
          />
          {/* // Create a blur on the background */}
          <Transition
            show={isOptionSelected || isDeletingFile}
            as={Fragment}
            enter="transition-opacity ease-out duration-300"
            enterFrom="opacity-0 translate-y-1/2"
            enterTo="opacity-100 translate-y-0"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="absolute flex inset-0 bg-neutral-500 bg-opacity-20 backdrop-blur-[2px] rounded-xl left-12 z-20"
              onClick={() => setIsOptionSelected(false)}
            ></div>
          </Transition>
          <Transition
            show={isDeletingFile}
            as={Fragment}
            enter="transition ease-out duration-700"
            enterFrom="opacity-0 translate-y-1/2"
            enterTo="opacity-100 translate-y-0"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="absolute flex inset-0 rounded-xl left-12 z-40"
              onClick={handlePopOutDeleteOption}
            >
              <PopUpDeleteFile
                handlePopOutDeleteOption={handlePopOutDeleteOption}
                handleDeleteFile={handleDeleteFile}
              />
            </div>
          </Transition>
          <div className="w-full h-[28px] flex-1 px-4 debug">
            {filesState.length > 0 && (
              <Editor
                defaultLanguage={language}
                language={language}
                value={code}
                onChange={handleEditorChange}
                options={{
                  contextmenu: false,
                  minimap: { enabled: false },
                  scrollbar: {
                    vertical: "auto",
                    horizontal: "auto",
                    verticalScrollbarSize: 8,
                    verticalSliderSize: 8,
                    horizontalScrollbarSize: 8,
                    ignoreHorizontalScrollbarInContentHeight: false,
                  },
                  scrollBeyondLastColumn: 2,
                  scrollBeyondLastLine: false,
                  smoothScrolling: true,
                  fontFamily: "Source Code Pro, monospace",
                  fontSize: 16,
                  lineHeight: 1.8,
                  automaticLayout: true,
                  lineNumbersMinChars: 0,
                  lineDecorationsWidth: 24,
                  folding: false,
                  tabSize: 2,
                  overviewRulerBorder: false,
                }}
                className={`mb-1 ${isResizing ? "pointer-events-none" : ""}`}
              />
            )}
          </div>
        </motion.div>
      </div>
      {/* Button transition out of the flow of the canvas */}
      {((normalisedLeftWidth < 1 &&
        normalisedLeftWidth > 0 &&
        normalisedEditorHeight == 1) ||
        (normalisedEditorHeight < 1 &&
          normalisedEditorHeight > 0 &&
          normalisedLeftWidth == 1)) && (
        <motion.div
          className="fixed top-0 z-[60]"
          style={{
            left:
              normalisedLeftWidth < 1 && normalisedLeftWidth > 0
                ? // The effect of dragging the panes left and right
                  normalisedLeftWidth * baseButtonPos[0] +
                  x * (1 - normalisedLeftWidth)
                : normalisedEditorHeight * baseButtonPos[0] +
                  x * (1 - normalisedEditorHeight),
            top:
              normalisedLeftWidth < 1 && normalisedLeftWidth > 0
                ? normalisedLeftWidth * baseButtonPos[1] +
                  y * (1 - normalisedLeftWidth) -
                  pythonJSHeaderOffset
                : normalisedEditorHeight * baseButtonPos[1] +
                  y * (1 - normalisedEditorHeight) -
                  pythonJSHeaderOffset,
          }}
          transition={{ type: "spring", duration: 0.001 }}
        >
          <RunCodeButton
            showRunCode={true}
            handleRunCode={() => {}}
            prefixId="mobile"
          />
        </motion.div>
      )}
    </div>
  );
};

export default CodeEditorPane;
