import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import AddNewStudent from "./add-new-student-view/AddNewStudent";
import PopUpPanel from "./pop-up-panels/PopUpPanel";
import { getCollectionRequest } from "services/apiRequests";
import {
  ClassListType,
  ContactListType,
  ParentListType,
  SchoolListType,
  StudentListType,
  TeacherListType,
} from "utils/interfaces";
import AnimatedClassesCards from "./AnimatedClassCards";
import { useNavigate } from "react-router-dom";

interface ClassesListProps {
  ref: any;
  userSchool: SchoolListType | null;
  activeClass: string;
  searchQuery: string;
  backPressedToggle: boolean;
  headerRef: React.MutableRefObject<any>;
}

const ClassesList: React.FC<ClassesListProps> = forwardRef(
  (
    { userSchool, activeClass, searchQuery, backPressedToggle, headerRef },
    ref
  ) => {
    // States definition
    /**
     * The general version of "data" had to be changed because
     * it was having race conditions with when rendering, and
     * it was throwing erros when the element was rendering before
     * we get the data.
     */
    const [selectedData, setSelectedData] = useState<
      ClassListType | TeacherListType | ParentListType | StudentListType | null
    >(null);
    const [classes, setClasses] = useState<ClassListType[] | null>(null);
    const [teachers, setTeachers] = useState<TeacherListType[] | null>(null);
    const [students, setStudents] = useState<StudentListType[] | null>(null);
    const [parents, setParents] = useState<ParentListType[] | null>(null);
    const [showSavedSuccessfully, setShowSavedSuccessfully] = useState(false);
    const [createNewStudent, setCreateNewStudent] = useState(false);
    const [showCreateNewClass, setShowCreateNewClass] = useState(false);
    // States for edit pop ups
    const [showEditClass, setShowEditClass] = useState(false);
    const [showEditTeacher, setShowEditTeacher] = useState(false);
    const [showEditParent, setShowEditParent] = useState(false);
    // State for view pop up
    const [selectedTeachers, setSelectedTeachers] = useState<ContactListType[]>(
      []
    );
    const [showTeachersList, setShowTeachersList] = useState(false);
    // Loading states
    const [isLoadingClasses, setIsLoadingClasses] = useState(true);
    const [isLoadingTeachers, setIsLoadingTeachers] = useState(true);
    const [isLoadingStudents, setIsLoadingStudents] = useState(true);
    const [isLoadingParents, setIsLoadingParents] = useState(true);

    // Add student reference definition
    const addStudentRef = useRef(null);

    // Define a navigation hook
    const navigate = useNavigate();

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      setCreateNewStudent(bool: boolean) {
        setCreateNewStudent(bool);
      },
      setShowCreateNewClass(bool: boolean) {
        setShowCreateNewClass(bool);
      },
      handleSaveNewStudent() {
        let currentHandler: any;
        if (addStudentRef.current) currentHandler = addStudentRef.current;
        currentHandler.handleSaveNewStudent();
      },
    }));

    /**
     * Root definition
     */
    useEffect(() => {
      getData();
    }, [activeClass, userSchool]);

    /**
     * Everytime the backPressedToggle signal is activated, we come back to the root view
     */
    useEffect(() => {
      // Once pressed, come back to the root view
      headerRef.current.classes().onDiscard();
      // And set any selectedData to null
      setSelectedData(null);
    }, [backPressedToggle]);

    /**
     * Function applied when we click on cards
     */
    const handleCardClick = (
      card: ClassListType | TeacherListType | ParentListType | StudentListType
    ) => {
      // Select the current data
      setSelectedData(card);

      // Action depending on the shown tab
      if (activeClass === "classes") {
        setShowEditClass(true);
      } else if (activeClass === "staff") {
        setShowEditTeacher(true);
      } else if (activeClass === "students") {
        // Define the type of the variable (for TypeScript)
        const studentCard = card as StudentListType;
        // Show the back button
        headerRef.current.setHeaderBackButton(true);
        // Set the same view as when creating a school
        headerRef.current
          .classes()
          .pressEditStudent(
            "Student",
            `${studentCard.firstName} ${studentCard.lastName}`
          );
      } else if (activeClass === "parents") {
        setShowEditParent(true);
      }
    };

    /**
     * Function to get the data depending on the class
     * @returns data
     */
    const getData = async () => {
      let response: any;
      if (activeClass === "classes") {
        // Set the animation
        setIsLoadingClasses(true);
        // Get the response using this filter
        response = await getCollectionRequest(
          "/api/classes",
          ["_id", "name", "teachers"],
          { organisations: { $all: [userSchool && userSchool._id] }, active: true },
        );
      } else if (activeClass === "staff") {
        // Set the animation
        setIsLoadingTeachers(true);
        // Get the response using this filter
        response = await getCollectionRequest(
          `/api/users/teachers?schoolId=${userSchool && userSchool._id}`
        );
      } else if (activeClass === "students") {
        // Set the animation
        setIsLoadingStudents(true);
        // Get the response using this filter
        response = await getCollectionRequest(
          `/api/users/students?schoolId=${userSchool && userSchool._id}`
        );
      } else if (activeClass === "parents") {
        // Set the animation
        setIsLoadingParents(true);
        // Get the response using this filter
        response = await getCollectionRequest(
          `/api/users/parents?schoolId=${userSchool && userSchool._id}`
        );
      }

      // Check if the response is successful
      if (!response.successful) {
        // Check if it's not successful because of a token expiration
        if (response.logout) {
          // alert(response.message);
          navigate("/")
        }
        return [];
      }

      // Get the data
      const data = response.content;

      // Assign the data to the corresponding element
      if (activeClass === "classes") {
        setIsLoadingClasses(false);
        setClasses(data);
      }
      else if (activeClass === "staff") {
        setIsLoadingTeachers(false);
        setTeachers(data);
      }
      else if (activeClass === "students") {
        setIsLoadingStudents(false);
        setStudents(data);
      }
      else if (activeClass === "parents") {
        setIsLoadingParents(false);
        setParents(data);
      }

      return data;
    };

    /**
     * Function executed after create or update a student
     */
    const afterCreateStudent = () => {
      // Force unselect data
      setSelectedData(null);

      // Show saved successfully pop up
      setShowSavedSuccessfully(true);

      // Click on discard to comeback
      headerRef.current.classes().onDiscard();

      // And refresh the data
      getData();
    }

    /**
     * Function executed when clicking "add another" after creating a student 
     */
    const handleCreateAnotherStudent = () => {
      // Create another student
      headerRef.current.classes().pressCreateNewStudent();

      // And close this pop up
      setShowSavedSuccessfully(false);
    }

    return (
      <div className="w-full h-full flex-col justify-start items-start gap-4 inline-flex overflow-x-hidden overflow-y-auto custom-scroll">
        {!createNewStudent ? (
          <AnimatedClassesCards
            activeClass={activeClass}
            isLoadingClasses={isLoadingClasses}
            isLoadingTeachers={isLoadingTeachers}
            isLoadingStudents={isLoadingStudents}
            isLoadingParents={isLoadingParents}
            classes={classes}
            teachers={teachers}
            students={students}
            parents={parents}
            searchQuery={searchQuery}
            setShowTeachersList={setShowTeachersList}
            setSelectedTeachers={setSelectedTeachers}
            handleCardClick={handleCardClick}
            headerRef={headerRef}
          />
        ) : (
          <AddNewStudent
            ref={addStudentRef}
            selectedData={selectedData as StudentListType}
            userSchool={userSchool}
            afterCreateFunction={afterCreateStudent}
            headerRef={headerRef}
          />
        )}
        {/* Definition of the possible pop up panels in this view */}
        <PopUpPanel
          type="saved-successful"
          showPopUp={showSavedSuccessfully}
          setShowPopUp={setShowSavedSuccessfully}
          onCreateAnother={handleCreateAnotherStudent}
        />
        <PopUpPanel
          type="create-class"
          contextSchool={userSchool}
          showPopUp={showCreateNewClass}
          setShowPopUp={setShowCreateNewClass}
          getData={getData}
          headerRef={headerRef}
        />
        <PopUpPanel
          type="edit-class"
          contextSchool={userSchool}
          selectedData={selectedData as ClassListType}
          showPopUp={showEditClass}
          setShowPopUp={setShowEditClass}
          setSelectedData={setSelectedData}
          getData={getData}
          headerRef={headerRef}
        />
        <PopUpPanel
          type="edit-teacher"
          selectedData={selectedData as TeacherListType}
          showPopUp={showEditTeacher}
          setShowPopUp={setShowEditTeacher}
          setSelectedData={setSelectedData}
          getData={getData}
          headerRef={headerRef}
        />
        <PopUpPanel
          type="edit-parent"
          selectedData={selectedData}
          showPopUp={showEditParent}
          setShowPopUp={setShowEditParent}
          setSelectedData={setSelectedData}
          getData={getData}
          headerRef={headerRef}
        />
        <PopUpPanel
          type="view-class-teachers"
          selectedTeachers={selectedTeachers}
          showPopUp={showTeachersList}
          setShowPopUp={setShowTeachersList}
        />
      </div>
    );
  }
);

export default ClassesList;
