import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import WideChevron from "assets/icons/editor/WideChevronIcon";
import TabButton from "./TabButton";
import RunCodeButton from "./RunCodeButton";
import { MdAdd, MdOutlineDownload } from "react-icons/md";
import OptionsMenu from "./OptionsMenu";
import { EditorContext, PythonJSEditorContext } from "components/main-view/utils/Contexts";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import { fetchPdf } from "services/filesRequests";

interface ButtonBarProps {
  id: string;
  text: string[];
  isOptionSelected?: boolean;
  textEditable?: boolean;
  activeTab?: string;
  onSelect?: (text: string) => void; // return active tab name
  handleRename?: (oldText: string, newText: string) => void;
  setIsOptionSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteOption?: () => void;
  handleDownloadOption?: () => void;
  handleRunCode?: () => void;
  hasAdd?: boolean;
  hasOptions?: boolean;
  onAdd?: () => void;
  negativeTabColor?: boolean;
  showRunCode?: boolean;
  showDownloadPdf?: boolean;
  addBlurToOptionButtons?: boolean;
}

const ButtonBar: React.FC<ButtonBarProps> = ({
  id,
  text,
  isOptionSelected,
  textEditable = false,
  hasAdd = true,
  hasOptions = true,
  negativeTabColor = false,
  showRunCode = false,
  showDownloadPdf = false,
  addBlurToOptionButtons = false,
  activeTab,
  onSelect,
  handleRename,
  setIsOptionSelected,
  handleDeleteOption,
  handleDownloadOption,
  handleRunCode,
  onAdd,
}) => {
  // States definition
  const [activeIndex, setActiveIndex] = useState(0);
  const [chevronStyle, setChevronStyle] = useState<{
    left: number;
    width: number;
  }>({ left: 90, width: 30 });
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [renameSignal, setRenameSignal] = useState<boolean>(false);

  // Refs definition
  const buttonsContainerRef = useRef<HTMLDivElement>(null);
  const startDragX = useRef<number>(0);
  const startScrollLeft = useRef<number>(0);
  const prevTextLengthRef = useRef(text.length);

  // Get context parameters
  const { normalisedLeftWidth, normalisedEditorHeight } = useContext(
    PythonJSEditorContext
  );
  const { pdf } = useContext(EditorContext);

  // Get the url parameters
  const { moduleOrProject, id: lessonId } = useParams();

  /*
   * flag dragging as true and and mark starting position
   */
  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    startDragX.current = e.pageX;
    startScrollLeft.current = buttonsContainerRef.current
      ? buttonsContainerRef.current.scrollLeft
      : 0;
    e.preventDefault(); // Prevent text selection
  }, []);

  /*
   * move scrollbar based on mouse position
   */
  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isDragging || !buttonsContainerRef.current) return;
      const moveX = e.pageX - startDragX.current;
      buttonsContainerRef.current.scrollLeft = startScrollLeft.current - moveX;
    },
    [isDragging]
  );

  /*
   * add window listeners for mousemove and mouseup
   */
  useEffect(() => {
    const el = buttonsContainerRef.current;
    if (!el) return;

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", () => setIsDragging(false));

    return () => {
      // Cleanup to remove event listeners
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", () => setIsDragging(false));
    };
  }, [handleMouseMove]);

  /*
   * scroll horizontal scrollbar with a vertical scroll
   */
  useEffect(() => {
    const el = buttonsContainerRef.current;
    if (el) {
      const onWheel = (e: any) => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, [text, buttonsContainerRef]);

  /*
   * update tab index when activeTab changes and select last tab when new tab has been created
   */
  useEffect(() => {
    const newIndex = activeTab ? text.indexOf(activeTab) : 0;
    setActiveIndex(newIndex !== -1 ? newIndex : 0);
  }, [activeTab, text]);

  /*
   * update position of chevron below active tab
   */
  const updateChevronPosition = useCallback(() => {
    if (buttonsContainerRef.current) {
      const adjustedIndex = hasAdd ? activeIndex + 2 : activeIndex;
      const buttons = buttonsContainerRef.current.children;
      const activeButton = buttons[adjustedIndex] as HTMLDivElement;

      if (activeButton) {
        const { left, width } = activeButton.getBoundingClientRect();
        const containerLeft =
          buttonsContainerRef.current.getBoundingClientRect().left;
        const containerScrollLeft = buttonsContainerRef.current.scrollLeft;
        setChevronStyle({
          left: left - containerLeft + containerScrollLeft + width / 2 - 18,
          width: 30,
        }); // Adjust the position
      }
    }
  }, [activeIndex, hasAdd]);

  /*
   * adjust the chevron's position to align with the active button, responding to layout changes
   */
  useEffect(() => {
    updateChevronPosition();
    const observer = new ResizeObserver(updateChevronPosition);
    if (buttonsContainerRef.current) {
      Array.from(buttonsContainerRef.current.children).forEach((child) =>
        observer.observe(child)
      );
    }
    return () => observer.disconnect();
  }, [updateChevronPosition]);

  /*
   * update the chevron's position on layout changes to ensure proper alignment
   */
  useLayoutEffect(() => {
    updateChevronPosition();
  }, [updateChevronPosition]);

  /*
   * change active index and call onSelect param with tab's text
   */
  const handleTabSelect = useCallback(
    (index: number) => {
      setActiveIndex(index);
      onSelect?.(text[index]);
    },
    [onSelect, text]
  );

  /*
   * handle tab renaming and updates the chevron position accordingly
   */
  const onRename = useCallback(
    (newName: string) => {
      handleRename?.(text[activeIndex], newName);
      updateChevronPosition();
    },
    [handleRename, text, activeIndex, updateChevronPosition]
  );

  /*
   * scroll the button container to its far right end
   */
  const scrollToFarRight = useCallback(() => {
    const scrollable = buttonsContainerRef.current;
    if (scrollable) {
      const maxScrollLeft = scrollable.scrollWidth - scrollable.clientWidth;
      scrollable.scrollTo({
        left: maxScrollLeft,
        behavior: "smooth",
      });
    }
  }, []);

  /*
   * scroll to the far right when new tabs are added
   */
  useEffect(() => {
    if (text.length > prevTextLengthRef.current) {
      scrollToFarRight();
    }
    prevTextLengthRef.current = text.length;
  }, [text, scrollToFarRight]);


  /**
   * Function to execute when clicking the PDF download button
   * @returns
   */
  const handleDownloadPDFFile = () => {
    // If there is no pdf, then prevent to execute this function
    if (!pdf) return;

    fetchPdf(moduleOrProject || "", lessonId || "").then((url: string) => {
      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = url;

      // Set the donwload name
      link.download = pdf;

      // Create the element, click it and the remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // And finally revoke the URL
      URL.revokeObjectURL(url);
    });
  };


  return (
    <div className="relative">
      <div className="flex flex-1 pl-4 relative items-start overflow-visible px-0 pt-5 pb-2 z-10">
        <div
          ref={buttonsContainerRef}
          className="flex items-center overflow-hidden invisible-scroll relative w-fit z-10"
          onMouseDown={handleMouseDown}
          onMouseLeave={() => setIsDragging(false)}
        >
          {hasAdd && (
            <>
              <div
                className="w-10 h-10 p-2 mr-1 mb-2 rounded-full border border-dashed border-neutral-600 hover:bg-neutral-100 ease-in-out duration-150 flex justify-center items-center cursor-pointer"
                title="Add file"
                onClick={onAdd}
              >
                <div className="w-6 h-6 flex justify-center items-center">
                  <MdAdd className="w-6 h-6 text-neutral-600" />
                </div>
              </div>
              <div className="w-px h-10 mx-2.5 flex justify-center items-start">
                <div className="w-px h-8 bg-neutral-200" />
              </div>
            </>
          )}
          {text.map((text, index) => (
            <TabButton
              key={text}
              text={text}
              isActive={index === activeIndex}
              isEditable={textEditable}
              onClick={() => handleTabSelect(index)}
              onRename={onRename}
              negativeTabColor={negativeTabColor}
              renameSignal={renameSignal}
            />
          ))}
          <div className="block">
            <div className="w-36"></div>
          </div>
          {text.length > 0 && (
            <div
              className="absolute bottom-0 transition-all duration-300"
              style={{
                left: `${chevronStyle.left}px`,
                width: `${chevronStyle.width}px`,
              }}
            >
              <WideChevron isLight={negativeTabColor} />
            </div>
          )}
        </div>
      </div>

      <div className="absolute pl-8 flex justify-end left-0 top-0 w-full">
        <div
          className={`flex gap-4 right-0 top-0 overflow-visible pl-5 pt-5 pb-3 z-20 ${
            addBlurToOptionButtons
              ? "bg-transparent bg-opacity-50 backdrop-blur-[2px]"
              : ""
          } ${hasOptions ? "pr-2.5" : "pr-5"}`}
        >
          {showDownloadPdf && (
            <motion.div
              style={{
                x: Math.min(normalisedLeftWidth, normalisedEditorHeight) * 60,
              }}
              transition={{ type: "spring", duration: 0.001 }}
            >
              <div
                className={`w-11 h-11 p-2 bg-neutral-50 hover:bg-neutral-100 ease-in-out duration-150 rounded-full backdrop-blur-[32px] justify-center items-center gap-3 inline-flex cursor-pointer`}
                onClick={handleDownloadPDFFile}
              >
                <div className="w-6 h-6 relative">
                  <MdOutlineDownload className="w-full h-full fill-dc-secondary-600" />
                </div>
              </div>
            </motion.div>
          )}
          <RunCodeButton
            showRunCode={showRunCode}
            handleRunCode={handleRunCode}
            prefixId={id}
          />
        </div>
        {hasOptions && (
          <div
            className={`flex gap-4 right-0 top-0 overflow-visible pl-2.5 pr-5 pt-5 pb-3 z-30 ${
              addBlurToOptionButtons
                ? "bg-transparent bg-opacity-50 backdrop-blur-[2px]"
                : ""
            }`}
          >
            <OptionsMenu
              open={isOptionSelected || false}
              setOpen={setIsOptionSelected ? setIsOptionSelected : () => {}}
              setRenameSignal={setRenameSignal}
              handleDeleteOption={handleDeleteOption}
              handleDownloadOption={handleDownloadOption}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ButtonBar;
