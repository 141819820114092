import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import { throttle } from "lodash";
import { motion } from "framer-motion";
import { useMonaco } from "@monaco-editor/react";
import {
  ImperativePanelGroupHandle,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from "react-resizable-panels";
import { theme } from "components/Editor/python-js-editor/utils/monacoTheme";
import { CodeFile } from "./LeftPane/CodeEditorPane";
import ResizeableSash from "assets/icons/editor/ResizeableSashIcon";
import languageSpecificScript from "./utils/languageSpecificScript";
import RightPane from "./RightPane/RightPane";
import LeftPane from "./LeftPane/LeftPane";
import smoothResize from "components/Editor/utils/smoothResize";
import {
  EditorContext,
  PythonJSEditorContext,
} from "components/main-view/utils/Contexts";
import { upsertCodeRequest } from "services/filesRequests";
import { useParams } from "react-router-dom";
import { getCollectionRequest } from "services/apiRequests";

interface CodeEditorProps {
  files: CodeFile[];
  pdf: string;
  onFileChange: (files: CodeFile[]) => void;
  toShrink: boolean;
  hide: boolean;
}

const TIME_AFK_TO_SAVE = 10000; // ms

const CodeEditor: React.FC<CodeEditorProps> = ({
  files,
  pdf,
  onFileChange,
  toShrink,
  hide,
}) => {
  /**
   * Paramters
   */
  // Width definition
  const codeEditorWidth = window.innerWidth - 40; // Less the separator

  // Panels size limits
  const minLeftPaneWidth = (90 / codeEditorWidth) * 100; // minimum left panel width in px
  const minRightPaneWidth = (90 / codeEditorWidth) * 100; // minimum right panel width in px

  // Animation limits
  const lrScreenPercTrigger = 0.8; // When the "x"% of the left-right pane is achieved, the animation starts

  // Get more parameters
  const { zeroThreshold, pythonJSHeaderOffset } = useContext(EditorContext);

  /**
   * CodeEditor Init
   */
  // States definition
  const [activeTab, setActiveTab] = useState<string>(
    files[0]?.name || "default"
  );
  const [activeOutput, setActiveOutput] = useState<string>("Output");
  const [code, setCode] = useState<string>(
    files[0]?.content || "// Write your code here"
  );
  const [language, setLanguage] = useState<string>("txt");
  const [filesState, setFilesState] = useState<CodeFile[]>(files);
  const [leftRightSizes, setLeftRightSizes] = useState([50, 50]);
  const [srcDoc, setSrcDoc] = useState<string>("");
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [iframeReloadTrigger, setIframeReloadTrigger] = useState(0);
  const [logs, setLogs] = useState<any[]>([]);
  const [normalisedLeftWidth, setNormalisedLeftWidth] = useState<number>(1);
  const [normalisedRightWidth, setNormalisedRightWidth] = useState<number>(1);
  const [normalisedEditorHeight, setNormalisedEditorHeight] = useState(1);
  const [normalisedConsoleHeight, setNormalisedConsoleHeight] = useState(0);
  const [showOutputRunCode, setShowOutputRunCode] = useState(false);
  const [consoleNotification, setConsoleNotification] =
    useState<boolean>(false);
  const [inputConsoleBool, setInputConsoleBool] = useState<boolean>(false);
  const [scaleFactor, setScaleFactor] = useState(1);
  const [scaledRightOffset, setScaledRightOffset] = useState(0);
  const [scaledTopOffset, setScaledTopOffset] = useState(0);
  const [saveCodeState, setSaveCodeState] = useState<
    "saved" | "saving" | "success" | "empty"
  >("saved");

  // References definition
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const consoleBottomRef = useRef<HTMLDivElement>(null);
  const editorPanelRef = useRef<HTMLDivElement>(null);
  const consolePanelRef = useRef<HTMLDivElement>(null);
  const leftPanelRef = useRef<any>(null); // To define the type of this element
  const rightPanelRef = useRef<HTMLDivElement>(null);
  const editorPanesRef = useRef<HTMLDivElement>(null);
  const leftRightLayout = useRef<ImperativePanelGroupHandle>(null);
  // Add a ref to manage the debounce timeout
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  // Get the url parameters
  const { moduleOrProject, id } = useParams();

  useLayoutEffect(() => {
    const handleResize = () => {
      const targetWidth = 180;
      const originalWidth = document.body.clientWidth - 96; // 96 is x padding
      const widthScale = targetWidth / originalWidth;
      const targetHeight = 111;
      const originalHeight = document.body.clientHeight - 155; // 155 is top offset
      const heightScale = targetHeight / originalHeight;
      const rightOffset = (targetWidth - originalWidth * heightScale) / 2;
      const topOffset = (targetHeight - originalHeight * widthScale) / 2;

      if (widthScale < heightScale) {
        setScaleFactor(widthScale);
        setScaledTopOffset(topOffset);
        setScaledRightOffset(0);
      } else {
        setScaleFactor(heightScale);
        setScaledTopOffset(0);
        setScaledRightOffset(rightOffset);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Monaco editor definition
  const monaco = useMonaco();

  // Get the limit pixel of the animation
  const startAnimPx = Math.round((1 - lrScreenPercTrigger) * 100);

  /*
   * throttle console scroll to bottom
   */
  const throttleScroll = throttle(() => {
    // Apply only when left pane is open (otherwise the console opening
    // animation goes wrong), and when we finished moving the pane
    if (normalisedLeftWidth === 1 && !isResizing) {
      consoleBottomRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, 100); // Execute at most once per 100ms

  /**
   * Function used to upload the code previously saved in case the user
   * have some
   */
  useEffect(() => {
    getCollectionRequest("/api/code/python-js", [], {
      itemId: id,
      type: moduleOrProject,
    }).then((response) => {
      // Check if the query went well
      if (!response.successful) return;

      // Get the content and check
      const content = response.content;

      // Check if the content is not void
      if (!(content && content.length > 0)) return;

      // Get the code from the first result
      const code = content[0].code;

      // Check if the code is not void
      if (!(code && code.length > 0)) return;

      // Set the code to the state
      setFilesState(code);
      // And set the current active tab
      setActiveTab(code[0].name)
    });
  }, []);

  /*
   * scroll console to bottom
   */
  useEffect(() => {
    throttleScroll();
    return () => throttleScroll.cancel();
  }, [logs, throttleScroll]);

  /*
   * handle console output from output window
   */
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === "IFRAME_CONSOLE") {
        const { method, args } = event.data;
        const decodedLogs = args
          .map((arg: any[]) =>
            typeof arg === "object" ? JSON.stringify(arg) : arg
          )
          .join(" ");
        // Select the method to use
        if (method === "clear") {
          setLogs([]);
        } else {
          setLogs((logs) => [...logs, { method: method, data: [decodedLogs] }]);
        }
      } else if (event.data.type === "SELECT_INPUT") {
        setInputConsoleBool(true);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  /*
   * catch ctrl + s to run the code
   */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && (event.key === "s" || event.keyCode === 13)) {
        event.preventDefault();
        handleRunCode();
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  /*
   * Run the client code in the output window
   */
  const handleRunCode = useCallback(() => {
    // setLogs([
    //   ...logs,
    //   { method: "log", data: [`>>>>>> Executing ${activeTab}...`] },
    // ]);
    // Save the code
    setSaveCodeState("saving");

    const srcTemplate = `
        ${
          language === "javascript"
            ? '<script src="/p5.min.js"></script>'
            : '<script src="http://ajax.googleapis.com/ajax/libs/jquery/1.9.0/jquery.min.js" type="text/javascript"></script><script src="/skulpt.min.js" type="text/javascript"></script><script src="/skulpt-stdlib.js" type="text/javascript"></script>'
        }
        <script>
          ${languageSpecificScript(code, language)}
        </script>
        <pre id="output"></pre> 
        <!-- If you want turtle graphics include a canvas -->
        <div id="mycanvas"></div>
    `;
    setSrcDoc(srcTemplate);
    setIframeReloadTrigger((prev) => prev + 1);

    // Set active output tab
    setActiveOutput("Output");
  }, [activeTab, code, language, logs]);

  /**
   * Activate notifications only when there are new logs and the console is closed
   */
  useEffect(() => {
    // If it's closed and we run the code, then show a notification
    if (leftPanelRef.current?.isConsoleClosed()) {
      setConsoleNotification(true);
    }
  }, [logs]);

  /*
   * set monaco theme, stop f1 keybinding, and add ctrl+enter to run code
   */
  useEffect(() => {
    if (monaco) {
      monaco.editor.remeasureFonts();
      monaco.editor.defineTheme("dc", theme);
      monaco.editor.setTheme("dc");
      monaco.editor.addEditorAction({
        id: "stopF1",
        label: "Stop the F1 panel from appearing",
        keybindings: [monaco.KeyCode.F1],
        contextMenuGroupId: "2_execution",
        run: () => {},
      });
      monaco.editor.addEditorAction({
        id: "run-code",
        label: "Run Code",
        contextMenuOrder: 2,
        contextMenuGroupId: "2_execution",
        keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter],
        run: handleRunCode,
      });
    }
  }, [handleRunCode, monaco]);

  /**
   * Function used to control the logic and the animation of the auto save code icon
   */
  useEffect(() => {
    if (saveCodeState === "success") {
      // Make sure that the signal will clear the keyboard counting
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      // Starting the timer
      const timer = setTimeout(() => {
        // Move the state to saved after 3 seconds
        setSaveCodeState("saved");
      }, 3000);
      return () => clearTimeout(timer);
    } else if (saveCodeState === "saving") {
      // Get the data object
      const codeData = {
        itemId: id,
        type: moduleOrProject,
        code: filesState,
      };

      // Send the request to save the code
      upsertCodeRequest(codeData, "python-js").then(() => {
        // Leave the animation 1 second after the response is received
        // for a smoother animation of this state
        const timer = setTimeout(() => {
          // Move the state to saved after 1 second
          setSaveCodeState("success");
        }, 1000);
        return () => clearTimeout(timer);
      });
    }
  }, [saveCodeState]);

  /*
   * handle switching code files when new tab selected
   */
  useEffect(() => {
    const file = filesState.find((file) => file.name === activeTab);
    setCode(file ? file.content : "");
    setLanguage(
      activeTab && activeTab.endsWith(".js") // To avoid activeTab undefined
        ? "javascript"
        : activeTab && activeTab.endsWith(".py")
        ? "python"
        : "txt"
    );
  }, [activeTab, filesState]);

  /*
   * handle code changes in monaco editor
   */
  const handleEditorChange = useCallback(
    (value: string | undefined) => {
      // Define as empty if there's no code
      if (!value) setSaveCodeState("empty");

      // If there is code 
      setFilesState((prevFiles) => {
        const newFiles = prevFiles.map((file) =>
          file.name === activeTab ? { ...file, content: value || "" } : file
        );
        onFileChange(newFiles);
        return newFiles;
      });
      setCode(value || "");


      // Debounce logic: After the user finishes writing,
      // this timer will start counting until TIME_AFK_TO_SAVE ms
      // to send automatically the signal for saving.
      // ** ONLY APPLY WHEN THERE IS CODE **
      if (value) {
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
          setSaveCodeState("saving");
        }, TIME_AFK_TO_SAVE);
      }
    },
    [activeTab]
  );

  /*
   * Handle adding a new file
   */
  const handleAddFile = useCallback(() => {
    const extension = activeTab.endsWith(".js")
      ? ".js"
      : activeTab.endsWith(".py")
      ? ".py"
      : ".txt";
    const newFileName = `file${filesState.length + 1}` + extension;
    const code =
      extension === ".js"
        ? `// ${newFileName}`
        : extension === ".py"
        ? `# ${newFileName}`
        : newFileName;

    setFilesState((prevFiles) => [
      ...prevFiles,
      { name: newFileName, content: code },
    ]);

    setActiveTab(newFileName);
  }, [activeTab, filesState.length]);

  /*
   * Calculate normalised left and right width for close animations
   */
  useEffect(() => {
    // Calculate the normalised values
    let normLeftWidth = Math.min(
      Math.max((leftRightSizes[0] - minLeftPaneWidth) / startAnimPx, 0),
      1
    );
    let normRightWidth = Math.min(
      Math.max((leftRightSizes[1] - minRightPaneWidth) / startAnimPx, 0),
      1
    );

    // Make sure that under a threshold, the numbers are zero
    normLeftWidth = normLeftWidth < zeroThreshold ? 0 : normLeftWidth;
    normRightWidth = normRightWidth < zeroThreshold ? 0 : normRightWidth;

    // Set the states
    setNormalisedLeftWidth(normLeftWidth);
    setNormalisedRightWidth(normRightWidth);
  }, [leftRightSizes]);

  /**
   * Set automatically the pane sizes when the editor is reaching the limit
   */
  const handlePanesResizeWidthEnd = useCallback(() => {
    // Aconditionate the numbers
    // 1. To correct from the bias of taking the current values
    // 2. To not exceed the limits
    const leftPaneWidth = Math.max(minLeftPaneWidth, leftRightSizes[0]);
    const rightPaneWidth = Math.max(minRightPaneWidth, leftRightSizes[1]);

    // Create the current size array for the smoothResize function
    const currentSizes = [leftPaneWidth, rightPaneWidth];

    // Left pane logic
    if (
      leftPaneWidth < minLeftPaneWidth + startAnimPx &&
      leftPaneWidth !== minLeftPaneWidth
    ) {
      smoothResize(
        [minLeftPaneWidth, leftPaneWidth + rightPaneWidth - minLeftPaneWidth],
        currentSizes,
        leftRightLayout,
        100
      );
    }

    // Right pane logic
    if (
      rightPaneWidth < minRightPaneWidth + startAnimPx &&
      rightPaneWidth !== minRightPaneWidth
    ) {
      smoothResize(
        [leftPaneWidth + rightPaneWidth - minRightPaneWidth, minRightPaneWidth],
        currentSizes,
        leftRightLayout,
        100
      );
    }
  }, [leftRightSizes, smoothResize]);

  /**
   * Maximise automatically the right pane when the active output is selected
   */
  useEffect(() => {
    if (activeOutput === "Instructions") {
      // Aconditionate the numbers
      // 1. To correct from the bias of taking the current values
      // 2. To not exceed the limits
      const leftPaneWidth = Math.max(minLeftPaneWidth, leftRightSizes[0]);
      const rightPaneWidth = Math.max(minRightPaneWidth, leftRightSizes[1]);

      // Create the current size array for the smoothResize function
      const currentSizes = [leftPaneWidth, rightPaneWidth];

      // Maximize Right pane logic
      smoothResize(
        [minLeftPaneWidth, leftPaneWidth + rightPaneWidth - minLeftPaneWidth],
        currentSizes,
        leftRightLayout,
        100
      );
    }
  }, [activeOutput]);

  return (
    <PythonJSEditorContext.Provider
      value={{
        saveCodeState,
        normalisedLeftWidth,
        normalisedRightWidth,
        normalisedEditorHeight,
        setNormalisedEditorHeight,
        normalisedConsoleHeight,
        setNormalisedConsoleHeight,
      }}
    >
      <motion.div
        className={`w-screen h-[calc(100vh-123px)] overflow-hidden absolute top-0 ${
          toShrink || hide ? "-z-10" : "z-10"
        }`}
        initial={{
          marginTop: pythonJSHeaderOffset,
        }}
        animate={{
          marginTop: toShrink ? 0 : pythonJSHeaderOffset,
        }}
      >
        <motion.div
          className="h-[calc(100vh-123px)] w-full absolute right-0 origin-top-right"
          ref={editorPanesRef}
          initial={{
            height: "calc(100vh-123px)",
            transform: "translateX(0%) scale(1)",
            top: 0,
            right: 0,
            opacity: 1,
          }}
          animate={{
            transform: toShrink
              ? `translateX(0%) scale(${scaleFactor * 0.98})`
              : `translateX(0%) scale(1)`,
            top: hide ? "100%" : toShrink ? 24 + scaledTopOffset : 0,
            right: toShrink ? 48 + scaledRightOffset : 0,
            opacity: hide ? 0 : 1,
            transition: { ease: "linear" },
          }}
        >
          <PanelGroup
            ref={leftRightLayout}
            direction="horizontal"
            className="relative pb-8"
            onLayout={(sizes) => setLeftRightSizes(sizes)}
          >
            <Panel
              minSize={minLeftPaneWidth}
              className="w-full h-full pt-8 overflow-visible"
            >
              <LeftPane
                filesState={filesState}
                activeTab={activeTab}
                language={language}
                code={code}
                logs={logs}
                consoleNotification={consoleNotification}
                editorPanelRef={editorPanelRef}
                consolePanelRef={consolePanelRef}
                consoleBottomRef={consoleBottomRef}
                handleAddFile={handleAddFile}
                handleRunCode={handleRunCode}
                handleEditorChange={handleEditorChange}
                setActiveTab={setActiveTab}
                setFilesState={setFilesState}
                setConsoleNotification={setConsoleNotification}
                setLogs={setLogs}
                ref={leftPanelRef}
                leftRightSizes={leftRightSizes}
                inputConsoleBool={inputConsoleBool}
                setInputConsoleBool={setInputConsoleBool}
                setShowOutputRunCode={setShowOutputRunCode}
                isResizing={isResizing}
                setIsResizing={setIsResizing}
              />
            </Panel>
            <PanelResizeHandle
              onDragging={(isDragging: boolean) => {
                if (isDragging) setIsResizing(true);
                else {
                  setIsResizing(false);
                  handlePanesResizeWidthEnd();
                }
              }}
            >
              <div className="inline-flex h-full items-center pt-8">
                <ResizeableSash />
              </div>
            </PanelResizeHandle>
            <Panel
              minSize={minRightPaneWidth}
              className="w-full h-full pt-8 overflow-visible"
            >
              <RightPane
                showOutputRunCode={showOutputRunCode}
                setActiveOutput={setActiveOutput}
                activeOutput={activeOutput}
                srcDoc={srcDoc}
                iframeRef={iframeRef}
                pdf={pdf}
                iframeReloadTrigger={iframeReloadTrigger}
                handleRunCode={handleRunCode}
                isResizing={isResizing}
                rightPanelRef={rightPanelRef}
              />
            </Panel>
          </PanelGroup>
        </motion.div>
      </motion.div>
    </PythonJSEditorContext.Provider>
  );
};

export default CodeEditor;
