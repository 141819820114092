import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { LoadingDots } from "utils/loadingAnimation";
import { resetPasswordRequest } from "services/authRequests";
import { AnimatePresence, motion } from "framer-motion";
import { MdOutlineArrowCircleLeft, MdOutlinePerson } from "react-icons/md";
import LandingPageBackground from "components/login/LandingPageBackground";
import MainWindow from "components/login/MainWindow";

const ResetPassword: React.FC = () => {
  // States definition
  const [errorMessage, setErrorMessage] = useState("");
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  const [requestSent, setRequestSent] = useState<boolean>(false);
  const [email, setEmail] = useState("");

  // Reference to email input
  const emailInputRef = useRef<HTMLInputElement>(null);

  // Function for submitting
  const handleEmailSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    // Prevent default behaviour
    event.preventDefault();

    // Reset the error message
    setErrorMessage("");

    // Check if the email is not empty
    if (!validateEmailExternally()) {
      setErrorMessage("Please enter a valid email address");
      return;
    }

    // Check if it's currently running the function to avoid over running this
    if (resetLoading) return;

    // Set animation state
    setResetLoading(true);

    // Send the reset password information for checking
    const response: { successful: boolean; message: string } =
      await resetPasswordRequest({ email });

    // Stop the loading animation
    setResetLoading(false);

    // If the response is successful
    if (response.successful) {
      // Then go to the home page
      setErrorMessage("");
      // Show the successful view
      setRequestSent(true);
    } else {
      // Display an error message
      setErrorMessage(response.message);
    }
  };

  /**
   * Function used to validate the email by something different than an event
   */
  const validateEmailExternally = () => {
    if (emailInputRef.current) {
      return (
        emailInputRef.current.value && emailInputRef.current.checkValidity()
      );
    }

    return false;
  };

  /**
   * Function used to validate the current email
   */
  const validateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Check if the form is valid using HTML5 checkValidity
    // Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#validation
    const emailValidation =
      !!event.target.value && event.target.checkValidity();

    // Update the error message
    setErrorMessage(
      emailValidation ? "" : "Please enter a valid email address"
    );
  };

  return (
    <LandingPageBackground>
      <MainWindow>
        <>
          <div className={`h-fit flex-col justify-center items-center ${requestSent ? "gap-8" : "gap-14"} inline-flex`}>
            {!requestSent && (
              <div className="self-stretch h-fit flex-col justify-start items-start gap-8 flex">
                <div className="self-stretch h-fit flex-col justify-start items-start gap-6 flex">
                  <div className="self-stretch justify-start items-center gap-2 inline-flex">
                    <div className="grow shrink basis-0 text-neutral-700 text-[33px] font-semibold font-sans leading-[34px]">
                      Reset your password
                    </div>
                  </div>
                  <div className="self-stretch h-fit flex-col justify-start items-start gap-3 flex">
                    <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[21px]">
                      Enter the email address you use to log in to Deadly
                      Coders.
                    </div>
                    <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[21px]">
                      A password reset email will be sent to that address.
                    </div>
                  </div>
                </div>
                <form
                  noValidate
                  onSubmit={handleEmailSubmit}
                  className="self-stretch h-fit flex-col justify-start items-start gap-6 flex"
                >
                  <div id="reset-password-form" className="w-full">
                    <div className="self-stretch h-[71px] flex-col justify-start items-start gap-1.5 flex">
                      <div className="rounded justify-start items-start gap-2 inline-flex">
                        <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide select-none">
                          Email
                        </div>
                      </div>
                      <div className="w-full h-full rounded-lg bg-neutral-50 text-neutral-700 placeholder:text-neutral-300 text-base font-normal font-sans leading-[19px] flex items-center relative">
                        <div className="absolute left-3.5 w-5 h-5 z-20">
                          <MdOutlinePerson className="w-full h-full text-neutral-500" />
                        </div>
                        <input
                          ref={emailInputRef}
                          id="username"
                          type="email"
                          onBlur={validateEmail}
                          onChange={(event) => setEmail(event.target.value)}
                          className="peer w-full h-full pl-[42px] rounded-lg bg-transparent text-neutral-700 placeholder:text-neutral-300 placeholder:select-none focus:outline-none text-base font-normal font-sans leading-[19px] z-10"
                          placeholder="Enter your email address"
                          autoComplete="email"
                        />
                      </div>
                    </div>
                    {/* Error message */}
                    <AnimatePresence mode="wait" initial={false}>
                      {errorMessage && (
                        <motion.div
                          className="w-full py-1 text-error-600 text-sm font-normal font-sans leading-[17px] select-none"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          {errorMessage}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                  <button
                    type="submit"
                    className={`self-stretch px-5 py-3 bg-dc-secondary-600 ${
                      resetLoading
                        ? ""
                        : "hover:bg-dc-secondary-700 cursor-pointer "
                    }duration-150 ease-in-out rounded justify-center items-center gap-2 inline-flex`}
                  >
                    <div className="text-white text-lg font-semibold font-sans leading-snug select-none">
                      {resetLoading ? <LoadingDots /> : "Reset password"}
                    </div>
                  </button>
                </form>
              </div>
            )}
            {requestSent && (
              <div className="h-fit flex-col justify-start items-start gap-6 inline-flex">
                <div className="self-stretch justify-start items-center gap-2 inline-flex">
                  <div className="grow shrink basis-0 text-[#353e47] text-[33px] font-semibold font-sans leading-[34px]">
                    Reset link sent
                  </div>
                </div>
                <div className="self-stretch p-6 bg-neutral-50 rounded-2xl justify-start items-center gap-6 inline-flex">
                  <div className="grow shrink basis-0">
                    <span className="text-neutral-700 text-base font-normal font-sans leading-7">
                      If an account with{" "}
                    </span>
                    <span className="text-neutral-700 text-base font-semibold font-sans leading-7">
                      {email}
                    </span>
                    <span className="text-neutral-700 text-base font-normal font-sans leading-7">
                      {" "}
                      exists, you will receive an email with a link to reset
                      your password. Please check your spam folder if the email
                      doesn't appear within a few minutes.
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="self-stretch h-fit flex-col justify-start items-center gap-6 flex">
              <Link
                to="/"
                className={`group w-fit rounded justify-center items-center gap-2 inline-flex ${
                  resetLoading ? "pointer-events-none" : "cursor-pointer"
                }`}
              >
                <div className="w-6 h-6 relative">
                  <MdOutlineArrowCircleLeft
                    className={`w-full h-full ${
                      resetLoading
                        ? "fill-neutral-300"
                        : "fill-dc-secondary-600 group-hover:fill-dc-secondary-700"
                    }`}
                  />
                </div>
                <div
                  className={`${
                    resetLoading
                      ? "text-neutral-300"
                      : "text-dc-secondary-600 group-hover:text-dc-secondary-700"
                  } text-lg font-semibold font-sans leading-snug select-none`}
                >
                  Back to login
                </div>
              </Link>
              <Link to="mailto:codeflix@jnrengineers.com">
              <div className="h-fit rounded justify-center items-center gap-2 inline-flex">
                <div className="text-dc-accent-700 hover:text-dc-accent-800 text-sm font-semibold font-sans underline leading-[17px] select-none">
                  Email support
                </div>
              </div>
            </Link>
            </div>
          </div>
        </>
      </MainWindow>
    </LandingPageBackground>
  );
};

export default ResetPassword;
