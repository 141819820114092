import React from "react";
import {
  MdFastForward,
  MdOutlineDarkMode,
  MdOutlineLightMode,
  MdRestore,
} from "react-icons/md";

interface ScratchEditPopupProps {
  theme: "default" | "dark";
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  handleRestore: () => void;
  handleTurbo: () => void;
  handleChangeTheme: () => void;
}

const ScratchEditPopup: React.FC<ScratchEditPopupProps> = ({
  theme,
  setOpen,
  handleRestore,
  handleTurbo,
  handleChangeTheme,
}) => {
  return (
    <div className="absolute w-[170px] top-0 right-2 p-3 -mt-3 bg-white rounded-lg shadow-card flex-col justify-center items-center gap-2 inline-flex cursor-pointer z-30">
      <div
        className="w-[154px] p-3 bg-white rounded-lg justify-start items-center gap-2 inline-flex text-neutral-700 hover:bg-neutral-700 hover:text-white"
        onClick={() => {
          setOpen?.(false);
          handleRestore();
        }}
      >
        <div className="w-6 h-6 relative fill-neutral-700">
          <MdRestore className="w-full h-full" />
        </div>
        <div className="text-base font-normal font-sans leading-7">Restore</div>
      </div>
      <div
        className="w-[154px] p-3 bg-white rounded-lg justify-start items-center gap-2 inline-flex text-neutral-700 hover:bg-neutral-700 hover:text-white"
        onClick={() => {
          setOpen?.(false);
          handleTurbo();
        }}
      >
        <div className="w-6 h-6 relative fill-neutral-700">
          <MdFastForward className="w-full h-full" />
        </div>
        <div className="text-base font-normal font-sans leading-7">
          Turbo Mode
        </div>
      </div>
      <div
        className="w-[154px] p-3 bg-white rounded-lg justify-start items-center gap-2 inline-flex text-neutral-700 hover:bg-neutral-700 hover:text-white"
        onClick={() => {
          setOpen?.(false);
          handleChangeTheme();
        }}
      >
        <div className="w-6 h-6 relative fill-neutral-700">
          {theme === "default" ? (
            <MdOutlineDarkMode className="w-full h-full" />
          ) : (
            <MdOutlineLightMode className="w-full h-full" />
          )}
        </div>
        <div className="text-base font-normal font-sans leading-7">
          {theme === "default" ? "Dark" : "Light"} Mode
        </div>
      </div>
    </div>
  );
};

export default ScratchEditPopup;
