import { useContext, useEffect, useRef, useState } from "react";
import Video from "components/Editor/utils/Video";
import Player from "@vimeo/player";
import { ExternalEditorContext } from "components/main-view/utils/Contexts";

interface LeftPaneProps {
  leftRightSizes: number[];
  codeEditorWidth: number;
  video: string;
  setMarkCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeftPane: React.FC<LeftPaneProps> = ({
  leftRightSizes,
  codeEditorWidth,
  video,
  setMarkCompleted
}) => {
  // States definition
  const [width, setWidth] = useState(
    (leftRightSizes[0] / 100) * codeEditorWidth - 40
  );
  const [displayBg, setDisplayBg] = useState(true);

  // Get parameters from context
  const { videoPercentageThreshold } = useContext(ExternalEditorContext);

  // Define a reference for the video iframe
  const videoIframeRef = useRef<HTMLIFrameElement>(null);
  // Define a reference for the container element
  const divRef = useRef<HTMLDivElement>(null);

  /**
   * Function to define the
   */
  useEffect(() => {
    // Define the new width
    const newWidth = (leftRightSizes[0] / 100) * codeEditorWidth - 40;
    // Set the new width
    setWidth(newWidth);

    // Check if the video height is going beyond
    if (divRef.current?.clientHeight) {
      // Define the container height
      const containerHeight = divRef.current?.clientHeight;

      // Define the intended height
      const height = (width * 9) / 16;

      if (height > containerHeight) setDisplayBg(true);
      else setDisplayBg(false);
    }
  }, [leftRightSizes, codeEditorWidth]);

  /**
   * Function used to triggers the video completion
   */
  useEffect(() => {
    if (videoIframeRef.current) {
      // Define the player element
      const player = new Player(videoIframeRef.current);

      // Get the duration of the video
      let duration = Infinity;
      player.getDuration().then((s => duration = s));

      // Function to apply on the listener
      const handleTimeUpdate = ({ seconds }: {seconds: number}) => {
        if (seconds >= Math.floor(videoPercentageThreshold * duration)) {
          // Mark as completed when done
          setMarkCompleted(true);
        }
      };

      // Add the timeupdate event listener
      player.on("timeupdate", handleTimeUpdate);

      // Cleanup event listener when component unmounts
      return () => {
        player.off("timeupdate", handleTimeUpdate);
      };
    }
  }, []);

  return (
    <div className="flex w-full h-full">
      <div className="flex-none w-10 h-full" />
      <div
        ref={divRef}
        className={`w-full ${
          displayBg ? "bg-neutral-50 " : ""
        }rounded-xl h-full flex justify-center items-center`}
      >
        <Video
          iframeRef={videoIframeRef}
          width={width}
          className="w-fit h-fit rounded-xl overflow-hidden"
          isOpen={true}
          videoUrl={video}
          maxWidth={divRef.current?.clientWidth}
          maxHeight={divRef.current?.clientHeight}
        />
      </div>
    </div>
  );
};

export default LeftPane;
