import { ChangeEvent, useState } from "react";
import { MdOutlineBackup } from "react-icons/md";
import { BACKEND_URL } from "config/getUrl";

interface InputBadgeImageProps {
  text: string;
  badgeImage: string | null;
  maxMB?: number;
  setBadgeImage: React.Dispatch<React.SetStateAction<string | null>>;
  id?: string;
}

const InputBadgeImage: React.FC<InputBadgeImageProps> = ({
  text,
  badgeImage,
  maxMB = 4,
  setBadgeImage,
  id="badge-image-upload"
}) => {
  // Parameters definition
  const formatsAllowed = ["image/png", "image/jpeg"];
  const maxFileSize = maxMB * 1024 * 1024; // 4 MB

  // States definition
  const [isDragging, setIsDragging] = useState(false);

  /**
   * Function used on drop the area
   * @param event
   */
  const handleDrop = (event: React.DragEvent) => {
    // Prevent opening the file on another tab
    event.preventDefault();

    // Set dragging state to false
    setIsDragging(false);

    // Get the list of files dropped
    const droppedFiles = event.dataTransfer.files;

    // Check if the drop contain files
    if (droppedFiles.length > 0) {
      // Transform into an array
      const newFiles = Array.from(droppedFiles);

      // And use just the first file
      processImageUpload(newFiles[0]);
    }
  };

  /**
   * Function to handle the image upload
   * @param event
   */
  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    // Get the file
    const file = event.target.files?.[0];

    // Process the file
    processImageUpload(file);
  };

  /**
   * Function to process the image upload
   * @param event
   */
  const processImageUpload = (file: File | undefined) => {
    // Check if there is an inputted file
    if (!file) {
      alert("No file was selected.");
      return;
    }

    // Check if the of the uploaded file is the defined in the
    // formats list allowed for this placeholder
    if (!formatsAllowed.includes(file.type)) {
      alert(
        `Format "${file.type}" is not allowed. Please try with another file.`
      );
      return;
    }

    // Check that the size is the allowed one
    if (file.size > maxFileSize) {
      const sizeInMb = (file.size / 1024 / 1024).toFixed(2);
      alert(`File size (${sizeInMb} MB) is larger than allowed.`);
      return;
    }

    // Perform the loading
    const reader = new FileReader();
    reader.onloadend = () => {
      setBadgeImage(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  /**
   * Function used to control drag and drop behaviour when leaving
   * @param event
   */
  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  /**
   * Function used to control drag and drop behaviour when leaving
   * @param event
   */
  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  /**
   * Function used to control drag and drop behaviour when entering
   * @param event
   */
  const handleDragEnter = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  /**
   * For removing badge image
   */
  const removeImage = () => {
    setBadgeImage("");
  };

  return (
    <div className="self-stretch h-fit flex-col justify-center items-center gap-2 flex">
      <div
        className={`group w-[109px] h-[109px] rounded-full border ${
          isDragging
            ? "border-dc-secondary-500 border-solid"
            : "border-neutral-300 border-dashed"
        } flex-col justify-center items-center gap-2 flex relative`}
      >
        {badgeImage ? (
          <img
            src={
              badgeImage.startsWith("data:image/")
                ? badgeImage
                : `${BACKEND_URL}${badgeImage}?v=${new Date()}`
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              setBadgeImage(null);
            }}
            alt="Badge"
            className="w-full h-full object-cover rounded-full"
          />
        ) : (
          <div
            className="self-stretch h-full flex flex-col justify-center items-center"
            onDrop={handleDrop}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
          >
            <div className="w-10 h-10 relative group-hover:size-14 ease-in-out duration-300">
              <MdOutlineBackup className="w-full h-full fill-neutral-300" />
            </div>
            <input
              type="file"
              accept={formatsAllowed.join(", ")}
              onChange={handleImageUpload}
              className="hidden"
              id={id}
            />
            <label
              htmlFor={id}
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            />
          </div>
        )}
      </div>
      {badgeImage ? (
        <button
          onClick={removeImage}
          className="text-error-500 hover:underline text-xs font-normal font-sans mt-2"
        >
          Remove Image
        </button>
      ) : (
        <div className="self-stretch h-7 flex-col justify-center items-center gap-0.5 flex">
          <div className="self-stretch justify-start items-center gap-2 inline-flex">
            <div className="grow shrink basis-0 text-center text-neutral-450 text-xs font-normal font-sans leading-[14px]">
              {text}
            </div>
          </div>
          <div className="self-stretch justify-start items-center gap-2 inline-flex">
            <div className="grow shrink basis-0 text-center text-neutral-450 text-[10px] font-sans leading-3 tracking-tight">
              <span className="font-normal">(</span>
              <span className="font-semibold">File types</span>
              <span className="font-normal">: PNG, JPEG, </span>
              <span className="font-semibold">Max. file size</span>
              <span className="font-normal">: {maxMB} MB)</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InputBadgeImage;
