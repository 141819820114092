import { AnimatePresence, motion } from "framer-motion";
import { GoArrowLeft } from "react-icons/go";

interface AnimatedBackButtonProps {
  show: boolean;
  width?: number;
  onClick: () => void;
}

/**
 * Function used to animate when rendering back button
 * @param show Boolean that indicates if the back button should be shown.
 * @returns Animated back button.
 */
const AnimatedBackButton: React.FC<AnimatedBackButtonProps> = ({
  show,
  width = 48,
  onClick,
}) => {
  return (
    <AnimatePresence initial={false}>
      {show && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex cursor-pointer absolute hover:bg-neutral-100 ease-in-out duration-150"
            onClick={onClick}
          >
            <div className="w-6 h-6 justify-center items-center flex relative">
              <div className="w-6 h-6 relative">
                <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: width }}
            exit={{ width: 0 }}
            className=""
          />
        </>
      )}
    </AnimatePresence>
  );
};

export default AnimatedBackButton;
