import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { motion } from "framer-motion";

type ButtonClick = {
  name: string;
  onClick: () => void;
};

interface FancyTabSwapProps {
  buttons: ButtonClick[];
  ref?: any;
}

const FancyTabSwap: React.FC<FancyTabSwapProps> = forwardRef(
  ({ buttons }, ref) => {
    const refs = useRef<(HTMLDivElement | null)[]>([]);
    const [pillWidth, setPillWidth] = useState(79);
    const [pillOffset, setPillOffset] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0);

    /*
     * Functions to externalise using refs
     */
    useImperativeHandle(ref, () => ({
      // Function to present all the gathered data in the form
      updatePillPosition(index: number) {
        updatePillPosition(index);
      },
    }));

    useEffect(() => {
      if (buttons.length > 0) {
        updatePillPosition(0);
      }
    }, []);

    const handleClick = (index: number) => {
      buttons[index]?.onClick();
      updatePillPosition(index);
      setSelectedIndex(index);
    };

    const updatePillPosition = (index: number) => {
      const currentButton = refs.current[index];
      if (currentButton) {
        setPillWidth(currentButton.clientWidth);
        setPillOffset(currentButton.offsetLeft);
      }
    };

    return (
      <div className="flex items-center h-[41px] relative bg-Canvas rounded-full px-2 gap-1">
        <motion.div
          className="absolute bg-white h-[29px] top-[6px] rounded-full"
          initial={{ width: pillWidth, left: pillOffset }}
          animate={{ width: pillWidth, left: pillOffset }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        />
        {buttons.map((button, index) => (
          <div
            key={index}
            ref={(el) => (refs.current[index] = el)}
            className={`flex-1 h-[29px] rounded-full flex items-center justify-center cursor-pointer z-10 ${
              selectedIndex === index ? "" : "hover:bg-neutral-100"
            } ease-in-out duration-150`}
            onClick={() => handleClick(index)}
          >
            <span className="text-dc-secondary-600 text-sm font-semibold font-sans leading-[17px] m-3">
              {button.name}
            </span>
          </div>
        ))}
      </div>
    );
  }
);

export default FancyTabSwap;
