import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import { AnimatePresence, motion } from "framer-motion";
import { MdCallReceived } from "react-icons/md";
import { AssistantAllButtonTypes, CodeFile } from "utils/interfaces";
import { fetchVideoData } from "services/filesRequests";
import { platform } from "config/platform";
import Video from "components/Editor/utils/Video";
import Player from "@vimeo/player";
import AssistantPopup from "components/Editor/assistant/AssistantPopup";

interface CodeEditorHeaderProps {
  name: string;
  video: string;
  AIAssistant: "littleC" | "jakita";
  activeItem: "editor" | "assistant";
  setActiveItem: React.Dispatch<React.SetStateAction<"assistant" | "editor">>;
  videoExpanded: boolean;
  setVideoExpanded: (bool: boolean) => void;
  hideVideo: boolean;
  showAssistant: boolean;
  assistantPopupState:
    | "closed"
    | "popup"
    | "popupNoCode"
    | "expanded"
    | "fullscreen";
  setAssistantPopupState: React.Dispatch<
    React.SetStateAction<
      "closed" | "popup" | "popupNoCode" | "expanded" | "fullscreen"
    >
  >;
  assistantPopupClickedButton: AssistantAllButtonTypes;
  setAssistantPopupClickedButton: React.Dispatch<
    React.SetStateAction<AssistantAllButtonTypes>
  >;
  codeFiles: CodeFile[];
}

const CodeEditorHeader: React.FC<CodeEditorHeaderProps> = ({
  name,
  video,
  AIAssistant,
  activeItem,
  setActiveItem,
  videoExpanded,
  setVideoExpanded,
  hideVideo,
  showAssistant,
  assistantPopupState,
  setAssistantPopupState,
  assistantPopupClickedButton,
  setAssistantPopupClickedButton,
  codeFiles,
}) => {
  // States definition
  const [videoThumbnail, setVideoThumbnail] = useState<any>(undefined);
  const [showVideo, setShowVideo] = useState(false);
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });

  // Define an image reference
  const imgRef = useRef<HTMLImageElement>(null);
  // Define a reference for the video iframe
  const videoIframeRef = useRef<HTMLIFrameElement>(null);

  // Define a navigator state
  const navigate = useNavigate();

  // Get the parameters from the url
  const { moduleOrProject } = useParams();
  // Get a state if we are receiving a state variable from another url
  const { state } = useLocation();

  useEffect(() => {
    if (videoExpanded) {
      const timer = setTimeout(() => {
        setShowVideo(true);
      }, 300);

      return () => clearTimeout(timer);
    } else {
      setShowVideo(false);
    }
  }, [videoExpanded]);

  // --------------- For fetching video title and thumbnail ----------------//
  useEffect(() => {
    if (activeItem === "editor" && video) {
      fetchVideoData(video).then((imageObjectURL: string) => {
        setVideoThumbnail(imageObjectURL);
      });
    }
  }, [video]);

  // Return to home url
  const goToDashboard = () => {
    if (state) {
      navigate("/home", {
        state: {
          // State for SuperAdmin.tsx
          activeItem: "lessons",
          // State for Lessons.tsx
          activeLesson: moduleOrProject,
          navRegister: state ? state.navRegister : [],
        },
      });
    } else {
      navigate("/home");
    }
  };

  /**
   * Function used to pause video
   */
  const pauseVideo = () => {
    if (videoIframeRef.current) {
      const player = new Player(videoIframeRef.current);
      player.pause();
    }
  };

  /**
   * Function that defines the contextual behaviour of the back button
   */
  const handleClickBack = () => {
    if (activeItem === "assistant") setActiveItem("editor");
    else if (activeItem === "editor") goToDashboard();
  };

  const openAssistant = () => {
    // Start pausing the video
    pauseVideo();

    // And moving into the AI assistant view
    setVideoExpanded(false);
    setActiveItem("assistant");
  };

  const handleThumbnailClick = () => {
    // Start pausing the video
    pauseVideo();

    console.log("expanding video...", !videoExpanded);
    setVideoExpanded(!videoExpanded);
  };

  const updateDimensions = () => {
    if (imgRef.current) {
      const dims = {
        width: imgRef.current.clientWidth || imgDimensions.width,
        height: imgRef.current.clientHeight || imgDimensions.height,
      };
      setImgDimensions(dims);
    }
  };

  return (
    <>
      <div className="w-full h-[123px] pt-8 px-10 relative flex items-center z-20">
        {/* Logo and back button */}
        <div className="left-10 flex items-center gap-8">
          <img
            className={`${
              platform === "codeflix" ? "w-[124px] h-10" : "w-[97.80px] h-16"
            } cursor-pointer`}
            src="/logo.svg"
            onClick={goToDashboard}
          />
          <div className="w-px self-stretch bg-neutral-200" />
          <div className="justify-start items-center gap-3 flex">
            <div className="flex-col justify-start items-center gap-2 inline-flex">
              <div
                className="w-12 h-12 p-3 bg-neutral-50 hover:bg-neutral-100 ease-in-out duration-150 rounded-full justify-center items-center inline-flex cursor-pointer z-10"
                onClick={handleClickBack}
              >
                <div className="w-6 h-6 relative">
                  <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
                </div>
              </div>
              <div className="justify-start items-start gap-2 inline-flex">
                <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide select-none">
                  Back
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Title */}
        <div className="flex-grow flex flex-col justify-center items-center">
          <div className="text-center text-neutral-600 text-[23px] font-bold font-sans leading-7">
            {activeItem === "assistant" ? "Feedback" : name}
          </div>
          {activeItem === "assistant" && (
            <div className="text-center text-neutral-500 text-base font-normal font-sans leading-[19px]">
              {AIAssistant === "jakita"
                ? "Tutoring with Big J"
                : AIAssistant === "littleC"
                ? "Tutoring with Little C"
                : "Tutoring with Cody"}
            </div>
          )}
        </div>

        {/* Assistant and video buttons */}
        {true && (
          <div className="right-10 flex items-center mr-3 z-10">
            {/* Conditional rendering for Assistant */}
            {showAssistant && (
              <AssistantPopup
                assistant={platform === "deadly-coders" ? AIAssistant : "cody"}
                state={assistantPopupState}
                setState={setAssistantPopupState}
                clickedButton={assistantPopupClickedButton}
                setClickedButton={setAssistantPopupClickedButton}
                codeFiles={codeFiles}
                goToDashboard={goToDashboard}
                language={null}
              />
            )}

            {/* Video placeholder */}
            {video !== "" && (
              <>
                <div className="w-6" />
                <div className="relative h-[111px] w-[180px]">
                  {videoExpanded ? (
                    <AnimatePresence>
                      <motion.div
                        key="video-expanded"
                        className="group cursor-pointer absolute overflow-hidden h-[111px] w-[180px] z-[5] rounded-lg"
                        initial={{
                          opacity: 0,
                          backdropFilter: "blur(0px)",
                          background: "rgb(0 0 0 / 10%)",
                          right: 100,
                          top: 200,
                        }}
                        animate={{
                          opacity: 1,
                          right: 0,
                          top: 0,
                          backdropFilter: "blur(2px)",
                        }}
                        exit={{
                          opacity: 0,
                          backdropFilter: "blur(0px)",
                          right: 100,
                          top: 200,
                        }}
                        onClick={handleThumbnailClick}
                      >
                        <motion.div className="h-[22px] px-3 py-1 bg-neutral-50 rounded shadow backdrop-blur-[32px] justify-center items-center gap-3 inline-flex right-2 top-2 absolute">
                          <div className="text-dc-secondary-700 text-xs font-semibold font-['Poppins'] uppercase leading-[14px] tracking-wide select-none">
                            Show Code
                          </div>
                        </motion.div>
                        <motion.div className="h-10 w-10 p-1 bg-neutral-400 bg-opacity-50 rounded-tr rounded-bl-lg shadow backdrop-blur-xl justify-center gap-2 inline-flex left-0 bottom-0 absolute items-center group-hover:size-12 ease-in-out duration-200">
                          <MdCallReceived className="h-full w-full fill-white" />
                        </motion.div>
                      </motion.div>
                    </AnimatePresence>
                  ) : (
                    <AnimatePresence initial={false}>
                      <motion.div
                        key="code-expanded"
                        className="group cursor-pointer absolute overflow-hidden h-[111px] w-[180px] z-[5] rounded-lg"
                        onClick={handleThumbnailClick}
                        initial={{
                          opacity: 0,
                          right: 100,
                          top: 200,
                        }}
                        animate={{
                          opacity: 1,
                          right: 0,
                          top: 0,
                        }}
                        exit={{
                          opacity: 0,
                          right: 100,
                          top: 200,
                        }}
                      >
                        <motion.div className="h-[22px] px-3 py-1 bg-neutral-50 rounded shadow backdrop-blur-[32px] justify-center items-center gap-3 inline-flex right-2 top-2 absolute">
                          <div className="text-dc-secondary-700 text-xs font-semibold font-['Poppins'] uppercase leading-[14px] tracking-wide select-none">
                            Play Video
                          </div>
                        </motion.div>
                        <motion.div className="h-10 w-10 p-1 bg-gradient-to-tr white rounded-tr rounded-bl-lg shadow backdrop-blur-xl justify-center gap-2 inline-flex left-0 bottom-0 absolute items-center group-hover:size-12 ease-in-out duration-200">
                          <MdCallReceived className="h-full w-full fill-white" />
                        </motion.div>
                      </motion.div>
                    </AnimatePresence>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <motion.div
        className={`absolute w-full h-screen items-center max-h-[calc(100vh-203px)] right-12 mb-12 ${
          hideVideo ? "hidden" : "block"
        }`}
        initial={{
          top: 22,
          width: 155,
        }}
        animate={{
          top: videoExpanded ? 155 : 22,
          width: videoExpanded ? "calc(100% - 96px)" : 180,
          height: videoExpanded ? undefined : 111,
          right: videoExpanded ? undefined : 52,
          transition: { ease: "linear" },
        }}
        onUpdate={updateDimensions}
      >
        <div
          className={`h-full w-fit rounded-lg mx-auto relative ${
            videoExpanded ? "z-1" : ""
          }`}
        >
          <motion.div
            ref={imgRef}
            className={`h-full w-fit ${showVideo ? "hidden" : "block"}`}
          >
            {videoThumbnail !== undefined ? (
              <img
                className={`h-full rounded-lg mx-auto`}
                src={videoThumbnail}
                alt="Video Thumbnail"
              />
            ) : (
              video && (
                <div className="h-[111px] w-[180px] rounded-lg mx-auto bg-neutral-100" />
              )
            )}
          </motion.div>
          <Video
            iframeRef={videoIframeRef}
            width={imgDimensions.width}
            className={`rounded-xl mx-auto overflow-hidden ${
              showVideo ? "block" : "hidden"
            }`}
            isOpen={true}
            videoUrl={video}
          />
        </div>
      </motion.div>
    </>
  );
};

export default CodeEditorHeader;
