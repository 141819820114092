import ProgressCard from "./ProgressCard";
import ActivityChart from "./ActivityChart";
import Leaderboard from "../utils/Leaderboard";
import { ClassListType } from "utils/interfaces";

interface ClassDashboardProps {
  classData: ClassListType;
}

const ClassDashboard: React.FC<ClassDashboardProps> = ({ classData }) => {
  return (
    <div className="w-full h-full justify-start flex-col inline-flex gap-8 overflow-y-auto overflow-x-hidden custom-scroll">
      <div className="w-full flex gap-8">
        <ProgressCard
          value={classData.students ? classData.students.length : 0}
          progressType="Active students"
        >
          <span className="font-semibold">
            {classData.students ? classData.students.length : 0}
          </span>{" "}
          students enrolled in this class
        </ProgressCard>
        <ProgressCard value={classData.progressRate} progressType={"Progress"}>
          Average completion of assigned projects
        </ProgressCard>
        <ProgressCard value={classData.aptitudeRate} progressType={"Aptitude"}>
          Average score on completed projects
        </ProgressCard>
      </div>
      <div className="w-full flex gap-8">
        <Leaderboard userClassId={classData._id} showStatus={false} />
        <ActivityChart
          userClassId={classData._id}
          hasStudents={!!classData.students && classData.students.length > 0}
        />
      </div>
    </div>
  );
};

export default ClassDashboard;
